/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable dot-notation */
/* eslint-disable max-len */
import {
  ReactElement, useEffect, useState, useRef,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { format } from 'date-fns'
import metricsService from 'reducers/services/metricsService'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { ColorButton } from 'common/CustomTheme'
import WarningCard from 'components/Common/WarningCard/WarningCard'
import Loading from 'components/Common/Backdrop/Backdrop'
import { useScreenshot, createFileName } from 'use-react-screenshot'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import MissingGovAlert from 'components/MissingGov/MissingGovAlert'
import terms from 'common/terms'
import BarChart from './BarChart'
import DivertedTrainsCheckbox from './DivertedTrainsCheckbox'
import TracksUsageSelect from './TracksUsageSelect'
import Legend from './Legend'
import '../station.scss'

interface TabPanelProps {
  index: number;
  value: number;
}

export default function TrackUsage(props: TabPanelProps): ReactElement {
  const dispatch = useDispatch()
  const { value, index, ...other } = props
  const {
    metricsList, trackUsage, loadingTrackUsage, errorTrackUsage, errorTrackUsageCode, fromGroix, filters,
  } = useSelector((state: RootState) => state.metrics)
  const { uploadedList } = useSelector((state: RootState) => state.groix)
  const { confirmedDates } = useSelector((state: RootState) => state.annualServices)
  const { activeStation } = useSelector((state: RootState) => state.stations)

  const [aggregationCategory, setAggregationCategory] = useState('Nombre total de circulations')
  const [divertedTrains, setDivertedTrains] = useState(false)
  const [possibleDivertedTrains, setPossibleDivertedTrains] = useState(false)
  const [results, setResults] = useState(undefined)
  const ref = useRef<HTMLDivElement>(null)
  const [image, takeScreenshot] = useScreenshot()

  const [comparaisonType, setComparaisonType] = useState('Théorique (J-1)')

  const handleChangeComparaisonType = event => {
    setDivertedTrains(false)
    setComparaisonType(event.target.value)
  }

  const handleAggregationCategory = event => {
    setAggregationCategory(event.target.value)
  }

  const handleDivertedTrains = () => {
    setDivertedTrains(!divertedTrains)
  }

  const download = (image, { name = `${activeStation.abv_traction} - ${comparaisonType}${fromGroix ? '' : ` - ${format(new Date(Date.now()), 'dd-MM-yyyy')}`}`, extension = 'jpeg' } = {}) => {
    const a = document.createElement('a')
    a.href = image
    a.download = createFileName(extension, name)
    a.click()
  }

  function getImage() {
    takeScreenshot(ref.current).then(download)
  }

  useEffect(() => {
    const result = metricsList?.metrics?.filter(obj => obj?.board_title === 'Utilisation des voies')
    setResults(result)
    if (value === index) {
      if (result) {
        const usedResult = divertedTrains ? result[1] : result[0]
        if (result[1]?.payload?.filter(p => p[comparaisonType]).length !== 0) {
          const divertedtrainsGovA = result[1]?.payload?.filter(p => p[comparaisonType])[0][comparaisonType]?.gov_a
          const divertedTrainsGovB = result[1]?.payload?.filter(p => p[comparaisonType])[0][comparaisonType]?.gov_b
          setPossibleDivertedTrains(divertedtrainsGovA && divertedTrainsGovB
         && divertedtrainsGovA?.length !== 0 && divertedTrainsGovB?.length !== 0)
          const dataGovA = usedResult?.payload?.filter(p => p[comparaisonType])[0][comparaisonType]?.gov_a
          const dataGovB = usedResult?.payload?.filter(p => p[comparaisonType])[0][comparaisonType]?.gov_b
          const govA = dataGovA && dataGovA?.length !== 0 ? dataGovA.toString() : null
          const govB = dataGovB && dataGovB?.length !== 0 ? dataGovB.toString() : null
          const typeGovA = usedResult?.payload?.filter(p => p[comparaisonType])[0][comparaisonType]?.type_gov_a
          const typeGovB = usedResult?.payload?.filter(p => p[comparaisonType])[0][comparaisonType]?.type_gov_b
          dispatch(metricsService.getTrackUsage({
            uri: usedResult.uri.substring(usedResult.uri.indexOf('/usage_reseau')),
            aggregation_category: aggregationCategory,
            gov_a: govA,
            gov_b: govB,
            type_gov_a: typeGovA,
            type_gov_b: typeGovB,
            filters,
          }))
        }
      }
    }
  }, [metricsList, comparaisonType, divertedTrains, aggregationCategory, filters, value])

  useEffect(() => {
    const result = metricsList?.metrics?.filter(obj => obj?.board_title === 'Utilisation des voies')
    if (result && fromGroix && uploadedList.length !== 0) {
      setComparaisonType(Object.keys(result[0].payload[0])[0])
    }
  }, [metricsList])

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-tu-${index}`}
      ref={ref}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper sx={{
          width: '100%', boxShadow: 'none', paddingLeft: '3rem', paddingRight: '3rem',
        }}
        >
          <Loading open={loadingTrackUsage} />
          <Grid container direction="row" className="mt-3" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Legend />
            <TracksUsageSelect
              comparaisonType={comparaisonType}
              handleChangeComparaisonType={handleChangeComparaisonType}
              results={results}
              aggregationCategory={aggregationCategory}
              handleAggregationCategory={handleAggregationCategory}
            />
            <ColorButton disabled={errorTrackUsageCode?.status === 400} onClick={() => getImage()} data-html2canvas-ignore startIcon={<FileDownloadOutlinedIcon />}>{terms.common.jpegExport}</ColorButton>
            <DivertedTrainsCheckbox
              possibleDivertedTrains={possibleDivertedTrains}
              divertedTrains={divertedTrains}
              handleDivertedTrains={handleDivertedTrains}
            />
          </Grid>
          {(errorTrackUsageCode?.status === 400) && <WarningCard message={errorTrackUsageCode?.message?.detail} />}
          {filters && trackUsage.length === 0 && !errorTrackUsage && <WarningCard message={terms.Filters.noResultNotice} />}
          {metricsList?.missing_days?.length !== 0 && !fromGroix && <MissingGovAlert />}
          {!errorTrackUsage && Object.keys(trackUsage).length !== 0 && (
          <Box>
            <BarChart aggregationCategory={aggregationCategory} />
          </Box>
          )}
        </Paper>
      )}
    </div>
  )
}
