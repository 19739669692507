import { get, patch } from '@osrdata/app_core/dist/requests'
import { ThunkApi } from 'types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Pagine } from 'reducers/types'

const getPagines = createAsyncThunk<Array<Pagine>, string, ThunkApi>(
  'gov/getPagines',
  async (params, thunkApi) => {
    try {
      const pagines = await get('/gov/journees_pagine/', { ids: params })
      return pagines
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const updatePagines = createAsyncThunk<Array<Pagine>, Array<string>, ThunkApi>(
  'gov/updatePagines',
  async (params, thunkApi) => {
    try {
      const pagines = await patch('/gov/journees_pagine/', { ids: params })
      return pagines
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const PagineService = {
  getPagines,
  updatePagines,
}

export default PagineService
