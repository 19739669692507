import {
  ReactElement, useEffect, useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import metricsService from 'reducers/services/metricsService'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Loading from 'components/Common/Backdrop/Backdrop'
import WarningCard from 'components/Common/WarningCard/WarningCard'
import nextId from 'react-id-generator'
import MissingGovAlert from 'components/MissingGov/MissingGovAlert'
import terms from 'common/terms'
import _ from 'lodash'
import { setTrainsList } from 'reducers/metrics'
import TrainsTable from './TrainsTable'
import '../station.scss'
import PaginationLinesPerPage from './PaginationLinesPerPage'

interface TabPanelProps {
  index: number;
  value: number;
}

export default function Trains(props: TabPanelProps): ReactElement {
  const dispatch = useDispatch()
  const { value, index, ...other } = props
  const {
    metricsList, loadingTrainsList, errorTrainsList, sortingBy, fromGroix, trainsList, filters,
  } = useSelector((state: RootState) => state.metrics)
  const [initialPage, setInitialPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [showLinesPerPage, setShowLinesPerPage] = useState(false)

  useEffect(() => {
    setShowLinesPerPage(false)
    setTimeout(() => {
      dispatch(setTrainsList())
      const result = metricsList?.metrics?.filter(obj => obj?.board_title === 'Liste des circulations')
      if (value === index) {
        if (result) {
          setShowLinesPerPage(true)
          result.forEach((element, indexx) => {
            dispatch(metricsService.getTrainsList({
              uri: element?.uri?.substring(result[0]?.uri?.indexOf('/usage_reseau')),
              gov_a: element?.payload?.gov_a?.length !== 0 ? element?.payload?.gov_a?.toString() : null,
              type_gov_a: element?.payload?.type_gov_a,
              sorting: sortingBy.length !== 0
                ? sortingBy.filter(v => v.name === element?.payload?.type_gov_a)[0]?.value : null,
              filters,
              order: indexx,
            }))
          })
        }
      }
    }, 1000)
  }, [metricsList, filters, sortingBy, value])

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-t-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper
          sx={{
            width: '100%',
            boxShadow: 'none',
          }}
        >
          <Loading open={loadingTrainsList} />
          <div className="d-flex w-100 justify-content-between mt-3 align-items-center pl-5">
            <div className="h-100 justify-content-between align-items-between">
              <h1 className="main-title font-weight-bold">{terms.Trains.trainsTitle}</h1>
              <h4 className="sub-title">{terms.Trains.trainsSubtitle}</h4>
            </div>
          </div>
          <Box className="center-content">
            <Box sx={{ width: '1450px' }}>
              {showLinesPerPage && (
              <PaginationLinesPerPage
                initialPage={initialPage}
                rowsPerPage={rowsPerPage}
                setInitialPage={setInitialPage}
                setRowsPerPage={setRowsPerPage}
              />
              )}
            </Box>
          </Box>
          <div className="paper-test d-flex align-items-start overflow-auto w-100">
            {_.orderBy(trainsList, item => item?.meta.arg.order, ['asc'])?.map(t => (
              <div key={nextId()} style={{ minWidth: '470px' }}>
                {t.payload?.table?.values && t.payload?.table?.values.length !== 0
                && (
                <TrainsTable
                  table={t.payload}
                  rowsPerPage={rowsPerPage}
                  initialPage={initialPage}
                />
                )}
                {!fromGroix && t.payload?.status && (
                <WarningCard
                  message={t.payload?.message?.detail}
                />
                )}
                {filters && t.payload?.table?.values && t.payload?.table?.values.length === 0
                && !errorTrainsList
               && <WarningCard message={terms.Filters.noResultNotice} />}
              </div>
            ))}
          </div>
            {metricsList?.missing_days?.length !== 0 && !fromGroix && <MissingGovAlert />}
        </Paper>
      )}
    </div>
  )
}
