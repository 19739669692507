import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSelectedBalanceType } from 'reducers/filters'
import { RootState } from 'Store'
import terms from 'common/terms'
import FiltersChips from './FiltersChips'

export default function BalanceTypeFilter(): ReactElement {
  const dispatch = useDispatch()
  const { balanceType, filters } = useSelector((state: RootState) => state.metrics)
  const { selectedBalanceType } = useSelector((state: RootState) => state.filters)

  useEffect(() => {
    if (filters && selectedBalanceType.length === 0) {
      dispatch(updateSelectedBalanceType(filters.balance_type))
    }
  }, [filters])

  const onBalanceTypeClick = (balance: string) => {
    const balanceList: string[] = []
    if (selectedBalanceType.filter(type => type === balance)?.length === 0) {
      balanceList.push(...selectedBalanceType, balance)
    } else {
      balanceList.push(...selectedBalanceType.filter(type => type !== balance))
    }
    dispatch(updateSelectedBalanceType(balanceList))
  }

  return (
    <FiltersChips
      title={terms.Filters.balanceType}
      data={balanceType}
      handleClick={onBalanceTypeClick}
      selectedValues={selectedBalanceType}
    />
  )
}
