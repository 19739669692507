import { TableBody, TableCell, TableRow } from '@mui/material'
import { ReactElement } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ReusabilityTable } from 'reducers/services/normesService'

interface Props {
  reusabilityTable: ReusabilityTable;
  handleClickBalance: (key: string, label: string) => void;
}

const CustomTableBody = ({
  reusabilityTable,
  handleClickBalance,
}: Props): ReactElement => (
  <TableBody>
    {
        reusabilityTable.data.map(row => (
          <TableRow key={uuidv4()}>
            <TableCell>
              <span className="cell-libelle">
                {row.key}
              </span>
            </TableCell>
            {
              row.value.map(item => (
                <TableCell key={uuidv4()}>
                  <button
                    type="button"
                    className="cell"
                    disabled={row.key === 'Total'}
                    onClick={() => handleClickBalance(row.key, item.label)}
                  >
                    <span className="value">
                      {item.value}
                    </span>
                    <span className="ratio">
                      {item.additional_value}
                    </span>
                  </button>
                </TableCell>
              ))
            }
          </TableRow>
        ))
      }
  </TableBody>
)

export default CustomTableBody
