import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import terms from 'common/terms'
import { setError as setErrorStations } from 'reducers/stations'
import { setError as setErrorTracks } from 'reducers/tracks'
import { setError, setInitialStateGroix } from 'reducers/groix'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import Button from '@mui/material/Button'

type Props = {
  message: string;
  error: boolean;
  file: Array<any>;
}

export default function ImportError({ message, error, file }: Props): ReactElement {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setError(undefined))
    dispatch(setErrorStations(undefined))
    dispatch(setErrorTracks(undefined))
    dispatch(setInitialStateGroix())
  }

  return (
    <Snackbar
      open={error}
      onClose={handleClose}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ background: '#FFF', maxWidth: '320px' }}
    >
      <div
        style={{
          border: '1px solid rgba(252, 81, 48, 0.2)',
          borderRadius: '8px',
        }}
      >
        <Alert
          variant="outlined"
          severity="error"
          sx={{
            textAlign: 'left', alignItems: 'center', border: 'none', fontSize: '24px', color: '#FC5130',
          }}
        >
          <span style={{ fontWeight: '500', fontFamily: 'Avenir' }}>
            {message}
          </span>

        </Alert>
        <div className="mb-3 font-weight-bold">
          {file[0]?.name}
        </div>
        <Divider variant="middle" />
        <Button
          color="error"
          variant="contained"
          onClick={handleClose}
          sx={{
            paddingX: '30px',
            paddingY: '10px',
            borderRadius: '30px',
            borderColor: 'rgba(235, 235, 235, 1)',
            minWidth: '200px',
            backgroundColor: '#FC5130',
          }}
          disableElevation
          className="my-4"
        >
          {terms.common.ok}
        </Button>
      </div>
    </Snackbar>
  )
}
