/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-array-index-key */
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { TrainNumberField } from 'reducers/types'
import { updateTrainNumberFields } from 'reducers/filters'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import RemoveIcon from '@mui/icons-material/Remove'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'

type Props = {
  field: TrainNumberField;
  index: number;
}

export default function TrainNumberFilterFields({ field, index }: Props): ReactElement {
  const dispatch = useDispatch()
  const { trainsNumberFields } = useSelector((state: RootState) => state.filters)

  const handleOperator = (value: string) => {
    const newTrains = trainsNumberFields.map((train: TrainNumberField) => (train.index !== index
      ? train : { index, operator: value, trainNumber: train.trainNumber }))
    dispatch(updateTrainNumberFields(newTrains))
  }

  const handleValue = (value: string) => {
    const regex = /[^A-Za-z0-9]/
    if (!regex.test(value)) {
      const newTrains = trainsNumberFields.map((train: TrainNumberField) => (train.index !== index
        ? train : { index, operator: train.operator, trainNumber: value }))
      dispatch(updateTrainNumberFields(newTrains))
    }
  }

  const deleteTrainNumber = () => {
    dispatch(updateTrainNumberFields(trainsNumberFields.filter(train => train.index !== index).map(train => (train.index
             < index ? train : {
        index: train.index - 1,
        operator: train.operator,
        trainNumber: train.trainNumber,
      }))))
  }

  return (
    <Grid container className="mb-3">
      <Grid item xs={5.5}>
        <Paper
          className="filters-input"
          variant="outlined"
          sx={{
            height: '33px',
          }}
        >
          <Select
            name="operatorField"
            value={field.operator}
            variant="standard"
            size="small"
            disableUnderline
            className="train-number-selector"
            onChange={e => handleOperator(e.target.value)}
          >
            <MenuItem
              sx={{
                fontFamily: 'Avenir',
                fontWeight: '400',
                fontSize: '15px',
              }}
              value={terms.Filters.equalTo}
            >
              {terms.Filters.equalTo}

            </MenuItem>
            <MenuItem
              sx={{
                fontFamily: 'Avenir',
                fontWeight: '400',
                fontSize: '15px',
              }}
              value={terms.Filters.startsWith}
            >
              {terms.Filters.startsWith}

            </MenuItem>
            <MenuItem
              sx={{
                fontFamily: 'Avenir',
                fontWeight: '400',
                fontSize: '15px',
              }}
              value={terms.Filters.contains}
            >
              {terms.Filters.contains}
            </MenuItem>
            <MenuItem
              sx={{
                fontFamily: 'Avenir',
                fontWeight: '400',
                fontSize: '15px',
              }}
              value={terms.Filters.endsWith}
            >
              {terms.Filters.endsWith}

            </MenuItem>
          </Select>
        </Paper>
      </Grid>
      <Grid item xs={5.5}>
        <InputBase
          name="valueField"
          value={field.trainNumber}
          onChange={e => handleValue(e.target.value)}
          className="filters-input"
          sx={{
            fontFamily: 'Avenir',
            fontWeight: '400',
            fontSize: '15px',
            color: '#00000099',
            pl: '10px',
          }}
        />
      </Grid>
      {trainsNumberFields.length > 1 && (
      <Grid item xs={1} className="delete-field-btn">
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => deleteTrainNumber()}
          aria-label="delete"
          sx={{ marginRight: 0 }}
        >
          <RemoveIcon />
        </IconButton>
      </Grid>
      )}
    </Grid>
  )
}
