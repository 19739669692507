/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import { setSaveStatus } from 'reducers/stations'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Grow from '@mui/material/Grow'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import ConfigDialog from 'components/Common/Dialog/Dialog'
import OrderingMenu from './OrderingMenu'
import StationCard from './StationCard'

export default function StationsItems(): ReactElement {
  const dispatch = useDispatch()
  const { stationsList, saveStatus } = useSelector((state: RootState) => state.stations)

  useEffect(() => {
    if (saveStatus) {
      dispatch(setSaveStatus(false))
    }
  }, [saveStatus])

  return (
    <Fade in timeout={500}>
      <Box>
        <Grid
          container
          sx={{
            height: '100%', p: 2, justifyContent: 'center',
          }}
          margin="auto"
        >
          <Grid
            item
            container
            xs={12}
            sm={10}
            md={8}
            sx={{
              justifyContent: 'space-between',
              p: 2,
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontSize: '12px', color: '#868686' }}>
              {terms.StationsDashboard.StationsHome.stationslength}
              {' '}
              <span style={{ color: '#43454A' }}>
                (
                {stationsList.length}
                )
              </span>
            </Typography>
            <OrderingMenu />
          </Grid>
          <Grid item container xs={12} sm={10} md={8} spacing={1} sx={{ alignContent: 'flex-start', justifyContent: 'flex-start' }}>
            {stationsList.map((station: any, index: number) => (
              <Grow
                in
                {...({ timeout: (index + 1) * 500 })}
                key={station.nom_gare}
              >
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} sx={{ justifyContent: 'center' }}>
                  <StationCard station={station} />
                  <ConfigDialog station={station} />
                </Grid>
              </Grow>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}
