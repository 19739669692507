/* eslint-disable react/display-name */
/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import StationsService from 'reducers/services/stationsService'
import { setSaveStatus } from 'reducers/stations'
import Fade from '@mui/material/Fade'
import history from 'customHistory'
import { customDarkButton } from 'common/CustomTheme'
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import InputBase from '@mui/material/InputBase'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import LoadingButton from '@mui/lab/LoadingButton'
import PathNames from 'common/PathNames'
import Snackbar from '../Common/SnackBar/Snackbar'

export default function TrigramConfiguration(): ReactElement {
  const dispatch = useDispatch()
  const {
    activeStation, loading, error, saveStatus,
  } = useSelector((state: RootState) => state.stations)
  const [input, setInput] = useState(activeStation.abv_traction)

  const errorFound = error !== undefined
  const errorMessage = `${error?.message?.non_field_errors[0]}`

  const onChange = (event: any) => {
    setInput(event.target.value)
  }

  const validerTrigram = () => {
    const object = {
      stationID: activeStation.id,
      params: {
        abv_traction: input,
      },
    }
    dispatch(StationsService.patchStation(object))
  }

  useEffect(() => {
    if (saveStatus) {
      history.push(PathNames.ImageConfig)
    } dispatch(setSaveStatus(false))
  }, [saveStatus])

  return (
    <main>
      <Fade timeout={400} in mountOnEnter unmountOnExit>
        <Container maxWidth={false} disableGutters sx={{ height: '100vh' }}>
          <Box sx={{ height: '68px' }} style={{ background: '#022e4f' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: '100%', width: '90%', margin: 'auto' }}
            >
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label="back"
                  onClick={() => history.push(PathNames.Home)}
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
                <Typography sx={{ fontSize: '12px', color: 'white' }}>
                  {terms.StationsDashboard.TrigramConfig.returnTopBt}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label="close"
                  onClick={() => history.push(PathNames.Home)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ height: '60%', alignItems: 'center', background: '#022e4f' }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100%', margin: 'auto' }}
            >
              <Grid
                item
                sx={{
                  width: '100%', alignItems: 'center', justifyContent: 'center', mt: 5, mb: 10,
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: '900',
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  {terms.StationsDashboard.TrigramConfig.trigramValidate}
                </Typography>
              </Grid>
              <Grid item sx={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: '400', color: '#B6B6B6', textAlign: 'center' }}
                >
                  {terms.common.infoChangeItLater}
                </Typography>
              </Grid>
              <Grid item width={400} mt={5} mb={10}>
                <InputLabel shrink sx={{ color: '#E1E1E1', mt: 5 }}>
                  {terms.StationsDashboard.TrigramConfig.inputLabel}
                </InputLabel>
                <Paper
                  elevation={0}
                  component="form"
                  autoComplete="off"
                  sx={{
                    p: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {activeStation !== undefined
                    ? (
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        inputProps={{ 'aria-label': 'test' }}
                        defaultValue={activeStation.abv_traction}
                        onChange={onChange}
                        onKeyPress={event => {
                          if (event.key === 'Enter') { event.preventDefault() }
                        }}
                      />
                    )
                    : (
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        inputProps={{ 'aria-label': 'test' }}
                        placeholder={`trigramme pour la gare ${activeStation.nom_gare}`}
                        onChange={onChange}
                        onKeyPress={event => {
                          if (event.key === 'Enter') { event.preventDefault() }
                        }}
                      />
                    )}
                  <EditIcon color="action" />
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ height: 'calc(40% - 68px)' }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100%' }}
            >
              <Grid item>
                <ThemeProvider theme={customDarkButton}>
                  <LoadingButton
                    onClick={() => validerTrigram()}
                    endIcon={<ArrowRightAltIcon />}
                    sx={{
                      width: '400px',
                      paddingY: '15px',
                      borderRadius: '30px',
                      color: 'white',
                    }}
                    loading={loading}
                    variant="contained"
                    disableElevation
                    disabled={input === ''}
                  >
                    {terms.StationsDashboard.TrigramConfig.validateBt}
                  </LoadingButton>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Fade>
      {errorMessage && <Snackbar message={errorMessage} error={errorFound} />}
    </main>
  )
}
