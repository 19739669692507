/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import _ from 'lodash'
import nextId from 'react-id-generator'
import SquareRoundedIcon from '@mui/icons-material/SquareRounded'
import InfoToolTip from '../Common/InfoToolTip'
import { color } from '../utils'
import InfoHover from '../Common/InfoHover'
import '../station.scss'

  type Props = {
    gv: any;
  }

export default function GovVariationLegend({ gv }: Props): ReactElement {
  const { monochrome } = useSelector((state: RootState) => state.stations)
  const { categoriesVariabiliteDetail } = useSelector((state: RootState) => state.metrics)
  return (
    <div className="d-flex flex-wrap ml-1 position-relative">
      {gv?.payload.category_values && gv?.payload.category_values.map(cat => (
        <div key={nextId()} className="mr-4 mb-3">
          <InfoHover title={categoriesVariabiliteDetail.filter(c => c.value === cat.category)[0]?.description}>
            <div className="mb-1" style={{ fontSize: '14px' }}>
              {cat.category}
            </div>
          </InfoHover>
          <div className="d-flex align-items-center">
            <SquareRoundedIcon fontSize="small" style={color(cat.category, monochrome)} />
            <span className="ml-2" style={{ fontSize: '14px' }}>
              {cat.count}
              {' '}
              %
            </span>
          </div>
        </div>
      ))}
      <InfoToolTip position="position-absolute" placement="top-start" />
    </div>
  )
}
