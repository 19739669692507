import { BarTooltipDatum } from '@nivo/bar'
import { ReactElement } from 'react'

interface Props {
  tooltipProps: BarTooltipDatum;
  withLabel?: boolean;
  withLegend?: boolean;
}

const TooltipBarCHart = ({ tooltipProps, withLabel, withLegend }: Props): ReactElement => (
  <div className="custom-tooltip">
    {
      Object.keys(tooltipProps.data).map(item => {
        if (item === 'x') return null
        return (
          <div key={item} className={`item ${item === tooltipProps.id ? 'selected' : ''}`}>
            <div>
              {tooltipProps.data[item]}
            </div>
            {
              withLabel && (
                <div>
                  {tooltipProps.indexValue}
                </div>
              )
            }
            {
              withLegend && (
                <div>
                  {item}
                </div>
              )

            }
          </div>
        )
      })
    }
  </div>
)

TooltipBarCHart.defaultProps = {
  withLabel: false,
  withLegend: false,
}

export default TooltipBarCHart
