import { ReactElement } from 'react'
import terms from 'common/terms'

type Props = {
  msg?: string;
  center?: boolean;
}

const defaultProps: Props = {
  msg: '',
  center: false,
}

export default function Loader({
  msg, center,
}: Props): ReactElement {
  const className = center ? 'loader center' : 'loader'

  return (
    <div className={className}>
      <div className="spinner-border" role="status">
        <span className="sr-only">{terms.common.loading}</span>
      </div>
      <div className="text-center mt-2">{msg}</div>
    </div>
  )
}

Loader.defaultProps = defaultProps
