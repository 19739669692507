/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react'
import emptyCardBg from 'assets/images/empty-card-bg.png'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import axios from 'axios'

export default function GovBanner(): ReactElement {
  const { activeStation } = useSelector((state: RootState) => state.stations)
  const [imageUrl, setImageUrl] = useState(emptyCardBg)

  const getAuthConfig = () => ({
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })

  const styles = {
    bannerContainer: {
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#022e4f',
    },
  }

  useEffect(() => {
    if (activeStation.image !== null) {
      const baseURL = activeStation.image
      const config = getAuthConfig()
      axios.get(baseURL, config)
        .then(response => {
          setImageUrl(response.data)
        })
        .catch(error => {
          if (error.response) {
            setImageUrl(error.response.data)
          } else if (error.request) {
            // eslint-disable-next-line no-console
            console.log('Error', error.message)
          } else {
            // eslint-disable-next-line no-console
            console.log('Error', error.message)
          }
        })
    }
  }, [])

  return (
    <Fade in timeout={500}>
      <Box style={styles.bannerContainer}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: '200px', width: '70%' }}
          margin="auto"
        >
          <Grid item xs={2} sm={6} md={12} mt={3}>
            <Typography variant="h3" sx={{ fontWeight: '400', textAlign: 'center', color: '#FFFFFF' }}>
              {activeStation.nom_gare}
              {' '}
              (
              {activeStation.abv_traction}
              )
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}
