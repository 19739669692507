import {
  Box,
  Collapse,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import MissingData from 'components/Station/MissingData'
import { ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidV4 } from 'uuid'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TableHeader from '../Table/TableHeader'
import ModalHeader from './ModalHeader'

import './modalDetailTable.scss'

interface Props {
  open: boolean;
  handleClose: () => void;
  onDownload: () => void;
  sortBalance: string;
  setSortBalance: (value: string) => void;
  handleClickHistory: (arrival: string, departure: string) => void;
}

const ModalDetailTable = ({
  open,
  handleClose,
  onDownload,
  sortBalance,
  setSortBalance,
  handleClickHistory,
}: Props): ReactElement => {
  const { detailTable, isLoading } = useSelector(
    (state: RootState) => state.normes,
  )
  const dataTable = detailTable.data
  const columns = () => {
    if (dataTable.length === 0) return []
    const result = dataTable[0].value.collapsed_value.map(item => item.label)
    return ['', ...result, '']
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function Row(props: any) {
    const { item } = props
    const [openCollapse, setOpenCollapse] = useState(false)

    return (
      <>
        <TableRow sx={{
          '& > *': {
            borderBottom: 'unset',
          },
        }}
        >
          <TableCell style={{ maxWidth: 60, minWidth: 60 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenCollapse(!openCollapse)}
            >
              {openCollapse ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
          {item.value.collapsed_value.map(row => (
            <TableCell key={uuidV4()}>{row.value || '-'}</TableCell>
          ))}
          <TableCell className="cell-history">
            <button
              type="button"
              onClick={() => handleClickHistory(
                item.value.collapsed_value
                  .find(val => val.label === 'Arrivée')
                  ?.value?.toString() || '',
                item.value.collapsed_value
                  .find(val => val.label === 'Départ')
                  ?.value?.toString() || '',
              )}
            >
              {terms.NormesRE.Reusability.Table.history}
            </button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={12}>
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              <Box>
                <Table
                  size="small"
                  sx={{ minWidth: 650 }}
                  aria-label="purchases"
                >
                  <TableHead>
                    <TableRow>
                      {columns().map(cell => (
                        <TableCell key={uuidV4()} component="th" scope="row">
                          <div className="cell-head">{cell || '-'}</div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.value.expanded_values.map(row => (
                      <TableRow key={uuidV4()}>
                        <TableCell className="first-column" style={{ maxWidth: 60, minWidth: 60 }} />
                        {row.map(cell => (
                          <TableCell key={uuidV4()} style={{ maxWidth: 120, minWidth: 120 }}>
                            <div className="cell">
                              <span className="value">{cell.value}</span>
                            </div>
                          </TableCell>
                        ))}
                        <TableCell className="cell-history" style={{ maxWidth: 120, minWidth: 120 }} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }
  return (
    <Modal open={open} onClose={handleClose} className="modal-wrapper">
      <Box className="modal-detail">
        <ModalHeader handleClose={handleClose} onDownload={onDownload} />
        <div className="content">
          {dataTable.length === 0 && !isLoading ? (
            <div>
              <MissingData message={terms.common.noData} />
            </div>
          ) : (
            <TableContainer component="div">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHeader
                  data={columns()}
                  sortTable={sortBalance}
                  setSortTable={setSortBalance}
                />
                <TableBody>
                  {dataTable.map(item => (
                    <Row key={uuidV4()} item={item} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </Box>
    </Modal>
  )
}

export default ModalDetailTable
