import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

const InfoHover = styled(({ className, ...propss }: TooltipProps) => (
  <Tooltip {...propss} arrow classes={{ popper: className }} placement="left" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '16px',
    fontWeight: '400',
    borderRadius: '8px',
    padding: '12px',
  },
}))

export default InfoHover
