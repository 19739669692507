/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable max-len */
import { ReactElement } from 'react'
import {
  addMonths, getMonth, getYear, subMonths,
} from 'date-fns'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

type Props = {
  customHeaderCount: number;
  changeMonth: Function;
  changeYear: Function;
  setLeftMonth: Function;
  lastClickedSide: string;
  monthDate: Date;
  minDate: Date;
  maxDate: Date;
  maxYear: number;
  months: Array<string>;
}

export default function HeaderSelectMonth({
  customHeaderCount, setLeftMonth, monthDate, changeMonth,
  minDate, maxDate, lastClickedSide, months, maxYear, changeYear,
}: Props): ReactElement {
  return (
    <Select
      className="ml-3 font-weight-bold"
      variant="standard"
      autoWidth
      size="small"
      disableUnderline
      sx={{ fontFamily: 'Avenir', fontSize: '13px' }}
      value={getMonth(monthDate)}
      onChange={e => {
        switch (true) {
          case (customHeaderCount === 0 && +e.target.value === 0):
            changeYear(lastClickedSide === 'left' ? getYear(monthDate) : getYear(addMonths(monthDate, 1)))
            changeMonth(lastClickedSide === 'left' ? 0 : 1)
            setLeftMonth(0)
            break
          case (customHeaderCount === 1 && +e.target.value === 0 && getYear(monthDate) === getYear(minDate)):
            changeYear(lastClickedSide === 'right' ? getYear(monthDate) : getYear(subMonths(monthDate, 1)))
            changeMonth(lastClickedSide === 'right' ? 1 : 0)
            setLeftMonth(0)
            break
          case (customHeaderCount === 1 && +e.target.value === 0):
            changeYear(lastClickedSide === 'right' ? getYear(monthDate) : getYear(subMonths(monthDate, 1)))
            changeMonth(lastClickedSide === 'right' ? 0 : 11)
            setLeftMonth(11)
            break
          case (customHeaderCount === 0 && getYear(monthDate) === getYear(maxDate) && +e.target.value >= getMonth(maxDate)):
            changeYear(lastClickedSide === 'left' ? getYear(monthDate) : getYear(addMonths(monthDate, 1)))
            changeMonth(lastClickedSide === 'left' ? getMonth(subMonths(maxDate, 1)) : getMonth(maxDate))
            setLeftMonth(getMonth(subMonths(maxDate, 1)))
            break
          case (customHeaderCount === 0):
            changeYear(getYear(monthDate))
            changeMonth(lastClickedSide === 'left' ? +e.target.value : +e.target.value + 1)
            setLeftMonth(+e.target.value)
            break
          case (customHeaderCount === 1):
            changeYear(getYear(monthDate))
            changeMonth(lastClickedSide === 'right' ? +e.target.value : +e.target.value - 1)
            setLeftMonth(+e.target.value - 1)
            break
          default:
            changeYear(getYear(monthDate))
            changeMonth(+e.target.value)
            setLeftMonth(+e.target.value)
            break
        }
      }}
    >
      {months.map(month => (
        (!(getYear(monthDate) === maxYear
        && getMonth(maxDate) < (months.indexOf(month))))
        && (
        <MenuItem
          disabled={customHeaderCount === 0
            ? getMonth(monthDate) === months.indexOf(month)
            || (getYear(monthDate) === getYear(maxDate) && getMonth(maxDate) === months.indexOf(month))
            : getMonth(monthDate) === months.indexOf(month)
            || (getYear(monthDate) === getYear(maxDate) && getMonth(subMonths(monthDate, 1)) === months.indexOf(month))
            || (getYear(monthDate) === getYear(minDate) && getMonth(monthDate) === 1 && months.indexOf(month) === 0)}
          key={month}
          sx={{ fontFamily: 'Avenir', fontSize: '13px' }}
          value={months.indexOf(month)}
        >
          {month}
        </MenuItem>
        )
      ))}
    </Select>
  )
}
