/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import GroixService from 'reducers/services/groixService'
import { Groix, GroixState } from './types'

const initialState: GroixState = {
  groixList: [],
  newGroix: undefined,
  loading: false,
  error: undefined,
  uploadedList: [],
  uploadedItem: undefined,
  groix: undefined,
}

export const groixSlice = createSlice({
  name: 'groix',
  initialState,

  reducers: {
    setInitialState: state => {
      state.uploadedList = []
      state.uploadedItem = undefined
    },
    updateGroixList: (state, action: PayloadAction<Array<Groix>>) => {
      state.groixList = action.payload
    },
    setNewGroix: (state, action: PayloadAction<string>) => {
      state.newGroix = action.payload
    },
    setInitialStateGroix: state => {
      state.groix = undefined
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
    },
    removeItem: (state, action: PayloadAction<number>) => {
      if (action.payload > -1) {
        state.uploadedList.splice(action.payload, 1)
      }
    },
    addItem: (state, action: PayloadAction<any>) => {
      state.uploadedList.push(action.payload)
    },
  },

  extraReducers: builder => {
    builder.addCase(GroixService.uploadGroix.pending, state => {
      state.loading = true
    })
    builder.addCase(GroixService.uploadGroix.fulfilled, (state, action) => {
      state.uploadedItem = action.payload
    })
    builder.addCase(GroixService.uploadGroix.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(GroixService.getGroixList.fulfilled, (state, action) => {
      state.groixList = action.payload
    })
    builder.addCase(GroixService.getGroixList.rejected, (state, action) => {
      state.loading = false
    })
    builder.addCase(GroixService.getGroix.fulfilled, (state, action) => {
      state.groix = action.payload
      if (action.payload.statut === 'Insertion terminée') {
        state.uploadedList.push(action.payload)
        state.loading = false
        state.uploadedItem = undefined
      } else if (action.payload.statut === 'Insertion échouée') {
        state.loading = false
        state.uploadedItem = undefined
      }
    })
    builder.addCase(GroixService.getGroix.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {
  updateGroixList, setNewGroix, setLoading, setError, removeItem, addItem, setInitialState, setInitialStateGroix,
} = groixSlice.actions

export default groixSlice.reducer
