import { get, patch } from '@osrdata/app_core/dist/requests'
import { ThunkApi } from 'types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  Station, GetParams, QueryParams, PatchParams,
} from '../types'

const getStationsList = createAsyncThunk<any, GetParams, ThunkApi>(
  'stations/getStationsList',
  async (params: GetParams, thunkApi) => {
    try {
      const stationsList = await get('/gov/gares/', {
        search: params.search !== '' ? params.search : null,
        ordering: params.ordering,
      })
      return stationsList
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getStation = createAsyncThunk<Station, string, ThunkApi>(
  'stations/getStation',
  async (id, thunkApi) => {
    try {
      const station = await get(`/gov/gares/${id}`, id)
      return station
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchStation = createAsyncThunk<any, QueryParams, ThunkApi>(
  'objects/patchStation',
  async (params: QueryParams, thunkApi) => {
    try {
      const response = await
      patch(`/gov/gares/${params.stationID}/`, params.params)
      return response
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchImage = createAsyncThunk<any, PatchParams, ThunkApi>(
  'stations/patchImage',
  async (params: PatchParams, thunkApi) => {
    try {
      const response: any = await
      patch(`/gov/gares/${params.stationId}/image/`, params.formData)
      return response
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const validateConfig = createAsyncThunk<any, any, ThunkApi>(
  'stations/validateConfig',
  async (object: any, thunkApi) => {
    try {
      const response: Station = await
      patch(`/gov/gares/${object.stationId}/`, object.params)
      return response
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const StationsService = {
  getStationsList,
  getStation,
  patchStation,
  patchImage,
  validateConfig,
}

export default StationsService
