import { Grid, Input, InputLabel } from '@mui/material'
import { ReactElement } from 'react'

type Props = {
    label?: string;
    value: string;
    index: number;
    onChange: (value: any) => void;
    inputStyle?: string;
}

const defaultProps = {
  label: '',
  inputStyle: '',
}

export default function SimpleInput({
  label, value, index, onChange, inputStyle,
}: Props): ReactElement {
  return (
    <Grid>
      {label !== '' && index === 0 && <InputLabel htmlFor="my-input">{label}</InputLabel>}
      <Input
        value={value}
        onChange={onChange}
        onKeyPress={event => {
          if (event.key === 'Enter') { event.preventDefault() }
        }}
        className={inputStyle}
      />
    </Grid>
  )
}

SimpleInput.defaultProps = defaultProps
