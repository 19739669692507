/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
import { ReactElement, useEffect } from 'react'
import history from 'customHistory'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { useLocation } from 'react-router-dom'
import { setSaveStatus } from 'reducers/stations'
import terms from 'common/terms'
import StationsService from 'reducers/services/stationsService'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { customWhiteButton } from 'common/CustomTheme'
import Grid from '@mui/material/Grid'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import Loading from 'components/Common/Backdrop/Backdrop'
import PathNames from 'common/PathNames'
import Snackbar from '../Common/SnackBar/Snackbar'
import './configuration.scss'

type acceptedFile = Blob
type stateType = Array<acceptedFile>

export default function ImagePreview(): ReactElement {
  const dispatch = useDispatch()
  const { state: acceptedFileArray } = useLocation<stateType>()
  const {
    activeStation, loading, error, saveStatus,
  } = useSelector((state: RootState) => state.stations)

  const errorFound = error !== undefined
  const errorMessage = error !== undefined ? "une erreur est survenue, veuillez répéter l'opération" : ''

  const cancelUpload = () => {
    history.push(PathNames.ImageConfig)
  }

  const imageFile = acceptedFileArray.map(
    file => Object.assign(
      file, { preview: URL.createObjectURL(file) },
    ),
  )

  const styles = {
    imagePreview: {
      backgroundImage: `url(${imageFile[0].preview})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
  }

  const confirmPhoto = () => {
    const formData = new FormData()
    formData.append('file', acceptedFileArray[0])
    formData.append('project_id', activeStation.id)
    const object = {
      stationId: activeStation.id,
      formData,
    }
    dispatch(StationsService.patchImage(object))
  }

  useEffect(() => {
    if (saveStatus) {
      imageFile.forEach(file => URL.revokeObjectURL(file.preview))
      history.push(PathNames.TrackConfig)
    } dispatch(setSaveStatus(false))
  }, [saveStatus])

  return (
    <main>
      <Fade timeout={400} in mountOnEnter unmountOnExit>
        <Container maxWidth={false} disableGutters sx={{ height: '100%' }}>
          <Box sx={{ height: '68px' }} style={{ background: '#022e4f' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: '100%', width: '90%', margin: 'auto' }}
            >
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label="back"
                  onClick={() => history.push(PathNames.ImageConfig)}
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
                <Typography sx={{ fontSize: '12px', color: 'white' }}>
                  {terms.StationsDashboard.ImagePreview.photoChange}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label="close"
                  onClick={() => history.push(PathNames.Home)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ height: 'calc(100vh - 68px)' }} style={styles.imagePreview}>
            <Grid
              container
              spacing={1}
              justifyContent="space-around"
              alignContent="flex-end"
              alignItems="center"
              sx={{ height: '90%', margin: 'auto' }}
            >
              <Grid item>
                <ThemeProvider theme={customWhiteButton}>
                  <LoadingButton
                    onClick={() => cancelUpload()}
                    disabled={loading}
                    variant="outlined"
                    loadingPosition="end"
                    disableElevation
                    sx={{
                      width: '400px',
                      paddingY: '15px',
                      borderRadius: '30px',
                      color: 'white',
                      backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    }}
                  >
                    {terms.common.cancel}
                  </LoadingButton>
                </ThemeProvider>
              </Grid>
              <Grid item>
                <ThemeProvider theme={customWhiteButton}>
                  <LoadingButton
                    onClick={() => confirmPhoto()}
                    endIcon={<ArrowRightAltIcon />}
                    variant="contained"
                    loadingPosition="end"
                    disableElevation
                    disabled={loading}
                    sx={{
                      width: '400px',
                      paddingY: '15px',
                      borderRadius: '30px',
                    }}
                  >
                    {terms.StationsDashboard.ImagePreview.photoValidate}
                  </LoadingButton>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Fade>
      <Snackbar message={errorMessage} error={errorFound} />
      <Loading open={loading} />
    </main>
  )
}
