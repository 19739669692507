import { ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import { setColorScheme } from 'reducers/stations'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

export default function BtSwitchColor(): ReactElement {
  const dispatch = useDispatch()
  const { monochrome } = useSelector((state: RootState) => state.stations)
  const toggleScheme = () => {
    dispatch(setColorScheme(!monochrome))
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography
        sx={{
          fontFamily: 'Avenir', fontSize: '12px', color: 'white',
        }}
      >
        {terms.TabMenu.monochrome}
      </Typography>
      <Switch
        checked={!monochrome}
        onChange={toggleScheme}
      />
      <Typography
        sx={{
          fontFamily: 'Avenir', fontSize: '12px', color: 'white', pr: '20px',
        }}
      >
        {terms.TabMenu.multicolor}
      </Typography>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{
          bgcolor: 'gray', height: '20px', alignSelf: 'center',
        }}
      />
    </Stack>
  )
}
