import {
  Badge,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ThemeProvider,
} from '@mui/material'
import { filterButton, customApplyFiltersButton, customResetFiltersButton } from 'common/CustomTheme'
import terms from 'common/terms'
import FilterIcon from 'components/Common/CustomImages/FilterIcon'
import { ReactElement, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import { setTrainsList, updateFilters } from 'reducers/metrics'
import { RootState } from 'Store'
import { reset, toggleDisplayRequiredNotice } from 'reducers/filters'
import FiltersDialogContent from './FiltersDialogContent'

import './filter.scss'

export default function FiltersModal(): ReactElement {
  const dispatch = useDispatch()
  const {
    selectedBalanceType, selectedCirculationType, selectedTracks, selectedEquipments,
    trainsRange, trainsNumber, timeRange, originDestination, timeRangeFields,
  } = useSelector((state: RootState) => state.filters)
  const {
    balanceType, circulationType, metricsList, filters,
  } = useSelector((state: RootState) => state.metrics)
  const [open, setOpen] = useState<boolean>(false)

  const handleClick = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    dispatch(reset())
  }

  const resetFilters = () => {
    setOpen(false)
    dispatch(updateFilters(undefined))
    dispatch(reset())
  }

  const applyFilters = () => {
    if (timeRange.length !== 0 || (timeRange.length === 0
      && timeRangeFields.filter(range => range.startTime === null).length === timeRangeFields.length
      && timeRangeFields.filter(range => range.endTime === null).length === timeRangeFields.length)) {
      dispatch(updateFilters({
        balance_type: selectedBalanceType.length !== balanceType.length ? selectedBalanceType : [],
        circulation_type: selectedCirculationType.length !== circulationType.length ? selectedCirculationType : [],
        multi_time_range: timeRange,
        track: selectedTracks.length !== metricsList?.track_list.length ? selectedTracks : [],
        equipment: selectedEquipments.length !== metricsList?.material_code_list.length ? selectedEquipments : [],
        train_number: trainsNumber,
        train_number_range: trainsRange,
        ori_dest: originDestination,
      }))
      dispatch(toggleDisplayRequiredNotice(false))
      dispatch(setTrainsList())
      setOpen(false)
    } else {
      dispatch(toggleDisplayRequiredNotice(true))
    }
  }

  return (
    <>
      <Grid item>
        <ThemeProvider theme={filterButton}>
          <Badge
            badgeContent={filters ? Object.values(filters).filter((v: string[]) => v.length !== 0).length : undefined}
            className="badge-style"
          >
            <Button
              variant="contained"
              disableElevation
              startIcon={<FilterIcon />}
              onClick={handleClick}
            >
              {terms.Filters.filters}
            </Button>
          </Badge>
        </ThemeProvider>
      </Grid>
      <Dialog
        open={open}
        className="filters-dialog"
      >
        <DialogTitle>
          {terms.Filters.filters}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ marginRight: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <FiltersDialogContent />
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={customResetFiltersButton}>
            <Button onClick={resetFilters}>
              {terms.Filters.resetFilters}
            </Button>
          </ThemeProvider>
          <ThemeProvider theme={customApplyFiltersButton}>
            <Button onClick={applyFilters}>
              {terms.Filters.applyFilters}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </>
  )
}
