/* eslint-disable max-len */
import * as React from 'react'
import { ReactElement } from 'react'
import history from 'customHistory'
import { ThemeProvider, useTheme } from '@mui/material/styles'
import { setRequiredConfig } from 'reducers/stations'
import terms from 'common/terms'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ErrorIcon from '@mui/icons-material/Error'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { customDarkButton, customWhiteButton } from 'common/CustomTheme'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { Station } from 'reducers/types'
import PathNames from 'common/PathNames'

type Props = {
  station: Station;
}

export default function ConfigDialog({ station }: Props): ReactElement {
  const dispatch = useDispatch()
  const { requiredConfig, activeStation } = useSelector((state: RootState) => state.stations)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClose = () => {
    dispatch(setRequiredConfig(false))
  }
  const handleClick = () => {
    dispatch(setRequiredConfig(false))
    history.push(PathNames.TrigramConfig)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={requiredConfig}
        onClose={handleClose}
        aria-labelledby={terms.StationsDashboard.Dialog.dialogTitle}
      >
        <DialogTitle id={terms.StationsDashboard.Dialog.dialogTitle}>
          <Grid container spacing={2} sx={{ padding: '30px' }}>
            <Grid item xs={11}>
              <Typography variant="h4" color="rgba(68, 84, 127, 1)">
                {terms.StationsDashboard.Dialog.dialogTitle}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <ErrorIcon fontSize="large" />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2} sx={{ pl: '30px', pr: '30px' }}>
              <Grid item>
                {activeStation?.est_admin ? (
                  <Typography sx={{ fontSize: 20 }}>
                    {terms.StationsDashboard.Dialog.warningAdmin}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: 20 }}>
                    {terms.StationsDashboard.Dialog.warningOthers}
                  </Typography>
                )}

              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} sx={{ padding: '30px' }}>
            <Grid item xs={5}>
              <ThemeProvider theme={customWhiteButton}>
                <Button
                  autoFocus
                  onClick={handleClose}
                  sx={{
                    paddingX: '30px',
                    paddingY: '10px',
                    borderRadius: '30px',
                    borderColor: 'rgba(235, 235, 235, 1)',
                  }}
                  variant="outlined"
                  disableElevation
                >
                  {terms.common.return}
                </Button>
              </ThemeProvider>
            </Grid>
            {activeStation?.est_admin && (
            <Grid container item xs={7} justifyContent="flex-end">
              <ThemeProvider theme={customDarkButton}>
                <Button
                  autoFocus
                  onClick={handleClick}
                  sx={{
                    paddingX: '40px',
                    paddingY: '10px',
                    borderRadius: '30px',
                    color: 'white',
                  }}
                  variant="outlined"
                  disableElevation
                  disabled={!activeStation?.est_admin}
                >
                  {terms.StationsDashboard.Dialog.configStationBt}
                </Button>
              </ThemeProvider>
            </Grid>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}
