/* eslint-disable no-loop-func */
/* eslint-disable max-len */
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateConfirmedDates, updateSelectedDates } from 'reducers/annualServices'
import { addDays, format } from 'date-fns'
import { useEffect, useState } from 'react'
import terms from 'common/terms'
import IconButton from '@mui/material/IconButton'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export default function DatesList() {
  const dispatch = useDispatch()

  const { selectedDates, availableDates2 } = useSelector((state: RootState) => state.annualServices)
  const [filteredDates, setFilteredDates] = useState([])
  const [datesAndRanges, setDatesAndRanges] = useState([])
  const [lookForRanges, setLookForRanges] = useState(false)

  const removeDates = (deleteDate: any) => {
    if (selectedDates[0]) {
      let array = selectedDates
      if ((typeof deleteDate) === 'string') {
        array = array.filter(date => format(new Date(date), 'dd/MM/yyyy') !== deleteDate)
        dispatch(updateSelectedDates(array))
      } else {
        const start = deleteDate[0]
        const end = deleteDate[2]
        const startRange = start.toString().replace(/\//g, '-')
        const endRange = end.toString().replace(/\//g, '-')
        const date1 = startRange.split('-').reverse().join('-')
        const date2 = endRange.split('-').reverse().join('-')
        let currentDate = date1
        while (currentDate <= date2) {
          array = array.filter(date => date !== currentDate)
          const nextDate = addDays(new Date(currentDate), 1)
          currentDate = format(new Date(nextDate), 'yyyy-MM-dd')
        }
        dispatch(updateSelectedDates(array))
      }
    }
  }

  const removeAllDates = () => {
    dispatch(updateSelectedDates([]))
  }

  useEffect(() => {
    const array: string[] = selectedDates
    const flatedArray = new Set(array)
    const orderedArray = [...flatedArray].sort()
    const availableArray = orderedArray.filter(date => availableDates2.includes(date))
    dispatch(updateConfirmedDates(availableArray))
    setFilteredDates(availableArray)
    setDatesAndRanges([])
    setLookForRanges(!lookForRanges)
  }, [selectedDates])

  useEffect(() => {
    if (filteredDates[0]) {
      const oneDay = 86400000
      let array1 = filteredDates
      let array2 = datesAndRanges
      let date1 = array1[0] ? Date.parse(array1[0]) : undefined
      let date2 = array1[1] ? Date.parse(array1[1]) : undefined
      let date3 = array1[2] ? Date.parse(array1[2]) : undefined
      let startRange = ''
      let endRange = ''
      switch (true) {
        case (!date3):
          if (date2 - date1 > oneDay) {
            array2 = date2 ? [...array2, format(new Date(date1), 'dd/MM/yyyy'), format(new Date(date2), 'dd/MM/yyyy')] : [...array2, format(new Date(date1), 'dd/MM/yyyy')]
          } else {
            array2 = date2 ? [...array2, [format(new Date(date1), 'dd/MM/yyyy'), '-', format(new Date(date2), 'dd/MM/yyyy')]] : [...array2, format(new Date(date1), 'dd/MM/yyyy')]
          }
          setDatesAndRanges(array2)
          setFilteredDates([])
          break
        case (date2 - date1 > oneDay):
          while (date3 && (date2 - date1 > oneDay)) {
            array2 = [...array2, format(new Date(date1), 'dd/MM/yyyy')]
            array1 = array1.filter(date => Date.parse(date) !== date1)
            date1 = array1[0] ? Date.parse(array1[0]) : undefined
            date2 = array1[1] ? Date.parse(array1[1]) : undefined
            date3 = array1[2] ? Date.parse(array1[2]) : undefined
          }
          setDatesAndRanges(array2)
          setFilteredDates(array1)
          setLookForRanges(!lookForRanges)
          break
        case (date3 - date1 === oneDay * 2):
          startRange = format(new Date(date1), 'dd/MM/yyyy')
          while (date3 && (date3 - date1 === oneDay * 2)) {
            array1 = array1.filter(date => Date.parse(date) !== date1)
            array1 = array1.filter(date => Date.parse(date) !== date2)
            date1 = array1[0] ? Date.parse(array1[0]) : undefined
            date2 = array1[1] ? Date.parse(array1[1]) : undefined
            date3 = array1[2] ? Date.parse(array1[2]) : undefined
          }
          endRange = date2 && (date2 - date1 === oneDay) ? format(new Date(array1[1]), 'dd/MM/yyyy') : format(new Date(array1[0]), 'dd/MM/yyyy')
          array2 = [...array2, [startRange, ' - ', endRange]]
          setDatesAndRanges(array2)
          array1 = array1.filter(date => Date.parse(date) !== date1)
          array1 = date2 && (date2 - date1 === oneDay) ? array1.filter(date => Date.parse(date) !== date2) : array1
          setFilteredDates(array1)
          setLookForRanges(!lookForRanges)
          break
        case (date2 - date1 === oneDay):
          array2 = [...array2, [format(new Date(date1), 'dd/MM/yyyy'), '-', format(new Date(date2), 'dd/MM/yyyy')]]
          array1 = array1.filter(date => Date.parse(date) !== date1)
          array1 = array1.filter(date => Date.parse(date) !== date2)
          setDatesAndRanges(array2)
          setFilteredDates(array1)
          setLookForRanges(!lookForRanges)
          break
        default:
          break
      }
    }
  }, [lookForRanges])

  return (
    <Stack sx={{
      minHeight: '280px', width: '250px',
    }}
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Avenir', fontWeight: 'bold', mb: '10px', mt: '10px', alignSelf: 'center',
        }}
      >
        {terms.DatePicker.sectionTitle}
      </Typography>
      <Stack sx={{ height: '100%', width: '100%', justifyContent: 'flex-start' }}>
        {datesAndRanges.length !== 0 && datesAndRanges.map((date: any) => (
          <Stack
            direction="row"
            spacing={5}
            key={date}
            sx={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: '400',
                color: 'black',
                cursor: 'pointer',
              }}
            >
              {date}
            </Typography>
            <IconButton
              sx={{ color: 'black', alignSelf: 'flex-end' }}
              aria-label="close"
              onClick={() => removeDates(date)}
            >
              <HorizontalRuleIcon />
            </IconButton>
          </Stack>
        ))}
      </Stack>
      {datesAndRanges[0] && (
      <IconButton
        sx={{ color: 'red', justifyContent: 'center', alignItems: 'flex-end' }}
        aria-label="removeAllDates"
        onClick={() => removeAllDates()}
      >
        <DeleteForeverRoundedIcon />
      </IconButton>
      )}
    </Stack>
  )
}
