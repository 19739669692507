import { RootState } from 'Store'
import terms from 'common/terms'
import Loading from 'components/Common/Backdrop/Backdrop'
import { format } from 'date-fns'
import {
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import normesService, { GOVMetrics, MetricsNormes } from 'reducers/services/normesService'
import { createFileName, useScreenshot } from 'use-react-screenshot'
import Header from '../Common/Header'
import ReoccupationLineChart from './Component/ReoccupationLineChart'

import './style.scss'
import SelectContainer from '../Conflict/Component/SelectContainer'
import ReoccupationTable from './Component/ReoccupationTable'
import BarChartHeader from '../Common/BarChartHeader'

interface Props {
  index: number;
  value: number;
  type: GOVMetrics;
}

const Reoccupation = ({ index, value, type }: Props): ReactElement => {
  const dispatch = useDispatch()
  const {
    normesList,
    isLoading,
    isLoadingReoccupationLine,
    reoccupationLine,
  } = useSelector((state: RootState) => state.normes)
  const { filters } = useSelector((state: RootState) => state.metrics)
  const [sortTable, setSortTable] = useState<string>('')
  const { activeStation } = useSelector((state: RootState) => state.stations)
  const [waySelected, setWaySelected] = useState<string[]>([])
  const refLine = useRef<HTMLDivElement>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [image, takeScreenshot] = useScreenshot()

  const normesSelected = normesList.filter((norme: MetricsNormes) => norme.board === terms.NormesRE.Reoccupation.tab)

  const download = (
    currentImage, {
      name = `${activeStation.abv_traction}-${format(new Date(Date.now()), 'dd-MM-yyyy')}`, extension = 'jpeg',
    } = {},
  ) => {
    const a = document.createElement('a')
    a.href = currentImage
    a.download = createFileName(extension, name)
    a.click()
  }

  const getImage = ref => takeScreenshot(ref.current).then(download)

  useEffect(() => {
    if (value === index) {
      if (normesSelected) {
        normesSelected.map(item => (
          dispatch(normesService.getMetricsNormes({
            uri: item?.uri.substring(
              item.uri.indexOf('/usage_reseau'),
            ),
            gov_a: item?.payload[type].gov_a.toString(),
            type_gov_a: type,
            metricType: terms.NormesRE.Reoccupation.tab,
            filters,
            status: '',
            board: item.board,
            ...(sortTable && { sort: sortTable }),
          }))
        ))
      }
    }
  }, [normesList, type, filters, sortTable])

  useEffect(() => {
    setWaySelected(reoccupationLine.data.map(item => item.id))
  }, [reoccupationLine.data])

  return (
    <div className="reoccupation">
      <Loading open={isLoading || isLoadingReoccupationLine} />
      <div>
        <div className="reoccupation-graph" ref={refLine}>
          <Header
            title={terms.NormesRE.Reoccupation.Header.title}
            subTitle={terms.NormesRE.Reoccupation.Header.subTitle}
          />
          <SelectContainer
            selectTypeLabel={terms.NormesRE.Reoccupation.LineChart.SelectWays.label}
            options={reoccupationLine.data.map(item => ({
              key: item.id,
              value: item.id,
            }))}
            selected={waySelected}
            handleSelectType={setWaySelected}
            refImage={refLine}
            handleClick={getImage}
          />
          <div className="line-info">
            <BarChartHeader info={reoccupationLine.meta.info} />
          </div>
          <ReoccupationLineChart waySelected={waySelected} />
        </div>
        <div data-html2canvas-ignore>
          <ReoccupationTable
            normesSelected={normesSelected}
            type={type}
            sortTable={sortTable}
            setSortTable={setSortTable}
          />
        </div>
      </div>
    </div>
  )
}

export default Reoccupation
