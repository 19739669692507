/* eslint-disable camelcase */
/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { format } from 'date-fns'
import _ from 'lodash'
import Paper from '@mui/material/Paper'
import LoadingButton from '@mui/lab/LoadingButton'
import WarningCard from 'components/Common/WarningCard/WarningCard'
import MissingGovAlert from 'components/MissingGov/MissingGovAlert'
import { updateLoadingExport } from 'reducers/missingPagine'
import Grid from '@mui/material/Grid'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import terms from 'common/terms'
import fetchPagine from './utils'
import '../station.scss'

interface TabPanelProps {
  index: number;
  value: number;
}

export default function Pagine(props: TabPanelProps): ReactElement {
  const dispatch = useDispatch()
  const {
    value, index, ...other
  } = props
  const {
    metricsList, filters, fromGroix,
  } = useSelector((state: RootState) => state.metrics)
  const { loadingExport } = useSelector((state: RootState) => state.missingPagine)
  const [dataPagine, setDataPagine] = useState(true)

  useEffect(() => {
    setDataPagine(true)
  }, [metricsList, filters])

  const savefile = responseText => {
    const blob = new Blob([responseText])
    if (responseText.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      const url = window.URL.createObjectURL(blob)
      const outputFilename = `PAGINE - ${format(new Date(Date.now()), 'dd-MM-yyyy')}.xlsx`
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', outputFilename)
      document.body.appendChild(link)
      link.click()
      dispatch(updateLoadingExport(false))
    } else {
      setDataPagine(false)
      dispatch(updateLoadingExport(false))
    }
  }

  const handleExport = () => {
    const result = metricsList?.metrics?.filter(obj => obj?.board === 'PAGINE')
    if (result) {
      dispatch(updateLoadingExport(true))
      const object = result[0]?.payload
      const gov_id = object?.gov_id
      const journee_pagine_id = object?.journee_pagine_id
      fetchPagine(gov_id?.toString(), journee_pagine_id?.toString(), filters, savefile)
    }
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-dv-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper sx={{
          width: '100%', height: '100%', boxShadow: 'none',
        }}
        >
          {metricsList?.missing_days?.length !== 0 && !fromGroix && <MissingGovAlert />}
          {!dataPagine && <WarningCard message={terms.Filters.noResultNotice} />}
          {dataPagine && (
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            height="calc(100vh - 155px)"
            sx={{
              backgroundColor: '#EDF0F3',
            }}
          >
            <FileDownloadOutlinedIcon sx={{
              fontSize: 170, color: '#A5A5A5', mb: 5, mt: 10,
            }}
            />
            <LoadingButton
              onClick={() => handleExport()}
              loading={loadingExport}
              sx={{
                textTransform: 'none',
                paddingX: '30px',
                paddingY: '10px',
                borderRadius: '5px',
                borderColor: 'red',
              }}
              variant="contained"
            >
              {terms.common.pagineExport}
            </LoadingButton>
          </Grid>
          )}
        </Paper>
      )}
    </div>
  )
}
