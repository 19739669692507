import { Box } from '@mui/material'
import { ResponsiveBar } from '@nivo/bar'
import { RootState } from 'Store'
import terms from 'common/terms'
import MissingData from 'components/Station/MissingData'
import TooltipBarCHart from 'components/StationRE/Common/TooltipBarChart'
import { COLORS_EVOLUTION, COLORS_EVOLUTION_MONO } from 'components/StationRE/Const'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import normesService, { ReusabilityBarData } from 'reducers/services/normesService'

interface Props {
  data: ReusabilityBarData[];
}

const ReusabilityBarChart = ({ data }: Props): ReactElement => {
  const { monochrome } = useSelector((state: RootState) => state.stations)
  const { isLoading, errorReutilityBar, isLoadingReutilityBar } = useSelector((state: RootState) => state.normes)

  const typeData = () => {
    if (data.length === 0) return []
    return Object.keys(data[0]).filter(item => item !== 'x')
  }

  const findMaxValue = () => {
    let maxValue = -Infinity
    data.forEach(item => {
      const value = item['Non respect du temps minimal RE']
      if (value > maxValue) {
        maxValue = value
      }
    })
    return maxValue
  }

  return (
    <Box sx={{
      height: '62vh',
      width: '85vw',
      margin: 'auto',
    }}
    >
      <ResponsiveBar
        data={data}
        keys={typeData()}
        indexBy="x"
        groupMode="grouped"
        margin={{
          top: 40, right: 350, bottom: 150, left: 80,
        }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={monochrome ? COLORS_EVOLUTION_MONO : COLORS_EVOLUTION}
        theme={{
          grid: {
            line: {
              strokeWidth: 1,
              stroke: '#EDEDED',
              opacity: 0.5,
            },
          },
          legends: {
            text: {
              fontSize: 14,
              fontWeight: 900,
            },
          },
          axis: {
            legend: { text: { fontWeight: 900, fill: '#000', fontSize: '14px' } },
          },
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legend: terms.NormesRE.Reusability.BarChart.xAxis,
          legendPosition: 'middle',
          legendOffset: 80,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: terms.NormesRE.Reusability.BarChart.y,
          legendPosition: 'middle',
          legendOffset: -60,
          tickValues: normesService.generateTickBarValues(findMaxValue()),
        }}
        isInteractive
        enableLabel={false}
        tooltip={bar => (
          <TooltipBarCHart tooltipProps={bar} withLabel />
        )}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
          },
        ]}
        onMouseEnter={(_data, event) => {
          const target = event.target as HTMLElement
          target.style.animation = 'barstrokeout 300ms forwards'
        }}
        onMouseLeave={(_data, event) => {
          const target = event.target as HTMLElement
          target.style.animation = 'barstrokein 300ms forwards'
        }}
        layers={[
          'grid',
          'axes',
          'markers',
          'bars',
          'legends',
        ]}
      />
      {
        (data.length === 0) && !isLoading && !isLoadingReutilityBar && (
          <div
            className="missing-data"
          >
            <MissingData
              message={errorReutilityBar ? errorReutilityBar.message.detail : terms.NormesRE.noData}
            />
          </div>
        )
      }
    </Box>
  )
}

export default ReusabilityBarChart
