import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSelectedEquipments } from 'reducers/filters'
import { RootState } from 'Store'
import terms from 'common/terms'
import EquipmentsService from 'reducers/services/equipmentsService'
import FiltersChips from './FiltersChips'
import BtSelectAll from './BtSelectAll'

export default function EquipmentsFilter(): ReactElement {
  const dispatch = useDispatch()
  const { activeStation } = useSelector((state: RootState) => state.stations)
  const { filters } = useSelector((state: RootState) => state.metrics)
  const { selectedEquipments } = useSelector((state: RootState) => state.filters)
  const { equipmentsList } = useSelector((state: RootState) => state.equipments)
  const { metricsList } = useSelector((state: RootState) => state.metrics)

  useEffect(() => {
    if (filters && selectedEquipments.length === 0) {
      dispatch(updateSelectedEquipments(filters.equipment))
    }
  }, [filters])

  const onEquipmentClick = (equipment: string) => {
    const codesList: string[] = []
    if (selectedEquipments.filter(type => type === equipment)?.length === 0) {
      codesList.push(...selectedEquipments, equipment)
    } else {
      codesList.push(...selectedEquipments.filter(type => type !== equipment))
    }
    dispatch(updateSelectedEquipments(codesList))
  }

  const selectAllEquipments = () => {
    dispatch(updateSelectedEquipments(metricsList?.material_code_list))
  }
  useEffect(() => {
    dispatch(EquipmentsService.getEquipmentsList({ id: activeStation.id }))
  }, [])

  return (
    <>
      <FiltersChips
        title={terms.Filters.equipment}
        data={metricsList?.material_code_list}
        handleClick={onEquipmentClick}
        selectedValues={selectedEquipments}
      />
      <BtSelectAll
        disabled={metricsList?.material_code_list.length === selectedEquipments.length}
        btSelectAll={terms.Filters.btSelectAllEquipments}
        handleClick={selectAllEquipments}
      />
    </>
  )
}
