import { PropsWithChildren, RefObject } from 'react'
import { ConflictsType } from 'reducers/services/normesService'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { ColorButton } from 'common/CustomTheme'
import terms from 'common/terms'
import CustomSelectType from './CustomSelectType'

interface Props {
  selectTypeLabel: string;
  options: ConflictsType[];
  selected: string[];
  handleSelectType: (value: string[]) => void;
  isSubType?: boolean;
  refImage: RefObject<HTMLDivElement>;
  handleClick: (refImage: RefObject<HTMLDivElement>) => void;

}

const SelectContainer = ({
  selectTypeLabel,
  options,
  selected,
  handleSelectType,
  isSubType,
  children,
  refImage,
  handleClick,
}: PropsWithChildren<Props>) => (
  <div className="select-container">
    <div className="select" data-html2canvas-ignore>
      <CustomSelectType
        label={selectTypeLabel}
        options={options}
        selected={selected}
        size="medium"
        handleSelect={handleSelectType}
        isSubType={isSubType}
        data-html2canvas-ignore
      />
    </div>
    {
      children && children
    }
    <div>
      <ColorButton
        onClick={() => handleClick(refImage)}
        startIcon={<FileDownloadOutlinedIcon />}
        data-html2canvas-ignore
      >
        {terms.common.jpegExport}
      </ColorButton>
    </div>
  </div>
)

SelectContainer.defaultProps = {
  isSubType: false,
}

export default SelectContainer
