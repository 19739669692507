import { Box } from '@mui/material'
import { ResponsiveLine } from '@nivo/line'
import { RootState } from 'Store'
import terms from 'common/terms'
import {
  ReactElement, useCallback, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedPoint } from 'reducers/normes'
import { throttle } from 'lodash'
import MissingData from 'components/Station/MissingData'
import { COLORS_EVOLUTION, COLORS_EVOLUTION_MONO } from '../../Const'
import PointLayer from './PointLayer'
import TooltipLine from './TooltipLine'

import './style.scss'

interface Props {
  normesClasseSelected: string[];
  isChangingStatus: boolean;
}

const ConflictLineChart = ({ normesClasseSelected, isChangingStatus }: Props): ReactElement => {
  const dispatch = useDispatch()
  const {
    normesLineList,
    isLoading,
    errorConflictLine,
    isLoadingConflictLine,
  } = useSelector((state: RootState) => state.normes)
  const { monochrome } = useSelector((state: RootState) => state.stations)
  const [data, setData] = useState([])

  useEffect(() => {
    setData(normesLineList.filter(item => normesClasseSelected.includes(item.id)))
  }, [normesClasseSelected, isChangingStatus])

  const tooltipData = point => {
    const results = []
    data.forEach(element => {
      const dataForDate = element.data.find(d => d.x === point.data.x.toString())

      const somme = dataForDate.y

      results.push({ id: element.id, result: somme })
    })
    dispatch(setSelectedPoint({ id: point.id, date: point.data.x }))

    return <TooltipLine date={point.data.x.toString()} data={results} hoveredId={point.serieId} />
  }
  const callback = useCallback(throttle(point => tooltipData(point), 300), [normesClasseSelected, normesLineList, data])

  const maxValuesData = Math.max(...data.flatMap(item => item.data).map(item => item.y))
  const generateTickValues = () => {
    if (maxValuesData === -Infinity) return []
    const values = []
    for (let i = 0; i <= maxValuesData; i += 1) {
      values.push(i)
    }
    return values
  }

  return (
    <Box sx={{
      height: '60vh',
      width: '80vw',
      margin: 'auto',
    }}
    >
      <ResponsiveLine
        data={data}
        margin={{
          top: 50, right: 250, bottom: 80, left: 100,
        }}
        enableArea
        animate
        defs={[
          {
            colors: [
              {
                color: 'inherit',
                offset: 0,
              },
              {
                color: 'inherit',
                offset: 100,
                opacity: 0,
              },
            ],
            id: 'gradientA',
            type: 'linearGradient',
          },
        ]}
        fill={[
          {
            id: 'gradientA',
            match: '*',
          },
        ]}
        colors={monochrome ? COLORS_EVOLUTION_MONO : COLORS_EVOLUTION}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 0,
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        theme={{
          grid: {
            line: {
              strokeWidth: 1,
              stroke: '#EDEDED',
              opacity: 0.5,
            },
          },
          legends: {
            text: {
              fontSize: 14,
              fontWeight: 900,
              marginLeft: 10,
            },
          },
          axis: {
            legend: { text: { fontWeight: 900, fill: '#000', fontSize: '14px' } },
          },
        }}
        yFormat=">-.2f"
        curve="monotoneX"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 50,
          legend: 'Dates',
          legendPosition: 'middle',
          legendOffset: 70,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: terms.NormesRE.ConflictsGov.ConflictsMetrics.legends.ordonnee,
          legendPosition: 'middle',
          legendOffset: -50,
          tickValues: generateTickValues(),
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh
        tooltip={({ point }) => callback(point)}
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'lines',
          PointLayer,
          'slices',
          'mesh',
          'legends',
        ]}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
          },
        ]}
      />
      {
        (data.length === 0) && !isLoading && !isLoadingConflictLine && (
          <div
            className="missing-data no-data-line"
          >
            <MissingData
              message={errorConflictLine ? errorConflictLine.message.detail : terms.NormesRE.noData}
            />
          </div>
        )
      }
    </Box>
  )
}

export default ConflictLineChart
