import { TabValues } from 'types'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import TrainIcon from '@mui/icons-material/TrainOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const TAB_MENU: TabValues[] = [
  {
    index: 0,
    label: 'Conflits GOV',
    icon: <ErrorOutlineIcon fontSize="small" />,
    iconPosition: 'start',
  },
  {
    index: 1,
    label: 'Réutilisation',
    iconPosition: 'start',
    icon: <AccountBalanceOutlinedIcon fontSize="small" />,
  },
  {
    index: 2,
    label: 'Réoccupation',
    iconPosition: 'start',
    icon: <TrainIcon fontSize="small" />,
  },
]

export default TAB_MENU
