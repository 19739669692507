import {
  Button, Card, CardActions, CardContent, ThemeProvider,
} from '@mui/material'
import { ReactElement, useEffect } from 'react'
import warningIcon from 'assets/icons/warning.png'
import terms from 'common/terms'
import { customIgnoreButton, customImportButton } from 'common/CustomTheme'

import './missingGov.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateDisplayIconNotice, updateDisplayMissingGovNotice } from 'reducers/missingGov'
import history from 'customHistory'
import GovService from 'reducers/services/govService'
import PagineService from 'reducers/services/pagineService'
import PathNames from 'common/PathNames'

export default function MissingGovAlert(): ReactElement {
  const dispatch = useDispatch()
  const { displayMissingGovNotice, displayIconNotice } = useSelector((state: RootState) => state.missingGov)
  const { metricsList } = useSelector((state: RootState) => state.metrics)

  const hasPagine = () => metricsList.metrics.find(board => board.board === 'PAGINE')

  const missingDays = metricsList && metricsList.length !== 0
    ? Object.values(metricsList?.missing_days).filter((value: any) => value.length !== 0) : []
  const missingGare = metricsList && metricsList.length !== 0
    ? Object.entries(metricsList?.gare).filter(([key, value]: [key: string, value: any]) => value.length !== 0
    && (key !== 'PAGINE' && !hasPagine())) : []

  useEffect(() => {
    if (metricsList && missingDays.length !== 0) {
      Object.keys(metricsList.missing_days).forEach((type: string) => {
        if (metricsList.missing_days[type].length !== 0) {
          const ids: string[] = []
          metricsList.missing_days[type].forEach(day => {
            if (Object.values(day)[0] !== '') {
              ids.push(Object.values(day)[0] as string)
            }
          })
          if (ids.length !== 0 && type === 'PAGINE') {
            dispatch(PagineService.getPagines(ids.toString()))
          }
          if (ids.length !== 0) {
            dispatch(GovService.getGovs({ ids: ids.toString(), comparaisontype: type }))
          }
        }
      })
    }
  }, [metricsList])

  const handleIgnore = () => {
    dispatch(updateDisplayIconNotice(true))
  }

  const handleImportGov = () => {
    history.push(PathNames.MissingGov)
    dispatch(updateDisplayMissingGovNotice(false))
  }

  const onIconClick = () => {
    dispatch(updateDisplayIconNotice(false))
  }

  useEffect(() => () => {
    dispatch(updateDisplayIconNotice(false))
  }, [])

  return (
    displayIconNotice ? (
      <Card className="missing-gov-icon" onClick={onIconClick}>
        <img src={warningIcon} alt="" width={40} />
      </Card>
    )
      : (!displayMissingGovNotice && (missingDays.length !== 0
        || (missingGare.length !== 0)) && (
          <Card className="missing-gov-card">
            <CardContent className="p-0">
              <div className="d-flex align-items-center justify-content-center h-100 flex-row">
                <img src={warningIcon} alt="" width={20} />
                <h1 className="font-weight-bold ml-4">{terms.MissingGov.missingGovNotice}</h1>
              </div>
            </CardContent>
            <CardActions>
              <ThemeProvider theme={customIgnoreButton}>
                <Button onClick={handleIgnore}>
                  {terms.MissingGov.ignoreBtn}
                </Button>
              </ThemeProvider>
              <ThemeProvider theme={customImportButton}>
                <Button onClick={handleImportGov}>
                  {terms.MissingGov.importGov}
                </Button>
              </ThemeProvider>
            </CardActions>
          </Card>
      ))
  )
}
