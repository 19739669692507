/* eslint-disable max-len */
/* eslint-disable dot-notation */
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { ResponsivePie } from '@nivo/pie'
import { animated } from '@react-spring/web'
import { linearGradientDef } from '@nivo/core'
import FilterH00Btn from './FilterH00Btn'
import PonctualityHeader from './PonctualityHeader'
import PieChartInfo from './PieChartInfo'
import '../station.scss'
import PieChartTooltip from './PieChartTooltip'
import {
  color1b, color10b, color8b, color9b, color5b, color6b, color7b,
} from '../colorSchemes'

type Props = {
  data: any;
  title: string;
  infoTitle: string;
  infoTooltip: string;
  filterHOO?: boolean;
}

const defaultProps = {
  filterHOO: false,
}

export default function PonctualityPieChart({
  data, title, infoTitle, infoTooltip, filterHOO,
}: Props): ReactElement {
  const { monochrome } = useSelector((state: RootState) => state.stations)

  return (
    <>
      <Grid container sx={{ alignItems: 'center' }}>
        <Grid item xs={12} sx={{ position: 'relative' }}>
          {filterHOO && (
          <FilterH00Btn />
          )}
          <PonctualityHeader title={title} />
        </Grid>
        <PieChartInfo title={title} data={data} infoTitle={infoTitle} infoTooltip={infoTooltip} />
        <Grid item xs={7}>
          <Box sx={{ p: 2, height: '450px' }}>
            <ResponsivePie
              data={data['pie-chart']}
              activeOuterRadiusOffset={20}
              arcLinkLabelsSkipAngle={10}
              padAngle={1}
              margin={{
                top: 30, right: 30, bottom: 30, left: 30,
              }}
              arcLinkLabelsDiagonalLength={8}
              arcLinkLabelsStraightLength={8}
              arcLinkLabelsTextOffset={2}
              borderColor="#ffffff"
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={1}
              arcLabelsSkipAngle={15}
              defs={[
                linearGradientDef('5', [
                  { offset: 0, color: monochrome ? '#d1e1ef' : color9b },
                  { offset: 100, color: monochrome ? '#d1e1ef' : color9b, opacity: 1 },
                ]),
                linearGradientDef('4', [
                  { offset: 0, color: monochrome ? '#accae3' : color7b },
                  { offset: 100, color: monochrome ? '#accae3' : color7b, opacity: 1 },
                ]),
                linearGradientDef('3', [
                  { offset: 0, color: monochrome ? '#88b2d6' : color6b },
                  { offset: 100, color: monochrome ? '#88b2d6' : color6b, opacity: 1 },
                ]),
                linearGradientDef('2', [
                  { offset: 0, color: monochrome ? '#4083bc' : color10b },
                  { offset: 100, color: monochrome ? '#4083bc' : color10b, opacity: 1 },
                ]),
                linearGradientDef('1', [
                  { offset: 0, color: monochrome ? '#336998' : color8b },
                  { offset: 100, color: monochrome ? '#336998' : color8b, opacity: 1 },
                ]),
                linearGradientDef('0', [
                  { offset: 0, color: monochrome ? '#1a374f' : color1b },
                  { offset: 100, color: monochrome ? '#1a374f' : color1b, opacity: 1 },
                ]),
                linearGradientDef('00', [
                  { offset: 0, color: monochrome ? '#0e1d2a' : color5b },
                  { offset: 100, color: monochrome ? '#0e1d2a' : color5b, opacity: 1 },
                ]),
              ]}
              fill={[
                {
                  match: {
                    id: '<0',
                  },
                  id: '00',
                },
                {
                  match: {
                    id: '0',
                  },
                  id: '0',
                },
                {
                  match: {
                    id: '+1',
                  },
                  id: '1',
                },
                {
                  match: {
                    id: '+2',
                  },
                  id: '2',
                },
                {
                  match: {
                    id: '+3',
                  },
                  id: '3',
                },
                {
                  match: {
                    id: '+4',
                  },
                  id: '4',
                },
                {
                  match: {
                    id: '+5',
                  },
                  id: '5',
                },
              ]}
              tooltip={e => <PieChartTooltip event={e} title={title} />}
              arcLabelsComponent={({ label, style }) => (
                <animated.g transform={style.transform} style={{ pointerEvents: 'none' }}>
                  <text
                    textAnchor="middle"
                    dominantBaseline="central"
                    fill="white"
                    style={{
                      fontSize: 14,
                      fontWeight: 800,
                      textShadow: '1px 0 black, 0 1px black, -1px 0 black, 0 -1px black,-1px -1px black, -1px 1px black, 1px -1px black, 1px 1px black',
                    }}
                  >
                    {label}
                  </text>
                </animated.g>
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

PonctualityPieChart.defaultProps = defaultProps
