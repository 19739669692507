import { ColorButton } from 'common/CustomTheme'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { ReactElement } from 'react'
import terms from 'common/terms'

interface Props {
  onDownload: () => void;
}

const ReusabilityTableHeader = ({ onDownload }: Props): ReactElement => (
  <header className="reusability-table-title">
    <div className="title">
      <span>
        {terms.NormesRE.Reusability.Table.title}
      </span>
      <span className="bold">
        {terms.NormesRE.Reusability.Table.perType}
      </span>
    </div>
    <div className="export">
      <ColorButton
        onClick={onDownload}
        startIcon={<FileDownloadOutlinedIcon />}
        data-html2canvas-ignore
      >
        {terms.common.excelExport}
      </ColorButton>
    </div>
  </header>
)

export default ReusabilityTableHeader
