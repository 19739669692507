/* eslint-disable max-len */
import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import terms from 'common/terms'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { setFromGroix } from 'reducers/metrics'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import IconButton from '@mui/material/IconButton'
import history from 'customHistory'
import CardActions from '@mui/material/CardActions'
import yellowCardBg from 'assets/images/yellow-card-bg.png'
import blueCardBg from 'assets/images/blue-card-bg.png'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import PathNames from 'common/PathNames'

export default function GovCards(): ReactElement {
  const dispatch = useDispatch()
  const getDate = () => {
    dispatch(setFromGroix(false))
    history.push(PathNames.DateChoice)
  }
  const uploadGov = () => {
    dispatch(setFromGroix(true))
    history.push(PathNames.FileImport)
  }

  return (
    <Fade in timeout={500}>
      <Grid
        container
        spacing={5}
        justifyContent="center"
        alignItems="center"
        sx={{ height: '50vh', width: '100%' }}
        margin="auto"
      >
        <Grid container item xs={12} sm={6} sx={{ justifyContent: 'right', alignItems: 'flex-start' }}>
          <Card
            sx={{
              height: '630px', width: '480px', p: 2, boxShadow: 2,
            }}
            style={{
              backgroundImage: `url(${blueCardBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
              backgroundSize: 'contain',
              alignSelf: 'flex-end',
              position: 'relative',
            }}
          >
            <CardContent sx={{ p: '25' }}>
              <Typography
                sx={{
                  fontFamily: 'Avenir', fontWeight: '400', fontSize: '20px', color: '#61B8FA',
                }}
              >
                {terms.GovOptions.GovCards.chooseDays}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Avenir', fontWeight: '800', fontSize: '32px', color: '#61B8FA', mt: 2, mb: '40px',
                }}
              >
                {terms.GovOptions.GovCards.cardTitle1}
              </Typography>
              <Stepper
                orientation="vertical"
                sx={{
                  '& .MuiStepConnector-root .MuiStepConnector-line': {
                    minHeight: '16px',
                    borderColor: '#61B8FA',
                  },
                  '& .MuiStepContent-root': {
                    borderLeftColor: '#61B8FA',
                  },
                }}
              >
                <Step
                  sx={{
                    '& .MuiStepLabel-root .Mui-active': {
                      color: '#61B8FA',
                      fontSize: '10px',
                      marginLeft: '8px',
                    },
                  }}
                >
                  <StepLabel icon=" ">
                    <Typography
                      sx={{
                        fontFamily: 'Avenir', fontWeight: '900', fontSize: '20px', color: '#616161',
                      }}
                    >
                      {terms.GovOptions.GovCards.conceptionGov}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography sx={{
                      fontFamily: 'Avenir', fontWeight: '400', fontSize: '16px', color: '#656565BF', ml: '4px',
                    }}
                    >
                      {terms.GovOptions.GovCards.conceptionGovInfo}
                    </Typography>
                  </StepContent>
                </Step>
                <Step
                  active
                  sx={{
                    '& .MuiStepLabel-root .Mui-active': {
                      color: '#61B8FA',
                      fontSize: '10px',
                      marginLeft: '8px',
                    },
                  }}
                >
                  <StepLabel icon=" ">
                    <Typography
                      sx={{
                        fontFamily: 'Avenir', fontWeight: '900', fontSize: '20px', color: '#616161',
                      }}
                    >
                      {terms.GovOptions.GovCards.theoreticGov}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography sx={{
                      fontFamily: 'Avenir', fontWeight: '400', fontSize: '16px', color: '#656565BF', ml: '4px',
                    }}
                    >
                      {terms.GovOptions.GovCards.theoreticGovInfo}
                    </Typography>
                  </StepContent>
                </Step>
                <Step
                  active
                  sx={{
                    '& .MuiStepLabel-root .Mui-active': {
                      color: '#61B8FA',
                      fontSize: '10px',
                      marginLeft: '8px',
                    },
                  }}
                >
                  <StepLabel icon=" ">
                    <Typography
                      sx={{
                        fontFamily: 'Avenir', fontWeight: '900', fontSize: '20px', color: '#616161',
                      }}
                    >
                      {terms.GovOptions.GovCards.realGov}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography sx={{
                      fontFamily: 'Avenir', fontWeight: '400', fontSize: '16px', color: '#656565BF', ml: '4px',
                    }}
                    >
                      {terms.GovOptions.GovCards.realGovInfo}
                    </Typography>
                  </StepContent>
                </Step>
              </Stepper>
              <Typography
                sx={{
                  fontFamily: 'Avenir',
                  fontWeight: '400',
                  fontSize: '12px',
                  color: '#61B8FA',
                  paddingLeft: '36px',
                  paddingTop: '20px',
                  width: '250px',
                }}
              >
                {terms.GovOptions.GovCards.note}
              </Typography>
            </CardContent>
            <CardActions sx={{ position: 'absolute', right: 0, bottom: 0 }}>
              <IconButton
                sx={{
                  justifyContent: 'flex-end', alignSelf: 'flex-end', mt: '-32px',
                }}
                aria-label={terms.GovOptions.GovCards.chooseDays}
                onClick={() => getDate()}
              >
                <ArrowForwardRoundedIcon sx={{ color: 'white', fontSize: '70px' }} />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
        <Grid container item xs={12} sm={6} sx={{ justifyContent: 'left', alignItems: 'flex-start' }}>
          <Card
            sx={{
              height: '630px', width: '480px', p: 2, boxShadow: 2,
            }}
            style={{
              backgroundImage: `url(${yellowCardBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
              backgroundSize: 'contain',
              alignSelf: 'flex-end',
              position: 'relative',
            }}
          >
            <CardContent sx={{ p: '25' }}>
              <Typography
                sx={{
                  fontFamily: 'Avenir', fontWeight: '400', fontSize: '20px', color: '#F2CC55',
                }}
              >
                {terms.GovOptions.GovCards.manuallyImport}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Avenir', fontWeight: '800', fontSize: '32px', color: '#F2CC55', mt: 2, mb: '10px',
                }}
              >
                {terms.GovOptions.GovCards.cardTitle2}

              </Typography>

              <Stepper
                orientation="vertical"
                sx={{
                  '& .MuiStepConnector-root .MuiStepConnector-line': {
                    borderColor: 'white',
                    minHeight: '16px',
                  },
                  '& .MuiStepContent-root': {
                    borderLeft: 'white',
                  },
                }}
              >
                <Step
                  active
                  sx={{
                    '& .MuiStepLabel-root .Mui-active': {
                      color: '#F2CC55',
                      fontSize: '10px',
                      marginLeft: '8px',
                    },
                    '& .MuiStepConnector-line': {
                      color: '#white',
                      minHeight: '16px',
                    },
                  }}
                >
                  <StepLabel icon=" ">
                    <Typography
                      sx={{
                        fontFamily: 'Avenir', fontWeight: '900', fontSize: '20px', color: '#616161', marginTop: '30px',
                      }}
                    >
                      {terms.GovOptions.GovCards.excelFile}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography sx={{
                      fontFamily: 'Avenir', fontWeight: '400', fontSize: '16px', color: '#656565BF', ml: '4px',
                    }}
                    >
                      {terms.GovOptions.GovCards.groixExtract}
                    </Typography>
                  </StepContent>
                </Step>
                <Step
                  active
                  sx={{
                    '& .MuiStepLabel-root .Mui-active': {
                      color: '#F2CC55',
                      fontSize: '10px',
                      marginLeft: '8px',
                    },
                  }}
                >
                  <StepLabel icon=" ">
                    <Typography
                      sx={{
                        fontFamily: 'Avenir', fontWeight: '900', fontSize: '20px', color: '#616161',
                      }}
                    >
                      {terms.GovOptions.GovCards.fileImport}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography sx={{
                      fontFamily: 'Avenir', fontWeight: '400', fontSize: '16px', color: '#656565BF', ml: '4px',
                    }}
                    >
                      {terms.GovOptions.GovCards.forComparation}
                    </Typography>
                  </StepContent>
                </Step>
              </Stepper>
            </CardContent>
            <CardActions sx={{ position: 'absolute', right: 0, bottom: 0 }}>
              <IconButton
                sx={{
                  justifyContent: 'flex-end', alignSelf: 'flex-end', mt: '55px',
                }}
                aria-label={terms.GovOptions.GovCards.manuallyImport}
                onClick={() => uploadGov()}
              >
                <ArrowForwardRoundedIcon sx={{ color: 'white', fontSize: '70px' }} />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Fade>
  )
}
