/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import StationsService from 'reducers/services/stationsService'
import { ResponseError } from 'types'
import { Module, Station, StationsState } from './types'

const initialState: StationsState = {
  stationsList: [],
  activeStation: undefined,
  loading: false,
  error: undefined,
  saveStatus: false,
  requiredConfig: false,
  ordering: 'alphabetical',
  search: '',
  monochrome: false,
  mode: Module.GOV,
}

export const stationsSlice = createSlice({
  name: 'stations',
  initialState,

  reducers: {
    updateStationsList: (state, action: PayloadAction<Array<Station>>) => {
      state.stationsList = action.payload
    },
    updateActiveStation: (state, action: PayloadAction<Station>) => {
      state.activeStation = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setError: (state, action: PayloadAction<ResponseError>) => {
      state.error = action.payload
    },
    setSaveStatus: (state, action: PayloadAction<boolean>) => {
      state.saveStatus = action.payload
    },
    setRequiredConfig: (state, action: PayloadAction<boolean>) => {
      state.requiredConfig = action.payload
    },
    setStationOrdering: (state, action: PayloadAction<string>) => {
      state.ordering = action.payload
    },
    SetStationSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setColorScheme: (state, action: PayloadAction<boolean>) => {
      state.monochrome = action.payload
    },
    setModeModule: (state, action: PayloadAction<Module>) => {
      state.mode = action.payload
    },
  },

  extraReducers: builder => {
    builder.addCase(StationsService.getStationsList.pending, state => {
      state.loading = true
    })
    builder.addCase(StationsService.getStationsList.fulfilled, (state, action) => {
      state.stationsList = action.payload.results
      state.loading = false
    })
    builder.addCase(StationsService.getStationsList.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(StationsService.getStation.pending, state => {
      state.loading = true
    })
    builder.addCase(StationsService.getStation.fulfilled, (state, action) => {
      state.activeStation = action.payload
      state.loading = false
    })
    builder.addCase(StationsService.getStation.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(StationsService.patchStation.pending, state => {
      state.loading = true
    })
    builder.addCase(StationsService.patchStation.fulfilled, (state, action) => {
      state.stationsList = state.stationsList.map(station => (station.id === action.payload.id ? action.payload : station))
      state.loading = false
      state.saveStatus = true
      state.activeStation = action.payload
    })
    builder.addCase(StationsService.patchStation.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(StationsService.validateConfig.pending, state => {
      state.loading = true
    })
    builder.addCase(StationsService.validateConfig.fulfilled, (state, action) => {
      state.loading = false
      state.saveStatus = true
      state.activeStation = action.payload
    })
    builder.addCase(StationsService.validateConfig.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(StationsService.patchImage.pending, state => {
      state.loading = true
    })
    builder.addCase(StationsService.patchImage.fulfilled, state => { // demander le lien comme response ?
      state.loading = false
      state.saveStatus = true
    })
    builder.addCase(StationsService.patchImage.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {
  updateStationsList,
  updateActiveStation,
  setLoading,
  setError,
  setSaveStatus,
  setRequiredConfig,
  setStationOrdering,
  SetStationSearch,
  setColorScheme,
  setModeModule,
} = stationsSlice.actions

export default stationsSlice.reducer
