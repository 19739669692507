import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { ColorButton } from 'common/CustomTheme'
import terms from 'common/terms'
import { ReactElement } from 'react'

interface Props {
  handleClose: () => void;
  onDownload: () => void;
}

const ModalHeader = ({ handleClose, onDownload }: Props): ReactElement => (
  <div className="header">
    <button
      type="button"
      className="back-button"
      onClick={handleClose}
    >
      <ArrowBackIcon />
      <span>
        {terms.NormesRE.Reusability.Table.back}
      </span>
    </button>
    <div className="export">
      <ColorButton
        onClick={onDownload}
        startIcon={<FileDownloadOutlinedIcon />}
      >
        {terms.common.excelExport}
      </ColorButton>
    </div>
  </div>
)

export default ModalHeader
