import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { setError as setErrorStations } from 'reducers/stations'
import { setError as setErrorTracks } from 'reducers/tracks'
import { setError } from 'reducers/groix'
import { setError as setErrorEquipments } from 'reducers/equipments'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Slide from '@mui/material/Slide'

type Props = {
  message: string;
  error: boolean;
}

export default function Snackbars({ message, error }: Props): ReactElement {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setErrorStations(undefined))
    dispatch(setErrorTracks(undefined))
    dispatch(setErrorEquipments(undefined))
    dispatch(setError(undefined))
  }

  return (
    <Snackbar
      open={error}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={Slide}
    >
      <Alert variant="filled" severity="warning" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}
