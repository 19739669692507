/* eslint-disable @typescript-eslint/no-shadow */
import {
  ReactElement, useEffect, useState, createRef,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import { format } from 'date-fns'
import Box from '@mui/material/Box'
import metricsService from 'reducers/services/metricsService'
import Divider from '@mui/material/Divider'
import { useScreenshot, createFileName } from 'use-react-screenshot'
import { ColorButton } from 'common/CustomTheme'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import Loading from 'components/Common/Backdrop/Backdrop'
import MissingGovAlert from 'components/MissingGov/MissingGovAlert'
import PonctualityPieChart from './PonctualityPieChart'
import PonctualityLineChart from './PonctualityLineChart'
import MissingData from '../MissingData'
import '../station.scss'

interface TabPanelProps {
  index: number;
  value: number;
}

export default function Ponctuality(props: TabPanelProps): ReactElement {
  const dispatch = useDispatch()
  const { value, index, ...other } = props
  const {
    metricsList, departPonctuality, arrivalPonctuality, ponctualityLineChart, loadingDepartPonctuality,
    loadingArrivalPonctuality, loadingPonctualityLineChart, errorDepartPonctuality, errorArrivalPonctuality,
    errorPonctualityLineChart, errorDepartPonctualityCode, errorArrivalPonctualityCode, errorPonctualityLineChartCode,
    fromGroix, filters,
  } = useSelector((state: RootState) => state.metrics)
  const { confirmedDates } = useSelector((state: RootState) => state.annualServices)
  const { activeStation } = useSelector((state: RootState) => state.stations)

  const [comparaisonType, setComparaisonType] = useState('Théorique (J-1) / Réel (J)')
  const [results, setResults] = useState(undefined)
  const ref = createRef()
  const [image, takeScreenshot] = useScreenshot()

  const handleChangeComparaisonType = event => {
    setComparaisonType(event.target.value)
  }

  const download = (image, {
    name = `${activeStation.abv_traction} -
  ${comparaisonType}${fromGroix ? '' : ` - ${format(new Date(Date.now()), 'dd-MM-yyyy')}`}`, extension = 'jpeg',
  } = {}) => {
    const a = document.createElement('a')
    a.href = image
    a.download = createFileName(extension, name)
    a.click()
  }

  function getImage() {
    takeScreenshot(ref.current).then(download)
  }

  useEffect(() => {
    const result = metricsList?.metrics?.filter(obj => obj?.board === 'Ponctualité')
    if (result && fromGroix) {
      setComparaisonType(Object.keys(result[0].payload['compared GOV'][0])[0])
    }
  }, [metricsList])

  useEffect(() => {
    const result = metricsList?.metrics?.filter(obj => obj?.board === 'Ponctualité')
    setResults(result)
    if (value === index) {
      if (result) {
        const array1 = result[0].payload['compared GOV']
        const array2 = result[1].payload['compared GOV']
        const array3 = result[2].payload

        const object1 = array1.find(x => x[comparaisonType])
        const object2 = array2.find(x => x[comparaisonType])
        const object3 = array3.find(x => x[comparaisonType])

        if (object1 && object2 && object3) {
          const govA1 = object1[comparaisonType]?.gov_a.length !== 0 ? object1[comparaisonType].gov_a.toString() : null
          const govB1 = object1[comparaisonType]?.gov_b.length !== 0 ? object1[comparaisonType].gov_b.toString() : null
          const govA2 = object2[comparaisonType]?.gov_a.length !== 0 ? object2[comparaisonType].gov_a.toString() : null
          const govB2 = object2[comparaisonType]?.gov_b.length !== 0 ? object2[comparaisonType].gov_b.toString() : null
          const govA3 = object3[comparaisonType]?.gov_a.length !== 0 ? object3[comparaisonType].gov_a.toString() : null
          const govB3 = object3[comparaisonType]?.gov_b.length !== 0 ? object3[comparaisonType].gov_b.toString() : null

          dispatch(metricsService.getDepartPonctuality({
            uri: result[0].uri.substring(result[0].uri.indexOf('/usage_reseau')),
            gov_a: govA1,
            gov_b: govB1,
            type_gov_a: object1[comparaisonType].type_gov_a,
            type_gov_b: object1[comparaisonType].type_gov_b,
            circulation_status: result[0].payload.circulation_status,
            filters,
          }))

          dispatch(metricsService.getArrivalPonctuality({
            uri: result[1].uri.substring(result[1].uri.indexOf('/usage_reseau')),
            gov_a: govA2,
            gov_b: govB2,
            type_gov_a: object2[comparaisonType].type_gov_a,
            type_gov_b: object2[comparaisonType].type_gov_b,
            circulation_status: result[1].payload.circulation_status,
            filters,
          }))

          dispatch(metricsService.getPonctualityLineChart({
            uri: result[2].uri.substring(result[2].uri.indexOf('/usage_reseau')),
            gov_a: govA3,
            gov_b: govB3,
            type_gov_a: object3[comparaisonType].type_gov_a,
            type_gov_b: object3[comparaisonType].type_gov_b,
            filters,
          }))
        }
      }
    }
  }, [metricsList, comparaisonType, filters, value])

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-hv-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {(value === index) && (
        <Paper sx={{
          width: '100%', boxShadow: 'none', paddingLeft: '3rem', paddingRight: '3rem',
        }}
        >
          <Loading open={loadingDepartPonctuality || loadingArrivalPonctuality || loadingPonctualityLineChart} />
          <div className="d-flex w-100 justify-content-between my-3 align-items-center">
            <FormControl sx={{ m: 1, minWidth: 260 }} className="ml-auto">
              <Select
                value={comparaisonType}
                label="Détail variation"
                onChange={handleChangeComparaisonType}
                className="detail-variation-select"
              >
                {results ? (results[0].payload['compared GOV'].map((a, i:number) => (
                  <MenuItem
                    key={Object.keys(results[0].payload['compared GOV'][i])[0]}
                    value={Object.keys(results[0].payload['compared GOV'][i])[0]}
                  >
                    {Object.keys(results[0].payload['compared GOV'][i])[0]}
                  </MenuItem>
                ))) : <></>}
              </Select>
            </FormControl>
            <ColorButton
              disabled={(errorDepartPonctualityCode?.status === 400 || errorArrivalPonctualityCode?.status === 400
                || errorPonctualityLineChartCode?.status === 400)}
              onClick={() => getImage()}
              startIcon={<FileDownloadOutlinedIcon />}
            >
              {terms.common.jpegExport}
            </ColorButton>
          </div>
          <Box ref={ref}>
            <Grid container>
              <Grid item xs={12}>
                <Divider orientation="horizontal" flexItem />
                <Stack direction="row">
                  {!errorDepartPonctuality && departPonctuality['pie-chart']
                   && departPonctuality['pie-chart'].length !== 0 && (
                   <PonctualityPieChart
                     data={departPonctuality}
                     title={terms.Ponctuality.departPieChartTitle}
                     infoTitle={terms.Ponctuality.departPieChartInfoTitle}
                     infoTooltip={terms.Ponctuality.pieChartInfoTooltipDepart}
                     filterHOO
                   />
                  )}
                  {(errorDepartPonctualityCode?.status === 400) && (
                  <Grid xs={6} className="my-5">
                    <MissingData
                      message={errorDepartPonctualityCode?.message.detail}
                      title={terms.Ponctuality.departPieChartTitle}
                    />
                  </Grid>
                  )}
                  {filters && ((Object.keys(departPonctuality).length !== 0
                   && departPonctuality['pie-chart'].length === 0)) && !errorDepartPonctuality && (
                   <Grid xs={6} className="my-5">
                     <MissingData
                       message={terms.Filters.noResultNotice}
                       title={terms.Ponctuality.departPieChartTitle}
                     />
                   </Grid>
                  )}
                  <Divider orientation="vertical" flexItem />
                  {!errorArrivalPonctuality && arrivalPonctuality['pie-chart']
                  && arrivalPonctuality['pie-chart'].length !== 0 && (
                  <PonctualityPieChart
                    data={arrivalPonctuality}
                    title={terms.Ponctuality.arrivalPieChartTitle}
                    infoTitle={terms.Ponctuality.arrivalPieChartInfoTitle}
                    infoTooltip={terms.Ponctuality.pieChartInfoTooltipArrival}
                  />
                  )}
                  {(errorArrivalPonctualityCode?.status === 400) && (
                    <Grid xs={6} className="my-5">
                      <MissingData
                        message={errorArrivalPonctualityCode?.message.detail}
                        title={terms.Ponctuality.arrivalPieChartTitle}
                      />
                    </Grid>
                  )}
                  {filters && ((Object.keys(arrivalPonctuality).length !== 0
                   && arrivalPonctuality['pie-chart'].length === 0)) && !errorArrivalPonctuality
                    && (
                      <Grid xs={6} className="my-5">
                        <MissingData
                          message={terms.Filters.noResultNotice}
                          title={terms.Ponctuality.arrivalPieChartTitle}
                        />
                      </Grid>
                    )}
                </Stack>
                <Divider orientation="horizontal" flexItem />
              </Grid>
              <Grid item xs={12}>
                <Divider orientation="vertical" flexItem />
                {!errorPonctualityLineChart && ponctualityLineChart[0] && (
                <PonctualityLineChart
                  data={ponctualityLineChart}
                  title={terms.Ponctuality.lineChartTitle}
                />
                )}
                {(errorPonctualityLineChartCode?.status === 400) && (
                  <Grid xs={12} className="my-5">
                    <MissingData
                      message={errorPonctualityLineChartCode?.message.detail}
                      title={terms.Ponctuality.lineChartTitle}
                    />
                  </Grid>
                )}
                {filters && ponctualityLineChart.length === 0 && !errorPonctualityLineChart
                    && (
                      <Grid xs={12} className="my-5">
                        <MissingData
                          message={terms.Filters.noResultNotice}
                          title={terms.Ponctuality.lineChartTitle}
                        />
                      </Grid>
                    )}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
      {metricsList.length !== 0 && metricsList?.missing_days?.length !== 0 && !fromGroix && <MissingGovAlert />}
    </div>
  )
}
