/* eslint-disable max-len */
/* eslint-disable camelcase */
import { get } from '@osrdata/app_core/dist/requests'
import { ThunkApi } from 'types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AnnualServices } from 'reducers/types'

const getYearsList = createAsyncThunk<Array<AnnualServices>, string, ThunkApi>(
  'annualservice/getYearsList',
  async (stationId, thunkApi) => {
    try {
      const yearsList = await get(`/gov/gares/${stationId}/services_annuels`)
      return yearsList
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getDatesList = createAsyncThunk<Array<AnnualServices>, any, ThunkApi>(
  'annualservice/getDatesList',
  async (ids, thunkApi) => {
    try {
      const datesList = await get(`/gov/gares/${ids.stationId}/services_annuels/${ids.yearId}/journees_service_annuel`)
      return datesList
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getDatesList2 = createAsyncThunk<Array<AnnualServices>, any, ThunkApi>(
  'annualservice/getDatesList2',
  async (ids, thunkApi) => {
    try {
      const datesList2 = await get(`/gov/gares/${ids.stationId}/services_annuels/${ids.yearId}/journees_service_annuel`)
      return datesList2
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getDatesList3 = createAsyncThunk<Array<AnnualServices>, any, ThunkApi>(
  'annualservice/getDatesList3',
  async (ids, thunkApi) => {
    try {
      const datesList3 = await get(`/gov/gares/${ids.stationId}/services_annuels/${ids.yearId}/journees_service_annuel`)
      return datesList3
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const AnnualServicesService = {
  getYearsList,
  getDatesList,
  getDatesList2,
  getDatesList3,
}

export default AnnualServicesService
