import {
  color10a,
  color10b,
  color1a,
  color1b,
  color2a,
  color2b,
  color3a,
  color3b,
  color4a,
  color4b,
  color5a,
  color5b,
  color6a,
  color6b,
  color7a,
  color7b,
  color8a,
  color8b,
  color9a,
  color9b,
} from 'components/Station/colorSchemes'

const TYPE = [
  'En cours',
  'Faux conflit',
  'Maintenu',
  'Non traité',
]

const COLORS = [
  color9b,
  color8b,
  color1b,
  color2b,
]

const MONOCOLORS = [
  color5a,
  color1a,
  color8a,
  color3a,
]

const COLORS_EVOLUTION = [
  color1b,
  color2b,
  color3b,
  color4b,
  color5b,
  color6b,
  color7b,
  color8b,
  color9b,
  color10b,
]

const COLORS_EVOLUTION_MONO = [
  color1a,
  color2a,
  color3a,
  color4a,
  color5a,
  color6a,
  color7a,
  color8a,
  color9a,
  color10a,
]

export {
  TYPE, COLORS, MONOCOLORS, COLORS_EVOLUTION, COLORS_EVOLUTION_MONO,
}
