/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { SetStationSearch } from 'reducers/stations'

export default function StationsSearchBar(): ReactElement {
  const dispatch = useDispatch()
  const { search } = useSelector((state: RootState) => state.stations)

  const handleFilter = (event: any) => {
    event.preventDefault()
    dispatch(SetStationSearch(event.target.value))
  }

  return (
    <Paper
      elevation={0}
      component="form"
      sx={{
        p: '2px 4px', display: 'flex', alignItems: 'center',
      }}
    >
      <IconButton type="submit" sx={{ p: '10px', color: 'black' }} aria-label="search" disabled>
        <SearchIcon sx={{ color: 'black' }} />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={terms.StationsDashboard.StationsHome.searchPlaceholder}
        inputProps={{ 'aria-label': `${terms.StationsDashboard.StationsHome.searchPlaceholder}` }}
        value={search}
        onChange={handleFilter}
        onKeyPress={event => {
          if (event.key === 'Enter') { event.preventDefault() }
        }}
      />
    </Paper>
  )
}
