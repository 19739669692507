import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import PagineService from './services/pagineService'
import { Pagine } from './types'

interface MissingPagineState {
    missingPagines?: any;
    paginesList: Array<Pagine>;
    hasNoPagines: boolean;
    loadingExport: boolean;
  }

const initialState: MissingPagineState = {
  paginesList: [],
  hasNoPagines: false,
  loadingExport: false,
}

export const missingPagineSlice = createSlice({
  name: 'missingPagine',
  initialState,

  reducers: {
    updateMissingPagines: (state, action: PayloadAction<any>) => {
      state.missingPagines = action.payload
    },
    updateHasNoPagines: (state, action: PayloadAction<boolean>) => {
      state.hasNoPagines = action.payload
    },
    updateLoadingExport: (state, action: PayloadAction<boolean>) => {
      state.loadingExport = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(PagineService.getPagines.fulfilled, (state, action) => {
      state.paginesList = action.payload
    })
    builder.addCase(PagineService.updatePagines.fulfilled, (state, action) => {
      state.paginesList = action.payload
    })
  },
})

export const {
  updateMissingPagines, updateHasNoPagines, updateLoadingExport,
} = missingPagineSlice.actions

export default missingPagineSlice.reducer
