import { ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  updateYearsList, updateDatesList, updateAvailableDates, updateAvailableDates2,
  updateExcludedDates, updateSelectedDates, updateConfirmedDates,
} from 'reducers/annualServices'
import { updateActiveStation } from 'reducers/stations'
import { updateMissingGovs } from 'reducers/missingGov'
import { updateMissingPagines } from 'reducers/missingPagine'
import terms from 'common/terms'
import Container from '@mui/material/Container'
import TopBar from 'components/Common/TopBar/TopBar'
import StationsItems from './Stations'
import StationsBanner from './Banner'

import './style.scss'

export default function Home(): ReactElement {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateYearsList([]))
    dispatch(updateDatesList([]))
    dispatch(updateAvailableDates([]))
    dispatch(updateAvailableDates2([]))
    dispatch(updateExcludedDates([]))
    dispatch(updateSelectedDates([]))
    dispatch(updateConfirmedDates([]))
  }, [])

  useEffect(() => {
    dispatch(updateActiveStation(undefined))
    dispatch(updateMissingGovs([]))
    dispatch(updateMissingPagines([]))
  }, [])

  return (
    <main className="mastcontainer pl-0">
      <TopBar appName={terms.common.appName} goHomeIcon={false} />
      <Container maxWidth={false} disableGutters sx={{ height: '100%' }}>
        <StationsBanner />
        <StationsItems />
      </Container>
    </main>
  )
}
