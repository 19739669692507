import { ReactElement } from 'react'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { ColorButton } from 'common/CustomTheme'
import { v4 as uuidV4 } from 'uuid'
import terms from 'common/terms'

interface Props {
  onDownload: () => void;
  handleClose: () => void;
  trainNumber: string;
  info: {label: string; value: string}[];
}

const HistoryHeader = ({
  onDownload, handleClose, trainNumber, info,
}: Props):ReactElement => (
  <div className="history-header">
    <div className="title">
      <span>
        {terms.NormesRE.Reusability.Table.balanceHistory}
      </span>
      <span className="train-number">
        {trainNumber}
      </span>
    </div>
    <div className="infos">
      {
        info.map(item => (
          <div className="info" key={uuidV4()}>
            <span className="label">
              {item.label}
            </span>
            <span>
              {item.value}
            </span>
          </div>
        ))
      }
    </div>
    <div className="buttons">
      <div className="export">
        <ColorButton
          onClick={onDownload}
          startIcon={<FileDownloadOutlinedIcon />}
        >
          {terms.common.excelExport}
        </ColorButton>
      </div>
      <button
        type="button"
        onClick={handleClose}
        className="close-button"
        aria-label="close-button"
      >
        <CloseIcon />
      </button>
    </div>
  </div>
)

export default HistoryHeader
