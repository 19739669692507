/* eslint-disable react/no-array-index-key */
import { ReactElement, useEffect } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { TrainNumberRange } from 'reducers/types'
import SimpleInput from 'components/Common/Input/SimpleInput'
import { Grid, IconButton } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import { updateTrainNumberRange, updateTrainsRange } from 'reducers/filters'
import InputFilterTitle from './InputFilterTitle'
import { getNumberRanges } from './utils'

export default function TrainNumbersFilter(): ReactElement {
  const dispatch = useDispatch()
  const { trainNumberRange } = useSelector((state: RootState) => state.filters)
  const { filters } = useSelector((state: RootState) => state.metrics)

  useEffect(() => {
    if (filters && trainNumberRange.length === 0) {
      dispatch(updateTrainNumberRange(getNumberRanges(filters.train_number_range)))
    }
  }, [filters, trainNumberRange])

  useEffect(() => {
    if (trainNumberRange.length !== 0) {
      const ranges: string[] = []
      trainNumberRange.forEach(range => {
        if (range.start !== '' || range.end !== '') {
          ranges.push(range.start.concat('-').concat(range.end))
        }
      })
      dispatch(updateTrainsRange(ranges))
    }
  }, [trainNumberRange])

  const addTrainNumberRange = () => {
    dispatch(updateTrainNumberRange([...trainNumberRange, { index: trainNumberRange.length, start: '', end: '' }]))
  }

  const handleStartRange = (value: string, index: number) => {
    const newTrainsRange = trainNumberRange.map((range: TrainNumberRange) => (range.index !== index
      ? range : { index, start: value, end: range.end }))
    dispatch(updateTrainNumberRange(newTrainsRange))
  }

  const handleEndRange = (value: string, index: number) => {
    const newTrainsRange = trainNumberRange.map((range: TrainNumberRange) => (range.index !== index
      ? range : { index, start: range.start, end: value }))
    dispatch(updateTrainNumberRange(newTrainsRange))
  }

  const deleteTrainNumberRange = (index: number) => {
    dispatch(updateTrainNumberRange(trainNumberRange.filter(range => range.index !== index).map(range => (range.index
       < index ? range : { index: range.index - 1, start: range.start, end: range.end }))))
  }

  return (
    <>
      <InputFilterTitle title={terms.Filters.trainNumberRange} onClick={addTrainNumberRange} />
      {trainNumberRange.map((range: TrainNumberRange, index: number) => (
        <Grid container key={index} className="mb-3">
          <Grid item xs={5.5}>
            <SimpleInput
              label={terms.Filters.trainNumberStart}
              value={range.start}
              index={index}
              onChange={e => handleStartRange(e.target.value, index)}
              inputStyle="filters-input"
            />
          </Grid>
          <Grid item xs={5.5}>
            <SimpleInput
              label={terms.Filters.trainNumberEnd}
              value={range.end}
              index={index}
              onChange={e => handleEndRange(e.target.value, index)}
              inputStyle="filters-input"
            />
          </Grid>
          {trainNumberRange.length > 1 && (
          <Grid item xs={1} className="delete-field-btn">
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => deleteTrainNumberRange(index)}
              aria-label="delete"
              sx={{ marginRight: 0 }}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
          )}
        </Grid>
      ))}
    </>
  )
}
