/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { Box } from '@mui/material'
import { ResponsiveBar } from '@nivo/bar'
import { AxisTickProps } from '@nivo/axes'
import { RootState } from 'Store'
import terms from 'common/terms'
import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import MissingData from 'components/Station/MissingData'
import normesService from 'reducers/services/normesService'
import { COLORS, MONOCOLORS, TYPE } from '../../Const'
import Tooltip from '../../Common/Tooltip'
import { BarTotalsLayer, separateDataType } from '../../function'

import './style.scss'

interface Props {
  normesTypeSelected: string[];
}

export default function BarChart({ normesTypeSelected }: Props): ReactElement {
  const {
    conflictBar, conflictsType, isLoading, errorConflictBar, isLoadingConflictBar,
  } = useSelector((state: RootState) => state.normes)
  const { monochrome } = useSelector((state: RootState) => state.stations)
  const [data, setData] = useState(separateDataType(conflictsType, conflictBar.data))

  useEffect(() => {
    setData(separateDataType(conflictsType, conflictBar.data).filter(item => normesTypeSelected.includes(item.type)))
  }, [normesTypeSelected, conflictBar.data])

  const findMaxSumValue = () => {
    let maxSum = -Infinity
    data.forEach(item => {
      const sum = item['Non traité'] + item.Maintenu + item['Faux conflit'] + item['En cours']
      if (sum > maxSum) {
        maxSum = sum
      }
    })
    return maxSum
  }

  const renderCustomTick = (tick: AxisTickProps<string>) => (
    <g transform={`translate(${tick.x},${tick.y})`}>
      {
          !conflictsType.find(item => item.key === tick.value) ? (
            <line
              x1="0"
              x2="0"
              y1="0"
              y2="5"
              style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1, marginBottom: 10 }}
            />
          ) : (
            <line
              x1="0"
              x2="0"
              y1="-220"
              y2="0"
              style={{
                stroke: 'rgb(220, 220, 220)', strokeWidth: 1, marginBottom: 10, strokeDasharray: '2,2',
              }}
            />
          )
        }

      <text
        x={7}
        y={9}
        dy={9}
        textAnchor="start"
        transform="rotate(40)"
        fontSize="14"
        fill="#000"
        style={{ padding: 10 }}
      >
        {conflictsType.find(item => item.key === tick.value) ? '' : tick.value}
      </text>
    </g>
  )

  return (
    <Box sx={{
      height: '58vh',
      width: '80vw',
      margin: 'auto',
    }}
    >
      <ResponsiveBar
        data={data.slice(1)}
        keys={TYPE}
        indexBy="x"
        margin={{
          top: 40, right: 200, bottom: 150, left: 60,
        }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={monochrome ? MONOCOLORS : COLORS}
        theme={{
          grid: {
            line: {
              strokeWidth: 1,
              stroke: '#EDEDED',
              opacity: 0.5,
            },
          },
          legends: {
            text: {
              fontSize: 14,
              fontWeight: 900,
            },
          },
          axis: {
            legend: { text: { fontWeight: 900, fill: '#000', fontSize: '14px' } },
          },
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          renderTick: renderCustomTick,
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 20,
          legend: terms.NormesRE.ConflictsGov.ConflictsMetrics.legends.abscisse,
          legendPosition: 'middle',
          legendOffset: 130,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: terms.NormesRE.ConflictsGov.ConflictsMetrics.legends.ordonnee,
          legendPosition: 'middle',
          legendOffset: -50,
          tickValues: normesService.generateTickBarValues(findMaxSumValue()),
        }}
        isInteractive
        enableLabel={false}
        tooltip={({ id, value }) => (
          <Tooltip label={id as string} value={value} />
        )}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
          },
        ]}
        onMouseEnter={(_data, event) => {
          const target = event.target as HTMLElement
          target.style.animation = 'barstrokeout 300ms forwards'
        }}
        onMouseLeave={(_data, event) => {
          const target = event.target as HTMLElement
          target.style.animation = 'barstrokein 300ms forwards'
        }}
        layers={[
          'grid',
          'axes',
          'markers',
          'bars',
          'legends',
          BarTotalsLayer,
        ]}
      />
      {
        (data.length === 0) && !isLoading && !isLoadingConflictBar && (
          <div
            className="missing-data"
          >
            <MissingData
              message={errorConflictBar ? errorConflictBar.message.detail : terms.NormesRE.noData}
            />
          </div>
        )
      }
    </Box>
  )
}
