import {
  Box, Modal, Table, TableContainer,
} from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import Loading from 'components/Common/Backdrop/Backdrop'
import MissingData from 'components/Station/MissingData'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import TableHeader from '../Table/TableHeader'

import './modalHistoryTable.scss'
import HistoryHeader from './HistoryHeader'
import HistoryBody from './HistoryBody'

interface Props {
  open: boolean;
  handleClose: () => void;
  onDownload: () => void;
  sortHistory: string;
  setSortHistory: (value: string) => void;
  arrivalSelected: string;
  departureSelected: string;
}

const ModalHistoryTable = ({
  open,
  handleClose,
  onDownload,
  sortHistory,
  setSortHistory,
  arrivalSelected,
  departureSelected,
}: Props): ReactElement => {
  const { historyTable, isLoading } = useSelector((state: RootState) => state.normes)
  const { data } = historyTable
  const columns = () => {
    if (data.length === 0) return []
    const result = data[0].value.map(item => item.label)
    return [...result, '']
  }

  return (
    <Modal open={open} onClose={handleClose} className="modal-wrapper-history">
      <Box className="modal-history">
        <Loading open={isLoading} />
        <HistoryHeader
          handleClose={handleClose}
          onDownload={onDownload}
          trainNumber={`${arrivalSelected} - ${departureSelected}`}
          info={historyTable.meta.info}
        />
        <div className="content">
          {
            ((data.length === 0) && !isLoading) ? (
              <div>
                <MissingData
                  message={terms.common.noData}
                />
              </div>
            ) : (
              <TableContainer component="div">
                <Table sx={{ minWidth: 450 }} aria-label="simple table">
                  <TableHeader
                    data={columns()}
                    sortTable={sortHistory}
                    setSortTable={setSortHistory}
                  />
                  <HistoryBody data={historyTable.data} />
                </Table>
              </TableContainer>
            )
          }
        </div>
      </Box>
    </Modal>
  )
}

export default ModalHistoryTable
