/* eslint-disable max-len */
import { ReactElement, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { OutlineButton } from 'common/CustomTheme'
import { updateFilterDeparture } from 'reducers/metrics'
import { FiltersDelay } from 'reducers/types'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Fade from '@mui/material/Fade'
import terms from 'common/terms'
import Button from '@mui/material/Button'
import Badge from '@mui/material/Badge'

const badgeStyle = {
  '& .MuiBadge-badge': {
    color: 'white',
    backgroundColor: '#FC5130',
    right: '15px',
    top: '8px',
  },
}

export default function DelayDepartureFilter(): ReactElement {
  const dispatch = useDispatch()
  const {
    filterDeparture, errorDetailVariationCode,
  } = useSelector((state: RootState) => state.metrics)
  const [anchorElDeparture, setAnchorElDeparture] = useState<null | HTMLElement>(null)
  const [localFilterDeparture, setLocalFilterDeparture] = useState('all1')
  const [localFilterDepartureTime, setLocalFilterDepartureTime] = useState('')
  const [localFilterDepartureTimeV2, setLocalFilterDepartureTimeV2] = useState('')
  const openFilterDeparture = Boolean(anchorElDeparture)

  useEffect(() => {
    if (filterDeparture.operation === 'all') {
      setAnchorElDeparture(null)
      setLocalFilterDeparture('all1')
      setLocalFilterDepartureTime('')
      setLocalFilterDepartureTimeV2('')
    } else {
      setAnchorElDeparture(null)
      setLocalFilterDeparture(filterDeparture.operation)
      setLocalFilterDepartureTime(filterDeparture.value1)
      setLocalFilterDepartureTimeV2(filterDeparture.value2)
    }
  }, [])

  const handleClickDeparture = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDeparture(event.currentTarget)
  }
  const handleCloseDeparture = () => {
    if ((filterDeparture.operation !== localFilterDeparture) && filterDeparture.operation !== 'all') {
      setAnchorElDeparture(null)
      setLocalFilterDeparture(filterDeparture.operation)
      setLocalFilterDepartureTime(filterDeparture.value1)
      setLocalFilterDepartureTimeV2(filterDeparture.value2)
    } else if (localFilterDeparture === 'all') {
      setAnchorElDeparture(null)
      setLocalFilterDeparture('all1')
      setLocalFilterDepartureTime('')
      setLocalFilterDepartureTimeV2('')
    } else {
      setAnchorElDeparture(null)
      setLocalFilterDeparture('all1')
      setLocalFilterDepartureTime('')
      setLocalFilterDepartureTimeV2('')
      const filter = {
        operation: 'all',
        value1: '',
        value2: '',
      }
      dispatch(updateFilterDeparture(filter))
    }
  }

  const handleCloseModal = () => {
    if (filterDeparture.operation === 'all') {
      setAnchorElDeparture(null)
      setLocalFilterDeparture('all1')
      setLocalFilterDepartureTime('')
      setLocalFilterDepartureTimeV2('')
    } else {
      setAnchorElDeparture(null)
      setLocalFilterDeparture(filterDeparture.operation)
      setLocalFilterDepartureTime(filterDeparture.value1)
      setLocalFilterDepartureTimeV2(filterDeparture.value2)
    }
  }

  const handleChangeFilterDeparture = event => {
    if (event.target.value !== filterDeparture.operation) {
      setLocalFilterDeparture(event.target.value)
      setLocalFilterDepartureTime('')
      setLocalFilterDepartureTimeV2('')
    } else {
      setLocalFilterDeparture(event.target.value)
      setLocalFilterDepartureTime(filterDeparture.value1)
      setLocalFilterDepartureTimeV2(filterDeparture.value2)
    }
  }

  const applyFilterDeparture = () => {
    setAnchorElDeparture(null)
    const filter = {
      operation: localFilterDeparture,
      value1: localFilterDepartureTime,
      value2: localFilterDepartureTimeV2,
    }
    dispatch(updateFilterDeparture(filter))
  }

  const onChangeDeparturetime = e => {
    const regex = /^[0-9\b]+$/
    if (e.target.value === '' || regex.test(e.target.value)) {
      setLocalFilterDepartureTime(e.target.value)
    }
  }

  const onChangeDeparturetimeV2 = e => {
    const regex = /^[0-9\b]+$/
    if (e.target.value === '' || regex.test(e.target.value)) {
      setLocalFilterDepartureTimeV2(e.target.value)
    }
  }

  const departureBadge = (e: FiltersDelay) => {
    switch (e.operation) {
      case 'departure_delay_gte':
        return `≥ ${e.value1}`
        break
      case 'departure_delay_lte':
        return `≤ ${e.value1}`
        break
      case 'departure_delay_equal':
        return `= ${e.value1}`
        break
      case 'between_delay':
        return `[${e.value1} , ${e.value2}]`
        break
      default:
        return ''
    }
  }

  return (
    <>
      <Badge badgeContent={departureBadge(filterDeparture)} color="primary" sx={badgeStyle} invisible={filterDeparture.operation === 'all'}>
        <OutlineButton disabled={errorDetailVariationCode?.status === 400} onClick={handleClickDeparture}>{terms.VariationDetail.lateOnDepartureFilterBtn}</OutlineButton>
      </Badge>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorElDeparture}
        open={openFilterDeparture}
        onClose={handleCloseModal}
        TransitionComponent={Fade}
      >
        <MenuItem
          disableRipple
          focusVisibleClassName="focus-visible"
          sx={{
            '&:hover': {
              backgroundColor: '#FFF !important',
            },
          }}
        >
          <Select
            value={localFilterDeparture}
            label={terms.VariationDetail.menuItemGovToCompare}
            onChange={handleChangeFilterDeparture}
            className="detail-variation-select w-100"
          >
            <MenuItem disabled value="all1"><em>{terms.VariationDetail.delayFilters.operationType}</em></MenuItem>
            <MenuItem value="departure_delay_gte">{terms.VariationDetail.delayFilters.greaterThan}</MenuItem>
            <MenuItem value="departure_delay_lte">{terms.VariationDetail.delayFilters.lowerThan}</MenuItem>
            <MenuItem value="departure_delay_equal">{terms.VariationDetail.delayFilters.equalTo}</MenuItem>
            <MenuItem value="between_delay">{terms.VariationDetail.delayFilters.between}</MenuItem>
          </Select>
        </MenuItem>
        <MenuItem
          disableRipple
          focusVisibleClassName="focus-visible"
          sx={{
            '&:hover': {
              backgroundColor: '#FFF !important',
            },
          }}
        >
          <Paper
            variant="outlined"
            sx={{
              width: '100%',
              height: '56px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <InputBase
              sx={{ ml: 1 }}
              value={localFilterDeparture !== 'all' ? localFilterDepartureTime : ''}
              inputProps={{ 'aria-label': terms.VariationDetail.delayFilters.placeHolder }}
              placeholder={terms.VariationDetail.delayFilters.placeHolder}
              onChange={onChangeDeparturetime}
              disabled={localFilterDeparture === 'all'}
            />
          </Paper>
        </MenuItem>
        {localFilterDeparture === 'between_delay' && (
        <>
          <MenuItem
            disableRipple
            className="d-flex justify-content-between"
            sx={{
              '&:hover': {
                backgroundColor: '#FFF !important',
              },
            }}
          >
            <span className="font-weight-bold">et</span>
          </MenuItem>
          <MenuItem
            disableRipple
            focusVisibleClassName="focus-visible"
            sx={{
              '&:hover': {
                backgroundColor: '#FFF !important',
              },
            }}
          >
            <Paper
              variant="outlined"
              sx={{
                width: '100%',
                height: '56px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <InputBase
                sx={{ ml: 1 }}
                value={localFilterDeparture === 'between_delay' ? localFilterDepartureTimeV2 : ''}
                inputProps={{ 'aria-label': terms.VariationDetail.delayFilters.placeHolder }}
                placeholder={terms.VariationDetail.delayFilters.placeHolder}
                onChange={onChangeDeparturetimeV2}
              />
            </Paper>
          </MenuItem>
        </>
        )}
        <MenuItem
          disableRipple
          className="d-flex justify-content-between"
          sx={{
            '&:hover': {
              backgroundColor: '#FFF !important',
            },
          }}
        >
          <Button onClick={handleCloseDeparture} variant="outlined" color={filterDeparture.operation === localFilterDeparture ? 'error' : 'primary'}>{filterDeparture.operation === localFilterDeparture ? terms.common.delete : terms.common.cancel}</Button>
          <Button disableElevation onClick={applyFilterDeparture} disabled={localFilterDepartureTime === '' && localFilterDeparture !== 'all'} variant="contained">{terms.common.ok}</Button>
        </MenuItem>
      </Menu>
    </>
  )
}
