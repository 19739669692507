/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable dot-notation */
/* eslint-disable max-len */
import {
  ReactElement, useEffect, useState, useRef,
} from 'react'
import { useScreenshot, createFileName } from 'use-react-screenshot'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { format } from 'date-fns'
import { ColorButton } from 'common/CustomTheme'
import metricsService from 'reducers/services/metricsService'
import { ResponsiveLine } from '@nivo/line'
import { linearGradientDef } from '@nivo/core'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import './station.scss'
import WarningCard from 'components/Common/WarningCard/WarningCard'
import Loading from 'components/Common/Backdrop/Backdrop'
import MissingGovAlert from 'components/MissingGov/MissingGovAlert'
import terms from 'common/terms'
import InfoHover from './Common/InfoHover'
import { color6b, color3b, color5b } from './colorSchemes'

interface TabPanelProps {
  index: number;
  value: number;
}

export default function HourlyVariation(props: TabPanelProps): ReactElement {
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [image, takeScreenshot] = useScreenshot()
  const { value, index, ...other } = props
  const {
    metricsList, hourlyVariation, loadingHourlyVariation, errorHourlyVariation, errorHourlyVariationCode, fromGroix,
    filters, categoriesVariabiliteHourly,
  } = useSelector((state: RootState) => state.metrics)
  const { activeStation, monochrome } = useSelector((state: RootState) => state.stations)

  const [comparaisonType, setComparaisonType] = useState('Théorique (J-1) / Réel (J)')
  const [results, setResults] = useState(undefined)
  const [variabilityCategory, setVariabilityCategory] = useState('all')

  const handleChangeComparaisonType = event => {
    setComparaisonType(event.target.value)
  }

  const handleChangeVariabilityCategory = event => {
    setVariabilityCategory(event.target.value)
  }

  useEffect(() => {
    const result = metricsList?.metrics?.filter(obj => obj?.board_title === 'Taux de variation horaire')
    setResults(result)
    if (value === index) {
      if (result && result[0]) {
        if (result[0]?.payload?.filter(p => p[comparaisonType]).length !== 0) {
          const govA = result[0]?.payload.filter(p => p[comparaisonType])[0][comparaisonType]?.gov_a
          const govB = result[0]?.payload.filter(p => p[comparaisonType])[0][comparaisonType]?.gov_b
          const typeGovA = result[0]?.payload.filter(p => p[comparaisonType])[0][comparaisonType]?.type_gov_a
          const typeGovB = result[0]?.payload.filter(p => p[comparaisonType])[0][comparaisonType]?.type_gov_b
          dispatch(metricsService.getHourlyVariation({
            uri: result[0]?.uri.substring(result[0].uri.indexOf('/usage_reseau')),
            gov_a: govA?.toString(),
            gov_b: govB?.toString(),
            type_gov_a: typeGovA,
            type_gov_b: typeGovB,
            category_filter: variabilityCategory,
            filters,
          }))
        }
      }
    }
  }, [metricsList, comparaisonType, filters, value, variabilityCategory])

  useEffect(() => {
    dispatch(metricsService.getCategoriesVariabiliteHourly())
    const result = metricsList?.metrics?.filter(obj => obj?.board_title === 'Taux de variation horaire')
    if (result && fromGroix) {
      setComparaisonType(Object.keys(result[0].payload[0])[0])
    }
  }, [metricsList])

  const download = (image, { name = `${activeStation.abv_traction} - ${comparaisonType}${fromGroix ? '' : ` - ${format(new Date(Date.now()), 'dd-MM-yyyy')}`}`, extension = 'jpeg' } = {}) => {
    const a = document.createElement('a')
    a.href = image
    a.download = createFileName(extension, name)
    a.click()
  }

  function getImage() {
    takeScreenshot(ref.current).then(download)
  }

  function percentage(slice) {
    return ((slice?.points[1]?.data?.yFormatted / slice?.points[0]?.data?.yFormatted) * 100).toFixed(0)
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-hv-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      ref={ref}
    >
      {value === index && (
        <Paper sx={{
          width: '100%', boxShadow: 'none', paddingLeft: '3rem', paddingRight: '3rem',
        }}
        >
          <Loading open={loadingHourlyVariation} />
          <div className="d-flex w-100 justify-content-between my-3 align-items-center">
            <div className="h-100 justify-content-between align-items-between">
              <h1 className="main-title font-weight-bold">{terms.HourlyVariation.HourlyVariationTitle}</h1>
              <h4 className="sub-title">{terms.HourlyVariation.HourlyVariationSubtitle}</h4>
            </div>
            <FormControl sx={{ m: 1, minWidth: 260 }} className="ml-auto" data-html2canvas-ignore>
              <Select
                value={comparaisonType}
                label="Détail variation"
                onChange={handleChangeComparaisonType}
                className="detail-variation-select"
              >
                {results ? (results[0].payload.map((a, i:number) => (
                  <MenuItem key={Object.keys(results[0].payload[i])[0]} value={Object.keys(results[0].payload[i])[0]}>{Object.keys(results[0].payload[i])[0]}</MenuItem>
                ))) : <></>}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 260 }} data-html2canvas-ignore>
              <Select
                value={variabilityCategory}
                label={terms.VariationDetail.menuItemVariabilityCategory}
                className="detail-variation-select"
                onChange={handleChangeVariabilityCategory}
                disabled={errorHourlyVariationCode?.status === 400}
              >
                <MenuItem disabled value={terms.VariationDetail.menuItemVariabilityCategory}><em>{terms.VariationDetail.menuItemVariabilityCategory}</em></MenuItem>
                <MenuItem value="all"><em>{terms.VariationDetail.menuItemAllCategories}</em></MenuItem>
                {categoriesVariabiliteHourly.map(cv => (
                  <MenuItem key={cv.value} value={cv.value}>
                    <InfoHover title={cv.description}>
                      <div>{cv.value}</div>
                    </InfoHover>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ColorButton data-html2canvas-ignore disabled={errorHourlyVariationCode?.status === 400} onClick={() => getImage()} startIcon={<FileDownloadOutlinedIcon />}>{terms.common.jpegExport}</ColorButton>
          </div>
          {(errorHourlyVariationCode?.status === 400) && <WarningCard message={errorHourlyVariationCode?.message?.detail} />}
          {filters && hourlyVariation.length === 0 && !errorHourlyVariation && <WarningCard message={terms.Filters.noResultNotice} />}
          {metricsList?.missing_days?.length !== 0 && !fromGroix && <MissingGovAlert />}
          {!errorHourlyVariation && !loadingHourlyVariation && hourlyVariation.length !== 0 && (
          <Box sx={{ height: '55vh' }}>
            <ResponsiveLine
              data={hourlyVariation}
              curve="catmullRom"
              enableArea
              areaOpacity={1}
              enableCrosshair
              crosshairType="x"
              theme={{
                crosshair: {
                  line: {
                    strokeWidth: 3,
                    stroke: 'white',
                    strokeOpacity: 1,
                    strokeDasharray: null,
                  },
                },
                grid: {
                  line: {
                    strokeWidth: 1,
                    stroke: '#EDEDED',
                    opacity: 0.5,
                  },
                },
                legends: {
                  text: {
                    fontSize: 14,
                  },
                },
              }}
              lineWidth={3}
              colors={monochrome ? ['#4A81F0', '#C0CFED'] : [color5b, color6b]}
              defs={[
                linearGradientDef('gradientA', [
                  { offset: 10, color: monochrome ? '#C0CFED' : color6b },
                  { offset: 100, color: monochrome ? '#C0CFED' : color6b, opacity: 0 },
                ]),
                linearGradientDef('gradientB', [
                  { offset: 30, color: monochrome ? '#81A5EF' : color5b },
                  { offset: 100, color: monochrome ? '#4A81F0' : color5b, opacity: 0 },
                ]),
              ]}
              fill={[
                { match: { id: 'Nombre de circulations modifiées par demi-heure' }, id: 'gradientB' },
                { match: '*', id: 'gradientA' },
              ]}
              enablePoints
              pointSize={10}
              pointColor="white"
              pointBorderColor={monochrome ? '#9BB9F2' : color3b}
              pointBorderWidth={3}
              pointLabelYOffset={-12}
              animate
              enableSlices="x"
              sliceTooltip={({ slice }) => (
                <div
                  style={{
                    background: 'black',
                    padding: '9px 12px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    opacity: '0.90',
                    color: '#FFF',
                    textAlign: 'center',
                    fontSize: '1rem',
                  }}
                >
                  <div style={{ fontWeight: '900' }}>
                    {slice.points[0].data.xFormatted}
                  </div>
                  <div>
                    <span style={{ fontWeight: '900' }}>
                      {slice.points[0].data.yFormatted}
                      {' '}
                      {' '}
                    </span>
                    <span style={{ fontWeight: '400' }}>
                      {slice.points[0].serieId.toString().substr(slice.points[0].serieId.toString().lastIndexOf('.') + 1)}

                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: '900' }}>
                      {slice.points[1].data.yFormatted}
                      {' '}
                      {' '}
                    </span>
                    <span style={{ fontWeight: '400' }}>
                      {slice.points[1].serieId.toString().substr(slice.points[1].serieId.toString().lastIndexOf('.') + 1)}

                    </span>
                  </div>
                  {Number(slice?.points[0]?.data?.yFormatted) > 0
                    && (
                    <div style={{ color: '#99B7F6', fontWeight: '900' }}>
                      <span>
                        {percentage(slice)}
                        {terms.HourlyVariation.TooltipPercentage}
                      </span>
                    </div>
                    )}

                </div>

              )}
              legends={[
                {
                  anchor: 'top',
                  direction: 'row',
                  justify: false,
                  translateX: 70,
                  translateY: -70,
                  itemsSpacing: 170,
                  itemDirection: 'left-to-right',
                  itemWidth: 450,
                  itemHeight: 60,
                  itemOpacity: 1,
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  symbolShape: ({ fill }) => <rect x={-50} y={25} width="60px" height="10px" rx="5" fill={fill} />,
                },
              ]}
              margin={{
                top: 50, right: 110, bottom: 50, left: 60,
              }}
              xScale={{ type: 'point' }}
              yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: false,
                reverse: false,
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickValues: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Heure',
                legendOffset: 36,
                legendPosition: 'middle',
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Nombre de circulations',
                legendOffset: -40,
                legendPosition: 'middle',
              }}
            />
          </Box>
          )}
        </Paper>
      )}
    </div>
  )
}
