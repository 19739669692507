import { Checkbox, Grid, Typography } from '@mui/material'
import { ReactElement } from 'react'
import terms from 'common/terms'

type Props = {
    possibleDivertedTrains: boolean;
    divertedTrains: boolean;
    handleDivertedTrains: () => void;
}

export default function DivertedTrainsCheckbox({
  possibleDivertedTrains, divertedTrains, handleDivertedTrains,
}: Props): ReactElement {
  const selectionColor = !possibleDivertedTrains ? '#B7B7B7' : 'black'
  const legendColor = !possibleDivertedTrains ? '#B7B7B7' : 'white'

  return (
    <Grid
      data-html2canvas-ignore
      container
      direction="row"
      item
      xs={12}
      sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
    >
      <Checkbox
        checked={divertedTrains}
        onChange={handleDivertedTrains}
        disabled={!possibleDivertedTrains}
      />
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '400',
          color: selectionColor,
          textAlign: 'center',
        }}
      >
        {terms.TracksUsage.divertedTrains}
        {' '}
        &nbsp;
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '400',
          color: legendColor,
          textAlign: 'center',
          mr: '25px',
        }}
      >
        {terms.TracksUsage.noData}
      </Typography>
    </Grid>
  )
}
