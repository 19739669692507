import {
  OriginDestination, TimeRange, TrainNumberRange, TrainNumberField,
} from 'reducers/types'
import terms from 'common/terms'

export const getTrainNames = (trainNumbers: string[]) => {
  const trainNumberFields: TrainNumberField[] = []
  trainNumbers.forEach((train: string, index: number) => {
    switch (true) {
      case (train.startsWith('~')):
        trainNumberFields.push({ index, operator: terms.Filters.contains, trainNumber: train.split('~')[1] })
        break
      case (train.startsWith('-')):
        trainNumberFields.push({ index, operator: terms.Filters.endsWith, trainNumber: train.split('-')[1] })
        break
      case (train.endsWith('-')):
        trainNumberFields.push({ index, operator: terms.Filters.startsWith, trainNumber: train.split('-')[0] })
        break
      default:
        trainNumberFields.push({ index, operator: terms.Filters.equalTo, trainNumber: train })
        break
    }
  })
  return trainNumberFields
}

export const getNumberRanges = (filtersRange: string[]) => {
  const numberRanges: TrainNumberRange[] = []
  filtersRange.map((range: string, index: number) => numberRanges.push({
    index,
    start: range.split('-')[0],
    end: range.split('-')[1],
  }))
  return numberRanges
}

export const getOriginDestination = (filtersRange: string[]) => {
  const oriDest: OriginDestination[] = []
  filtersRange.map((range: string, index: number) => oriDest.push({
    index,
    origin: range.split('-')[0],
    destination: range.split('-')[1],
  }))
  return oriDest
}

export const getTimeRange = (timeRange: string[]) => {
  const ranges: TimeRange[] = []
  timeRange.map((range: string, index: number) => {
    const startTime = range.split('-')[0]
    const endTime = range.split('-')[1]
    const startDate = new Date()
    const endDate = new Date()
    startDate.setHours(+startTime.split(':')[0], +startTime.split(':')[1], 0)
    endDate.setHours(+endTime.split(':')[0], +endTime.split(':')[1], 0)
    ranges.push({
      index,
      startTime: startDate,
      endTime: endDate,
    })
    return null
  })
  return ranges
}

export const getTimeFormat = (range: TimeRange) => {
  const zeroFormat = '0'
  const startHours = range.startTime.getHours() < 10
    ? zeroFormat.concat(range.startTime.getHours().toString()) : range.startTime.getHours().toString()
  const endHours = range.endTime.getHours() < 10
    ? zeroFormat.concat(range.endTime.getHours().toString()) : range.endTime.getHours().toString()
  const startMinutes = range.startTime.getMinutes() < 10
    ? zeroFormat.concat(range.startTime.getMinutes().toString()) : range.startTime.getMinutes().toString()
  const endMinutes = range.endTime.getMinutes() < 10
    ? zeroFormat.concat(range.endTime.getMinutes().toString()) : range.endTime.getMinutes().toString()
  return {
    startHours,
    endHours,
    startMinutes,
    endMinutes,
  }
}
