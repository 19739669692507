/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import terms from 'common/terms'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { TrainNumberField } from 'reducers/types'
import { updateTrainNumberFields, updateTrainsNumber } from 'reducers/filters'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import InputFilterTitle from './InputFilterTitle'
import { getTrainNames } from './utils'
import TrainNumberFilterFields from './TrainNumberFilterFields'

export default function TrainNumberFilter(): ReactElement {
  const dispatch = useDispatch()
  const { trainsNumberFields } = useSelector((state: RootState) => state.filters)
  const { filters } = useSelector((state: RootState) => state.metrics)

  useEffect(() => {
    if (filters && trainsNumberFields.length === 0) {
      dispatch(updateTrainNumberFields(getTrainNames(filters.train_number)))
    }
  }, [filters, trainsNumberFields])

  useEffect(() => {
    if (trainsNumberFields.length !== 0) {
      const trains: string[] = []
      trainsNumberFields.forEach((train: TrainNumberField) => {
        switch (true) {
          case (train.trainNumber !== '' && train.operator === terms.Filters.equalTo):
            trains.push(train.trainNumber)
            break
          case (train.trainNumber !== '' && train.operator === terms.Filters.contains):
            trains.push(('~').concat(train.trainNumber))
            break
          case (train.trainNumber !== '' && train.operator === terms.Filters.startsWith):
            trains.push((train.trainNumber).concat('-'))
            break
          case (train.trainNumber !== '' && train.operator === terms.Filters.endsWith):
            trains.push(('-').concat(train.trainNumber))
            break
          default:
            break
        }
      })
      dispatch(updateTrainsNumber(trains))
    }
  }, [trainsNumberFields])

  const addTrainNumber = () => {
    dispatch(updateTrainNumberFields([...trainsNumberFields,
      { index: trainsNumberFields.length, operator: terms.Filters.equalTo, trainNumber: '' }]))
  }

  return (
    <>
      <InputFilterTitle title={terms.Filters.searchByTrainNumber} onClick={addTrainNumber} />
      <Grid container>
        <Grid item xs={5.5} sx={{ mb: '7px' }}>
          <Typography
            sx={{
              fontFamily: 'Avenir', fontWeight: '400', color: '#00000099', fontSize: '1rem', width: '40%',
            }}
          >
            {terms.Filters.operator}
          </Typography>
        </Grid>
        <Grid item xs={5.5} sx={{ mb: '7px' }}>
          <Typography
            sx={{
              fontFamily: 'Avenir', fontWeight: '400', color: '#00000099', fontSize: '1rem', width: '70%',
            }}
          >
            {terms.Filters.value}
          </Typography>
        </Grid>
      </Grid>
      {trainsNumberFields.map((field: TrainNumberField, index: number) => (
        <TrainNumberFilterFields key={index} field={field} index={index} />
      ))}
    </>
  )
}
