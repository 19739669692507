/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable max-len */
import { ReactElement } from 'react'
import {
  addMonths, format, getMonth, subMonths,
} from 'date-fns'
import IconButton from '@mui/material/IconButton'
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded'
import './stylesheets/datepicker.scss'

type Props = {
  customHeaderCount: number;
  decreaseMonth: Function;
  setLeftMonth: Function;
  monthDate: Date;
  prevMonthButtonDisabled: boolean;
  minDate: Date;
}

export default function HeaderArrowLeft({
  customHeaderCount, decreaseMonth, setLeftMonth, monthDate,
  prevMonthButtonDisabled, minDate,
}: Props): ReactElement {
  return (
    <IconButton
      onClick={() => {
        decreaseMonth()
        setLeftMonth(customHeaderCount === 0
          ? getMonth(subMonths(monthDate, 1))
          : getMonth(subMonths(monthDate, 2)))
      }}
      disabled={customHeaderCount === 0
        ? prevMonthButtonDisabled || format(new Date(monthDate), 'yyyy-MM') === format(new Date(minDate), 'yyyy-MM')
        : prevMonthButtonDisabled || format(new Date(monthDate), 'yyyy-MM') === format(new Date(addMonths(minDate, 1)), 'yyyy-MM')}
      sx={{
        fontSize: 30,
        height: '40px',
        width: '40px',
        position: 'absolute',
        left: -15,
        top: 140,
        color: '#1976d2',
        '&.Mui-disabled': {
          color: '#d3d3d3',
        },
      }}
      style={customHeaderCount === 1 ? { visibility: 'hidden' } : null}
    >
      <ArrowCircleLeftRoundedIcon fontSize="inherit" />
    </IconButton>
  )
}
