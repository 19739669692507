/* eslint-disable no-nested-ternary */
import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined'
import { SelectChangeEvent } from '@mui/material'
import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { RootState } from 'Store'
import { filterButton, tabsMenu } from 'common/CustomTheme'
import PathNames from 'common/PathNames'
import terms from 'common/terms'
import TabsMenu from 'components/Common/TabsMenu/TabsMenu'
import TopBar from 'components/Common/TopBar/TopBar'
import FiltersModal from 'components/Station/Filters/FiltersModal'
import history from 'customHistory'
import {
  ReactElement, SyntheticEvent, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMetricsInitialState } from 'reducers/metrics'
import { updateDisplayMissingGovNotice } from 'reducers/missingGov'
import { setActiveGov, setActiveNormes } from 'reducers/normes'
import metricsService from 'reducers/services/metricsService'
import normesService, { GOVMetrics } from 'reducers/services/normesService'
import SelectGOV from './Conflict/Component/SelectGOV'
import TAB_MENU from './TabMenu'
import './stationRE.scss'
import Reusability from './Reusability'
import Reoccupation from './Reoccupation'
import MissingGovAlert from './Common/MissingGov/MissingGovAlert'
import Conflict from './Conflict/Index'

const badgeStyle = {
  '& .MuiBadge-badge': {
    color: 'white',
    backgroundColor: '#FC5130',
    right: '10px',
    top: '5px',
  },
}

const StationRE = (): ReactElement => {
  const dispatch = useDispatch()
  const { confirmedDates } = useSelector((state: RootState) => state.annualServices)
  const { activeStation } = useSelector((state: RootState) => state.stations)
  const { metricsList } = useSelector((state: RootState) => state.metrics)
  const { activeNormes, missingDays, activeGov } = useSelector((state: RootState) => state.normes)
  const { uploadedList } = useSelector((state: RootState) => state.groix)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const tabMenu = metricsList?.metrics?.filter(
    obj => obj?.board === 'PAGINE',
  ).length !== 0 ? TAB_MENU : TAB_MENU.slice(0, 6)

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  useEffect(() => {
    dispatch(normesService.getNormesJsa({
      gare_id: activeStation.id,
      dates: confirmedDates.toString().replace(/-/g, ''),
    }))
  }, [confirmedDates, uploadedList])

  useEffect(() => {
    dispatch(metricsService.getMetricsParams())
  }, [])

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    dispatch(setActiveNormes(newValue))
    dispatch(updateDisplayMissingGovNotice(false))
  }

  const handleOnClick = () => {
    dispatch(setMetricsInitialState())
    history.push(PathNames.DateChoice)
  }

  const handleChangeGov = (event: SelectChangeEvent) => {
    dispatch(setActiveGov(event.target.value as GOVMetrics))
  }

  const getComponent = (index: number) => {
    switch (index) {
      case 0:
        return (
          <Conflict
            index={0}
            value={activeNormes}
            type={activeGov}
          />
        )
      case 1:
        return <Reusability index={1} value={activeNormes} type={activeGov} />
      case 2:
        return <Reoccupation index={2} value={activeNormes} type={activeGov} />
      default:
        return <Conflict index={0} value={activeNormes} type={activeGov} />
    }
  }

  return (
    <main className="mastcontainer pl-0 body-alter-background">
      <TopBar appName={terms.common.appName} goHomeIcon />
      <Container maxWidth={false} disableGutters sx={{ height: '100%' }}>
        <Grid container mt="20px">
          <div className="menu">
            <div>
              <FiltersModal />
            </div>
            <div className="menu-item">
              <ThemeProvider theme={tabsMenu}>
                <TabsMenu tabValues={tabMenu} handleChange={handleChange} value={activeNormes} />
              </ThemeProvider>
            </div>

            <div>
              <SelectGOV
                value={activeGov}
                options={Object.values(GOVMetrics)}
                handleChange={handleChangeGov}
              />
              <ThemeProvider theme={filterButton}>
                <Badge badgeContent={confirmedDates?.length} sx={badgeStyle}>
                  <Button
                    onClick={handleOnClick}
                    variant="contained"
                    disableElevation
                    size="small"
                    startIcon={<CalendarIcon />}
                  >
                    {windowWidth < 1400 ? terms.TabMenu.txtBtDays : terms.TabMenu.txtBtSelectedDays}
                  </Button>
                </Badge>
              </ThemeProvider>
            </div>
          </div>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={12} style={{ minHeight: '80vh' }}>
              {
                getComponent(activeNormes)
              }
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {missingDays.length !== 0 && <MissingGovAlert />}
    </main>
  )
}

export default StationRE
