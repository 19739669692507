import { Grid } from '@mui/material'
import { RootState } from 'Store'
import warningIcon from 'assets/icons/warning.png'
import terms from 'common/terms'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateHasNoGovs } from 'reducers/missingGov'
import GovService from 'reducers/services/govService'
import { GovsList } from 'reducers/types'
import { v4 as uuidV4 } from 'uuid'
import ReImportNotice from './ReImportNotice'

import './missingGov.scss'

const MissingDays = () => {
  const dispatch = useDispatch()
  const { missingDays, missingGare } = useSelector((state: RootState) => state.normes)
  const { govs, hasNoGovs } = useSelector((state: RootState) => state.missingGov)
  const [noDataStatus, setNoDataStatus] = useState<GovsList[]>(undefined)
  const [pendingStatus, setPendingStatus] = useState<GovsList[]>(undefined)

  const handleClickImport = (key: string) => {
    const ids: string[] = []
    missingDays[key].forEach(day => {
      if (Object.values(day)[0] !== '') {
        ids.push(Object.values(day)[0] as string)
      }
    })
    if (ids.length !== 0) {
      dispatch(GovService.updateGovs({ ids, comparaisontype: key }))
    } else {
      dispatch(updateHasNoGovs(true))
    }
  }

  const getMissingGare = (comparaisonType: string) => (missingGare.length !== 0
    ? missingGare[comparaisonType] : [])

  const getGovsList = (key: string) => (govs?.filter((gov: GovsList) => gov.comparaisonType
  === key)[0])?.govs

  const getGovsStatus = (key: string, status: string) => (govs?.filter((gov: GovsList) => gov.comparaisonType
  === key)[0])?.govs.filter(gov => gov.statut === status)

  useEffect(() => {
    const noDataGovs: GovsList[] = []
    Object.keys(missingDays).map(key => (noDataGovs.push({
      govs: getGovsStatus(key, 'no_data'),
      comparaisonType: key,
    })))
    setNoDataStatus(noDataGovs)
    const pendingGovs: GovsList[] = []
    Object.keys(missingDays).map(key => (pendingGovs.push({
      govs: getGovsStatus(key, 'pending'),
      comparaisonType: key,
    })))
    setPendingStatus(pendingGovs)
  }, [govs])

  const disabledImportButton = (key: string): boolean => (
    pendingStatus?.filter(gov => gov.comparaisonType === key)[0]?.govs?.length
     === getGovsList(key)?.length && getGovsList(key))
    || missingDays[key].length === 0
    || hasNoGovs
    || key === 'Réel (J)'

  return (
    <Grid
      container
      spacing={5}
      justifyContent="center"
      alignItems="start"
      sx={{ width: '100%', mx: '6%' }}
    >
      {
        missingDays.length !== 0 && Object.keys(missingDays).map((key: string) => (
          <Grid className="type" key={uuidV4()} item xs={6} sm={4} justifyContent="center">
            <div className={`type-container ${key === 'Réel (J)' ? 'disabled' : ''} `}>
              <header className="missing-day-headers">
                <div className="title">
                  <img src={warningIcon} alt="" width={30} className="mr-2" />
                  <span>
                    {key}
                  </span>
                </div>
                {
                  getMissingGare(key).length === 0 && key !== 'Réel (J)' && (
                    <ReImportNotice
                      comparaisonType={key}
                      govs={noDataStatus?.filter(gov => gov.comparaisonType === key)[0]?.govs}
                    />
                  )
                }
              </header>
              {
                key === 'Réel (J)' ? (
                  <div />
                ) : (
                  <div className="overflow-container">
                    {
                      getMissingGare(key).length === 0 ? (
                        <div className="day-container">
                          {
                            missingDays[key].map((day: string) => (
                              <div key={Object.keys(day)[0]} className="day">
                                {new Date(Object.keys(day)[0]).toLocaleString('fr-FR', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                })}
                              </div>
                            ))
                          }
                        </div>
                      ) : (
                        missingGare[key].length !== 0 && (
                        <div className="notice">
                          {missingGare[key]}
                        </div>
                        )
                      )
                    }
                  </div>
                )
              }

            </div>
            <div className={`import-button ${key === 'Réel (J)' ? 'off' : ''} `}>
              <button
                type="button"
                disabled={disabledImportButton(key)}
                onClick={() => handleClickImport(key)}
              >
                {
                  disabledImportButton(key) && key !== 'Réel (J)'
                    ? terms.MissingGov.reImportPending
                    : terms.MissingGov.reImport
                }
              </button>
            </div>
          </Grid>
        ))
      }
    </Grid>
  )
}

export default MissingDays
