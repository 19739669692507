import { Table, TableContainer } from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import MissingData from 'components/Station/MissingData'
import moment from 'moment'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import normesService, { MetricsNormes, ReusabilityTable as ReusabilityTableType } from 'reducers/services/normesService'
import ModalDetailTable from './ModalDetailTable/ModalDetailTable'
import ReusabilityTableHeader from './ReusabilityTableHeader'
import CustomTableBody from './Table/CustomTableBody'
import TableHeader from './Table/TableHeader'
import ModalHistoryTable from './ModalHistoryTable/ModalHistoryTable'

interface Props {
  normesSelected: MetricsNormes[];
  type: string;
  sortTable: string;
  setSortTable: (value: string) => void;
}

const ReusabilityTable = ({
  normesSelected, type, sortTable, setSortTable,
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const {
    reusabilityTable,
    isLoading,
    errorReutilityTable,
    isLoadingReutilityTable,
  } = useSelector((state: RootState) => state.normes)
  const { activeStation } = useSelector((state: RootState) => state.stations)
  const [openModalDetail, setOpenModalDetail] = useState(false)
  const [openModalHistory, setOpenModalHistory] = useState(false)
  const [arrivalSelected, setArrivalSelected] = useState('')
  const [departureSelected, setDepartureSelected] = useState('')
  const [keySelected, setKeySelected] = useState<string>('')
  const [labelSelected, setLabelSelected] = useState<string>('')
  const [sortBalance, setSortBalance] = useState<string>('')
  const [sortHistory, setSortHistory] = useState<string>('')
  const dataTable = reusabilityTable.data
  const columns = () => {
    if (dataTable.length === 0) return []
    const result = dataTable[0].value.map(item => item.label)
    result.unshift(reusabilityTable.meta.index_label)
    return result
  }

  const downloadExcel = async (metricType: string) => {
    const response = await dispatch(normesService.downloadExcel({
      type: metricType,
      gov_a: normesSelected[0]?.payload[type].gov_a.toString(),
      ...(metricType === 'reutilisation_balances_details' ? {
        key: keySelected,
        label: labelSelected,
      } : {
        station_id: activeStation.id,
        arrival: arrivalSelected,
        departure: departureSelected,
      }),
    })) as unknown as { type: string; payload: ArrayBuffer }

    if (response.type.endsWith('fulfilled')) {
      const blob = new Blob(
        [response.payload],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      )
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${metricType}-${moment().format('L')}.xlsx`

      link.click()
    }
  }

  const handleClickBalance = async (key: string, label: string) => {
    setKeySelected(key)
    setLabelSelected(label)
    const response = await dispatch(normesService.getDetailReusability({
      key,
      label,
      gov_a: normesSelected[0]?.payload[type].gov_a.toString(),
      ...(sortBalance && { sorting: sortBalance }),
    })) as unknown as { type: string; payload: { data: ReusabilityTableType } }
    if (response.type.endsWith('fulfilled')) {
      setOpenModalDetail(true)
    }
  }

  const handleCloseModal = (modalType: string) => {
    switch (modalType) {
      case 'detail':
        setOpenModalDetail(false)
        setKeySelected('')
        setLabelSelected('')
        break
      case 'history':
        setOpenModalHistory(false)
        setArrivalSelected('')
        setDepartureSelected('')
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (!keySelected && !labelSelected) return
    handleClickBalance(keySelected, labelSelected)
  }, [sortBalance])

  const handleClickHistory = async (arrivalValue: string, departureValue: string) => {
    setArrivalSelected(arrivalValue)
    setDepartureSelected(departureValue)
    const response = await dispatch(normesService.getDetailReusabilityHistory({
      arrival: arrivalValue,
      departure: departureValue,
      station_id: activeStation.id,
      gov_a: normesSelected[0]?.payload[type].gov_a.toString() || '',
      ...(sortBalance && { sorting: sortHistory }),
    })) as unknown as { type: string; payload: { data: ReusabilityTableType } }
    if (response.type.endsWith('fulfilled')) {
      setOpenModalHistory(true)
    }
  }

  useEffect(() => {
    if (sortHistory) {
      dispatch(normesService.getDetailReusabilityHistory({
        arrival: arrivalSelected,
        departure: departureSelected,
        station_id: activeStation.id,
        gov_a: normesSelected[0]?.payload[type].gov_a.toString() || '',
        sorting: sortHistory,
      }))
    }
  }, [sortHistory])

  return (
    <>
      <div className="reusability-table">
        <ReusabilityTableHeader onDownload={() => downloadExcel('reutilisation_detail')} />
        <TableContainer sx={{ maxWidth: 1050, margin: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHeader
              data={columns()}
              sortTable={sortTable}
              setSortTable={setSortTable}
            />
            <CustomTableBody
              reusabilityTable={reusabilityTable}
              handleClickBalance={handleClickBalance}
            />
          </Table>
        </TableContainer>
      </div>
      {
      (dataTable.length === 0) && !isLoading && !isLoadingReutilityTable && (
        <div
          className="missing-data no-data-table"
        >
          <MissingData
            message={errorReutilityTable ? errorReutilityTable.message.detail : terms.NormesRE.noData}
          />
        </div>
      )
    }
      <ModalDetailTable
        open={openModalDetail}
        handleClose={() => handleCloseModal('detail')}
        onDownload={() => downloadExcel('reutilisation_balances_details')}
        sortBalance={sortBalance}
        setSortBalance={setSortBalance}
        handleClickHistory={handleClickHistory}
      />
      <ModalHistoryTable
        open={openModalHistory}
        handleClose={() => handleCloseModal('history')}
        onDownload={() => downloadExcel('reutilisation_balance_historic')}
        sortHistory={sortHistory}
        setSortHistory={setSortHistory}
        arrivalSelected={arrivalSelected}
        departureSelected={departureSelected}
      />
    </>
  )
}

export default ReusabilityTable
