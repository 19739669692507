import {
  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'

interface Props {
  size: 'small' | 'medium';
  handleChange: (event: SelectChangeEvent<string>) => void;
  disabled?: boolean;
  value: string;
  options: string[];
}

const SelectStatus = ({
  size, handleChange, disabled, value, options,
}: Props):ReactElement => (
  <FormControl variant="outlined" className="mb-3" size={size} fullWidth>
    <InputLabel id="select-visibility">
      {terms.NormesRE.ConflictsGov.ConflictsEvolution.selectStatus}
    </InputLabel>
    <Select
      disabled={disabled}
      size={size}
      value={value}
      onChange={handleChange}
      label={terms.NormesRE.ConflictsGov.ConflictsEvolution.selectStatus}
    >
      <MenuItem value="all">
        {terms.NormesRE.ConflictsGov.ConflictsEvolution.allStatus}
      </MenuItem>
      { options.map(e => (
        <MenuItem key={e} value={e}>
          {e}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

SelectStatus.defaultProps = {
  disabled: false,
}

export default SelectStatus
