import { get, patch } from '@osrdata/app_core/dist/requests'
import { ThunkApi } from 'types'
import { createAsyncThunk } from '@reduxjs/toolkit'

type GetTracksParams = any

const getTracksList = createAsyncThunk<any, GetTracksParams, ThunkApi>(
  'tracks/getTracksList',
  async (params: GetTracksParams, thunkApi) => {
    try {
      const tracksList = await get(`/gov/gares/${params.id}/quais/`)
      return tracksList.voies_a_quai
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchTrack = createAsyncThunk<any, any, ThunkApi>(
  'tracks/updateTrack',
  async (object: any, thunkApi) => {
    try {
      const response: any = await patch(`/gov/gares/${object.stationId}/quais/`, object.newTracksList)
      return response.voies_a_quai
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const TracksService = {
  getTracksList,
  patchTrack,
}

export default TracksService
