/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import {
  ReactElement, SyntheticEvent, useEffect, useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import Container from '@mui/material/Container'
import TopBar from 'components/Common/TopBar/TopBar'
import TabsMenu from 'components/Common/TabsMenu/TabsMenu'
import { filterButton, tabsMenu } from 'common/CustomTheme'
import metricsService from 'reducers/services/metricsService'
import { setMetricsInitialState, updateActiveMetrics } from 'reducers/metrics'
import history from 'customHistory'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import Divider from '@mui/material/Divider'
import Badge from '@mui/material/Badge'
import { updateDisplayMissingGovNotice } from 'reducers/missingGov'
import PathNames from 'common/PathNames'
import GovVariation from './GovVariation/GovVariation'
import DetailVariation from './DetailVariation/DetailVariation'
import HourlyVariation from './HourlyVariation'
import TrackUsage from './TrackUsage/TrackUsage'
import Ponctuality from './Ponctuality/Ponctuality'
import Trains from './Trains/Trains'
import Pagine from './Pagine/Pagine'

import './station.scss'
import TAB_MENU from './TabMenu'
import FiltersModal from './Filters/FiltersModal'

const badgeStyle = {
  '& .MuiBadge-badge': {
    color: 'white',
    backgroundColor: '#FC5130',
    right: '10px',
    top: '5px',
  },
}

export default function Station(): ReactElement {
  const dispatch = useDispatch()
  const { confirmedDates } = useSelector((state: RootState) => state.annualServices)
  const { activeStation } = useSelector((state: RootState) => state.stations)
  const { metricsList, fromGroix, activeMetrics } = useSelector((state: RootState) => state.metrics)
  const { uploadedList } = useSelector((state: RootState) => state.groix)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const tabMenu = metricsList?.metrics?.filter(obj => obj?.board === 'PAGINE').length !== 0 ? TAB_MENU : TAB_MENU.slice(0, 6)

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  useEffect(() => {
    if (fromGroix) {
      const idList = []
      uploadedList.forEach(element => {
        idList.push(element.id)
      })
      dispatch(metricsService.getMetricsGroix({
        id: idList.toString(),
      }))
    } else {
      dispatch(metricsService.getMetricsJsa({
        gare_id: activeStation.id,
        dates: confirmedDates.toString().replace(/-/g, ''),
      }))
    }
  }, [confirmedDates, uploadedList])

  useEffect(() => {
    dispatch(metricsService.getMetricsParams())
  }, [])

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    dispatch(updateActiveMetrics(newValue))
    dispatch(updateDisplayMissingGovNotice(false))
  }

  const handleOnClick = () => {
    dispatch(setMetricsInitialState())
    if (fromGroix) {
      history.push(PathNames.FileImport)
    } else {
      history.push(PathNames.DateChoice)
    }
  }

  return (
    <main className="mastcontainer pl-0 body-alter-background">
      <TopBar appName={terms.common.appName} goHomeIcon />
      <Container maxWidth={false} disableGutters sx={{ height: '100%' }}>
        <Grid container justifyContent="center" mt="20px">
          <Grid container item lg={12} xl={10} justifyContent="space-between" alignItems="flex-start" mb="17px">
            <FiltersModal />
            <Grid item>
              <ThemeProvider theme={tabsMenu}>
                <TabsMenu tabValues={tabMenu} handleChange={handleChange} value={activeMetrics} />
              </ThemeProvider>
            </Grid>
            <Grid item>
              <ThemeProvider theme={filterButton}>
                <Badge badgeContent={fromGroix ? uploadedList?.length : confirmedDates?.length} sx={badgeStyle}>
                  <Button
                    onClick={handleOnClick}
                    variant="contained"
                    disableElevation
                    size="small"
                    startIcon={<CalendarIcon />}
                  >
                    {fromGroix
                      ? terms.TabMenu.txtBtSelectedFiles
                      : (windowWidth < 1400 ? terms.TabMenu.txtBtDays : terms.TabMenu.txtBtSelectedDays)}
                  </Button>
                </Badge>
              </ThemeProvider>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <GovVariation
                value={activeMetrics}
                index={0}
              />
              <DetailVariation
                value={activeMetrics}
                index={1}
              />
              <HourlyVariation
                value={activeMetrics}
                index={2}
              />
              <TrackUsage
                value={activeMetrics}
                index={3}
              />
              <Trains
                value={activeMetrics}
                index={4}
              />
              <Ponctuality
                value={activeMetrics}
                index={5}
              />
              <Pagine
                value={activeMetrics}
                index={6}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </main>
  )
}
