import { TabValues } from 'types'
import DataUsageIcon from '@mui/icons-material/DataUsage'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import BarChart from '@mui/icons-material/BarChart'
import TrainIcon from '@mui/icons-material/TrainOutlined'
import TimerIcon from '@mui/icons-material/TimerOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import terms from 'common/terms'

const TAB_MENU: TabValues[] = [
  {
    index: 0,
    label: terms.TabMenu.govVariation,
    icon: (<DataUsageIcon fontSize="small" />),
    iconPosition: 'start',
  },
  {
    index: 1,
    label: terms.TabMenu.detailVariation,
    iconPosition: 'start',
    icon: <ErrorOutlineIcon fontSize="small" />,
  },
  {
    index: 2,
    label: terms.TabMenu.hourlyVariation,
    iconPosition: 'start',
    icon: <TrendingUpIcon fontSize="small" />,
  },
  {
    index: 3,
    label: terms.TabMenu.trackUsage,
    iconPosition: 'start',
    icon: <BarChart fontSize="small" />,
  },
  {
    index: 4,
    label: terms.TabMenu.circulations,
    iconPosition: 'start',
    icon: <TrainIcon fontSize="small" />,
  },
  {
    index: 5,
    label: terms.TabMenu.punctuality,
    iconPosition: 'start',
    icon: <TimerIcon fontSize="small" />,
  },
  {
    index: 6,
    label: terms.TabMenu.pagine,
  },
]

export default TAB_MENU
