import { get, post } from '@osrdata/app_core/dist/requests'
import { ThunkApi } from 'types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Groix } from 'reducers/types'

const getGroixList = createAsyncThunk<Array<Groix>>(
  'groix/getGroixList',
  async () => {
    const response = await get('/gov/fichiers_groix/')
    return response.results
  },
)

const getGroix = createAsyncThunk<Groix, string, ThunkApi>(
  'groix/getGroix',
  async (id, thunkApi) => {
    try {
      const groix = await get(`/gov/fichiers_groix/${id}`)
      return groix
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const uploadGroix = createAsyncThunk<any, any, ThunkApi>(
  'groix/uploadGroix',
  async (object: any, thunkApi) => {
    try {
      const newGroix: any = await post('/gov/fichiers_groix/', object.formData)
      return newGroix
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GroixService = {
  getGroixList,
  getGroix,
  uploadGroix,
}

export default GroixService
