/* eslint-disable max-len */
/* eslint-disable camelcase */
import MAIN_API from 'config/config'
import { Filters } from 'reducers/types'

const filterArrivalGTE = filterArrival => {
  switch (filterArrival.operation) {
    case 'arrival_delay_gte':
      return `&arrival_delay_gte=${filterArrival.value1}`
      break
    case 'between_delay':
      return `&arrival_delay_gte=${filterArrival.value1}`
      break
    case 'arrival_delay_equal':
      return `&arrival_delay_equal=${filterArrival.value1}`
      break
    default:
      return ''
  }
}

const filterArrivalLTE = filterArrival => {
  switch (filterArrival.operation) {
    case 'arrival_delay_lte':
      return `&arrival_delay_lte=${filterArrival.value1}`
      break
    case 'between_delay':
      return `&arrival_delay_lte=${filterArrival.value2}`
      break
    default:
      return ''
  }
}

const filterDepartureGTE = filterDeparture => {
  switch (filterDeparture.operation) {
    case 'departure_delay_gte':
      return `&departure_delay_gte=${filterDeparture.value1}`
      break
    case 'between_delay':
      return `&departure_delay_gte=${filterDeparture.value1}`
      break
    case 'departure_delay_equal':
      return `&departure_delay_equal=${filterDeparture.value1}`
      break
    default:
      return ''
  }
}

const filterDepartureLTE = filterDeparture => {
  switch (filterDeparture.operation) {
    case 'departure_delay_lte':
      return `&departure_delay_lte=${filterDeparture.value1}`
      break
    case 'between_delay':
      return `&departure_delay_lte=${filterDeparture.value2}`
      break
    default:
      return ''
  }
}

const fetchCsv = (govA: string, govB: string, typeGovA: string, typeGovB: string,
  variabilityCategory: string, sorting: string,
  filters: Filters, delayArrival: any, delayDeparture: any, savefile: (v: any) => void) => {
  fetch(`${MAIN_API.proxy}/usage_reseau/metrics/gov_variability_detail/xlsx/
?gov_a=${govA?.toString()}
&gov_b=${govB?.toString()}
&type_gov_a=${typeGovA}
&type_gov_b=${typeGovB}
${filterArrivalGTE(delayArrival)}
${filterArrivalLTE(delayArrival)}
${filterDepartureGTE(delayDeparture)}
${filterDepartureLTE(delayDeparture)}
${variabilityCategory === 'all' ? '' : `&category_filter=${variabilityCategory}`}
&sorting=${sorting}
${filters?.balance_type.toString() && filters?.balance_type.toString() !== ''
    ? `&balance_type=${filters.balance_type.toString()}` : ''}
${filters?.circulation_type.toString() && filters?.circulation_type.toString() !== ''
    ? `&circulation_type=${filters.circulation_type.toString()}` : ''}
${filters?.multi_time_range.toString() && filters?.multi_time_range.toString() !== ''
    ? `&multi_time_range=${filters.multi_time_range.toString()}` : ''}
${filters?.track.toString() && filters?.track.toString() !== '' ? `&track=${filters?.track.toString()}` : ''}
${filters?.train_number_range.toString() && filters?.train_number_range.toString() !== ''
    ? `&train_number_range=${filters.train_number_range.toString()}` : ''}
${filters?.train_number.toString() && filters?.train_number.toString() !== ''
    ? `&train_number=${filters.train_number.toString()}` : ''}
${filters?.equipment.toString() && filters?.equipment.toString() !== ''
    ? `&material_code=${filters.equipment.toString()}` : ''}
${filters?.ori_dest.toString() && filters?.ori_dest.toString() !== ''
    ? `&ori_dest=${filters.ori_dest.toString()}` : ''}`,
  {
    method: 'GET',
    headers: {
      Authorization:
              `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(response => response.blob())
    .then(blob => savefile(blob))
}

export default fetchCsv
