import { TableBody, TableCell, TableRow } from '@mui/material'
import { ReactElement } from 'react'
import { ReusabilityDataTable } from 'reducers/services/normesService'
import { v4 as uuidv4 } from 'uuid'

interface Props {
  data: ReusabilityDataTable[];
}

const HistoryBody = ({ data }: Props): ReactElement => (
  <TableBody>
    {
    data.map(row => (
      <TableRow key={uuidv4()}>
        {
          row.value.map(item => (
            <TableCell key={uuidv4()}>
              <div
                className="cell"
              >
                <span className="value">
                  {item.value}
                </span>
                <span className="ratio">
                  {item.additional_value}
                </span>
              </div>
            </TableCell>
          ))
        }
      </TableRow>
    ))
  }
  </TableBody>
)

export default HistoryBody
