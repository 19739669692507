// monochrome scheme
export const color1a = '#012639'
export const color2a = '#023A58'
export const color3a = '#0A4D77'
export const color4a = '#136095'
export const color5a = '#2173AF'
export const color6a = '#3583C1'
export const color7a = '#71B0E2'
export const color8a = '#9EC2E4'
export const color9a = '#C4D9EB'
export const color10a = '#DBE9F8'

// multicolor scheme
export const color1b = '#219EBC' //  BLUE GREEN       Identique
export const color2b = '#701192' //  MAUVEINE         Changement d\'équilibre
export const color3b = '#034363' //  INDIGO DYE       Changement d\'équilibre et Dévoiement
export const color4b = '#DED391' //  VANILLE          Changement de composition
export const color5b = '#AB5CC1' //  VIOLET AMETHYST  Dévoiement
export const color6b = '#8ECAE6' //  SKY BLUE         Changement d\'équilibre et Changement de composition
export const color7b = '#2274A5' //  UCLA BLUE        Dévoiement et Changement de composition
export const color8b = '#FCBF49' //  JEUNE VANTHOUS   Suppression
export const color9b = '#D62828' //  FIRE ENGINE RED  Ajout
export const color10b = '#F77F00' // ORANGE           Changement d\'équilibre et Dévoiement et Changement de composition
