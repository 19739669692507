import {
  Checkbox, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { ConflictsType } from 'reducers/services/normesService'

interface Props {
  disabled?: boolean;
  handleSelect: (value: string[]) => void;
  options: ConflictsType[];
  selected: string[];
  size?: 'small' | 'medium';
  label: string;
  isSubType?: boolean;
}

const SELECT_ALL_VALUE = 'all'

const CustomSelectType = ({
  options,
  disabled,
  selected,
  handleSelect,
  size,
  label,
  isSubType,
}: Props): ReactElement => {
  const handleSelectAll = () => {
    if (options.length === selected.length) {
      handleSelect([])
      return
    }
    handleSelect([...options.map(i => i.key as string)])
  }

  const handleChange = (evt: SelectChangeEvent<string[]>) => {
    const { target: { value } } = evt
    if (value.includes(SELECT_ALL_VALUE)) {
      handleSelectAll()
      return
    }
    const idList: string[] = typeof value === 'string' ? [value] : value
    handleSelect([...new Set([
      ...idList,
    ])])
  }

  return (
    <FormControl variant="outlined" className="mb-3" size={size} fullWidth>
      <InputLabel id="select-visibility">
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        size={size}
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={select => (
          terms.NormesRE.ConflictsGov.selectConflictType.inputLabel(select as string[], options, isSubType)
        )}
        label={label}
      >
        <MenuItem
          key={SELECT_ALL_VALUE}
          value={SELECT_ALL_VALUE}
          className={selected.length === options.length ? 'Mui-selected' : ''}
          style={{ paddingLeft: '5px' }}
        >
          <Checkbox
            size={size}
            checked={selected.length === options.length}
          />
          <span>
            {selected.length === options.length
              ? terms.NormesRE.ConflictsGov.selectConflictType.selectNone
              : terms.NormesRE.ConflictsGov.selectConflictType.selectAll}
          </span>
        </MenuItem>
        { options.map(e => (
          <MenuItem key={e.key} value={e.key}>
            <Checkbox
              size={size}
              checked={selected.includes(e.key || '')}
            />
            <span>
              {e.key}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

CustomSelectType.defaultProps = {
  size: 'small',
  isSubType: false,
  disabled: false,
}

export default CustomSelectType
