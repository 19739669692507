import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import TracksService from 'reducers/services/tracksService'
import { ResponseError } from 'types'
import { TracksState } from './types'

const initialState: TracksState = {
  tracksList: [],
  loading: false,
  error: undefined,
  updateStatus: false,
}

export const tracksSlice = createSlice({
  name: 'tracks',
  initialState,

  reducers: {
    updateTracksList: (state, action: PayloadAction<Array<string>>) => {
      state.tracksList = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setUpdateStatus: (state, action: PayloadAction<boolean>) => {
      state.updateStatus = action.payload
    },
    setError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.error = action.payload
    },
  },

  extraReducers: builder => {
    builder.addCase(TracksService.getTracksList.pending, state => {
      state.loading = true
    })
    builder.addCase(TracksService.getTracksList.fulfilled, (state, action) => {
      state.tracksList = action.payload
      state.loading = false
    })
    builder.addCase(TracksService.getTracksList.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(TracksService.patchTrack.pending, state => {
      state.loading = true
    })
    builder.addCase(TracksService.patchTrack.fulfilled, (state, action) => {
      state.tracksList = action.payload
      state.loading = false
      state.updateStatus = true
    })
    builder.addCase(TracksService.patchTrack.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {
  updateTracksList, setLoading, setUpdateStatus, setError,
} = tracksSlice.actions

export default tracksSlice.reducer
