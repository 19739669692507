/* eslint-disable max-len */
/* eslint-disable camelcase */
import MAIN_API from 'config/config'
import { Filters } from 'reducers/types'

const fetchPagine = (gov_id: string, journee_pagine_id: string, filters: Filters, savefile: (v: any) => void) => {
  fetch(`${MAIN_API.proxy}/usage_reseau/metrics/pagine/pagine-xlsx/
?gov_id=${gov_id}
&journee_pagine_id=${journee_pagine_id}
${filters?.balance_type.toString() && filters?.balance_type.toString() !== ''
    ? `&balance_type=${filters.balance_type.toString()}` : ''}
${filters?.circulation_type.toString() && filters?.circulation_type.toString() !== ''
    ? `&circulation_type=${filters.circulation_type.toString()}` : ''}
${filters?.multi_time_range.toString() && filters?.multi_time_range.toString() !== ''
    ? `&multi_time_range=${filters.multi_time_range.toString()}` : ''}
${filters?.track.toString() && filters?.track.toString() !== '' ? `&track=${filters?.track.toString()}` : ''}
${filters?.train_number_range.toString() && filters?.train_number_range.toString() !== ''
    ? `&train_number_range=${filters.train_number_range.toString()}` : ''}
${filters?.train_number.toString() && filters?.train_number.toString() !== ''
    ? `&train_number=${filters.train_number.toString()}` : ''}
${filters?.equipment.toString() && filters?.equipment.toString() !== ''
    ? `&material_code=${filters.equipment.toString()}` : ''}
${filters?.ori_dest.toString() && filters?.ori_dest.toString() !== ''
    ? `&ori_dest=${filters.ori_dest.toString()}` : ''}`,
  {
    method: 'GET',
    headers: {
      Authorization:
              `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(response => response.blob())
    .then(blob => savefile(blob))
}

export default fetchPagine
