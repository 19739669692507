import {
  Button, Card, CardActions, CardContent, ThemeProvider,
} from '@mui/material'
import { ReactElement, useEffect } from 'react'
import warningIcon from 'assets/icons/warning.png'
import terms from 'common/terms'
import { customIgnoreButton, customImportButton } from 'common/CustomTheme'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateDisplayIconNotice, updateDisplayMissingGovNotice } from 'reducers/missingGov'
import history from 'customHistory'
import GovService from 'reducers/services/govService'
import PathNames from 'common/PathNames'

import './missingGov.scss'

const MissingGovAlert = (): ReactElement => {
  const dispatch = useDispatch()
  const { displayMissingGovNotice, displayIconNotice } = useSelector((state: RootState) => state.missingGov)
  const { missingDays, normesList, missingGare } = useSelector((state: RootState) => state.normes)

  const missingDaysIds = normesList && normesList.length !== 0
    ? Object.values(missingDays).filter((value: string) => value.length !== 0) : []
  const missingGareId = normesList && normesList.length !== 0
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ? Object.entries(missingGare).filter(([key, value]: [key: string, value: string]) => value.length !== 0) : []

  useEffect(() => {
    if (normesList && missingDays.length !== 0) {
      Object.keys(missingDays).forEach((type: string) => {
        if (missingDays[type].length !== 0) {
          const ids: string[] = []
          missingDays[type].forEach(day => {
            if (Object.values(day)[0] !== '') {
              ids.push(Object.values(day)[0] as string)
            }
          })
          if (ids.length !== 0) {
            dispatch(GovService.getGovs({ ids: ids.toString(), comparaisontype: type }))
          }
        }
      })
    }
  }, [missingDays])

  const handleIgnore = () => {
    dispatch(updateDisplayIconNotice(true))
  }

  const handleImportGov = () => {
    history.push(PathNames.MissingGovNormes)
    dispatch(updateDisplayMissingGovNotice(false))
  }

  const onIconClick = () => {
    dispatch(updateDisplayIconNotice(false))
  }

  useEffect(() => () => {
    dispatch(updateDisplayIconNotice(false))
  }, [])

  return (
    displayIconNotice ? (
      <Card className="missing-gov-icon" onClick={onIconClick}>
        <img src={warningIcon} alt="" width={40} />
      </Card>
    )
      : (!displayMissingGovNotice && (missingDaysIds.length !== 0
        || (missingGareId.length !== 0)) && (
          <Card className="missing-gov-card">
            <CardContent className="p-0">
              <div className="d-flex align-items-center justify-content-center h-100 flex-row">
                <img src={warningIcon} alt="" width={20} />
                <h1 className="font-weight-bold ml-4">{terms.MissingGov.missingGovNotice}</h1>
              </div>
            </CardContent>
            <CardActions>
              <ThemeProvider theme={customIgnoreButton}>
                <Button onClick={handleIgnore}>
                  {terms.MissingGov.ignoreBtn}
                </Button>
              </ThemeProvider>
              <ThemeProvider theme={customImportButton}>
                <Button onClick={handleImportGov}>
                  {terms.MissingGov.importGov}
                </Button>
              </ThemeProvider>
            </CardActions>
          </Card>
      ))
  )
}

export default MissingGovAlert
