import { persistCombineReducers } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage' // defaults to localStorage

import { auth } from '@osrdata/app_core'
import appReducer from './app'
import stationsReducer from './stations'
import tracksReducer from './tracks'
import groixReducer from './groix'
import annualServicesReducer from './annualServices'
import metricsReducer from './metrics'
import missingGovReducer from './missingGov'
import missingPagineReducer from './missingPagine'
import filtersReducer from './filters'
import equipmentsReducer from './equipments'
import normesReducer from './normes'

const userReducer = auth.reducer

const userWhiteList = [
  'account',
]

const stationsWhiteList = [
  'activeStation',
]

const tracksWhiteList = [
  'tracksList',
]

const annualServicesWhiteList = [
  'confirmedDates',
  // 'availableDates',
]

const equipmentsWhiteList = [
  'equipmentsList',
]

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
)

const saveStationsFilter = createFilter(
  'stations',
  stationsWhiteList,
)

const saveTracksFilter = createFilter(
  'tracks',
  tracksWhiteList,
)

const saveAnnualServicesFilter = createFilter(
  'annualServices',
  annualServicesWhiteList,
)

const saveEquipmentsFilter = createFilter(
  'equipments',
  equipmentsWhiteList,
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter, saveStationsFilter, saveTracksFilter, saveAnnualServicesFilter, saveEquipmentsFilter],
  whitelist: ['user', 'stations', 'annualServices'],
}

export const rootReducer = {
  user: userReducer,
  app: appReducer,
  stations: stationsReducer,
  tracks: tracksReducer,
  groix: groixReducer,
  annualServices: annualServicesReducer,
  metrics: metricsReducer,
  missingGov: missingGovReducer,
  missingPagine: missingPagineReducer,
  filters: filtersReducer,
  equipments: equipmentsReducer,
  normes: normesReducer,
}

const persistedRootReducer = persistCombineReducers(persistConfig, rootReducer)

export default persistedRootReducer
