import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateOriginDestinationFields, updateTimeRangeFields, updateTrainNumberFields, updateTrainNumberRange,
} from 'reducers/filters'
import { RootState } from 'Store'
import terms from 'common/terms'
import BalanceTypeFilter from './BalanceTypeFilter'
import CirculationTypeFilter from './CirculationTypeFilter'
import OriginDestinationFilter from './OriginDestinationFilter'
import TimeFilter from './TimeFilter'
import TracksFilter from './TracksFilter'
import EquipmentsFilter from './EquipmentsFilter'
import TrainNumberFilter from './TrainNumberFilter'
import TrainNumbersFilter from './TrainNumberRangeFilter'

export default function FiltersDialogContent(): ReactElement {
  const dispatch = useDispatch()
  const {
    trainNumberRange, trainsNumberFields, originDestinationFields, timeRangeFields,
  } = useSelector((state: RootState) => state.filters)
  const { fromGroix } = useSelector((state: RootState) => state.metrics)

  useEffect(() => {
    if (trainNumberRange.length === 0) {
      dispatch(updateTrainNumberRange([{
        index: 0,
        start: '',
        end: '',
      }]))
    }
    if (trainsNumberFields.length === 0) {
      dispatch(updateTrainNumberFields([{
        index: 0,
        operator: terms.Filters.equalTo,
        trainNumber: '',
      }]))
    }
    if (originDestinationFields.length === 0) {
      dispatch(updateOriginDestinationFields([{
        index: 0,
        origin: '',
        destination: '',
      }]))
    }
    if (timeRangeFields.length === 0) {
      dispatch(updateTimeRangeFields([{
        index: 0,
        startTime: null,
        endTime: null,
      }]))
    }
  }, [])

  return (
    <>
      {!fromGroix && <BalanceTypeFilter />}
      {!fromGroix && <CirculationTypeFilter />}
      <TimeFilter />
      <TracksFilter />
      <EquipmentsFilter />
      <TrainNumberFilter />
      <TrainNumbersFilter />
      <OriginDestinationFilter />
    </>
  )
}
