import terms from 'common/terms'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

type Props = {
    event: any;
    title: string;
}

const tooltipDescription = datum => {
  switch (datum) {
    case '0':
      return 'minute de retard'
      break
    case '+1':
      return 'minute de retard'
      break
    case '<0':
      return 'en avance'
      break
    default:
      return 'minutes de retard'
  }
}

export default function PieChatTooltip({ event, title }: Props): ReactElement {
  const { departPonctuality, arrivalPonctuality } = useSelector((state: RootState) => state.metrics)
  const totalTrainCount = title === terms.Ponctuality.departPieChartTitle ? departPonctuality : arrivalPonctuality
  return (
    <div
      style={{
        background: 'black',
        padding: '9px 12px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        opacity: '0.90',
        color: '#FFF',
        textAlign: 'center',
      }}
    >
      <div style={{
        fontSize: '16px',
        fontWeight: '900',
      }}
      >
        {event.datum.id}
      </div>
      <div style={{
        fontSize: '1rem',
        fontWeight: '400',
      }}
      >
        {tooltipDescription(event.datum.id)}
      </div>
      <div style={{
        fontSize: '1rem',
        fontWeight: '400',
        color: '#99B7F6',
      }}
      >
        {`${Math.round((event.datum.value * 100) / totalTrainCount.train_count.train_count_total)}% des circulations`}
      </div>
    </div>
  )
}
