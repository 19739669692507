import thunk from 'redux-thunk'
import { persistStore } from 'redux-persist'
import persistedReducer from 'reducers'
import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== 'production',
})

const persistor = persistStore(store)

export {
  store,
  persistor,
}

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
