import { RootState } from 'Store'
import { useSelector } from 'react-redux'

/* eslint-disable react/prop-types */
const PointLayer = props => {
  const { selectedPoint } = useSelector((state: RootState) => state.normes)

  const { points } = props

  const dataTodisplay = points.filter(point => point.data.x === selectedPoint.date)
  return (
    <g>
      {dataTodisplay.map(point => (
        <circle
          key={point.id}
          r={5}
          cx={point.x}
          cy={point.y}
          fill={point.serieColor}
          stroke={point.borderColor}
          strokeWidth={2}
        />
      ))}
    </g>
  )
}

export default PointLayer
