import { ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import { auth } from '@osrdata/app_core'
import { Dropdown } from 'react-bootstrap'
import { FaPowerOff } from 'react-icons/fa'
import Typography from '@mui/material/Typography'

export default function BtUser(): ReactElement {
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user)
  const logout = () => {
    dispatch(auth.logout())
  }

  return (
    <ul className="mastheader-toolbar toolbar mb-0">
      <li className="toolbar-item separator-gray-500">
        <Dropdown>
          <Dropdown.Toggle variant="transparent">
            <i className="icons-menu-account icons-size-1x25 icons-md-size-1x5 mr-xl-2" aria-hidden="true" />
            <Typography sx={{ fontFamily: 'Avenir', fontSize: '13px', color: 'white' }}>
              {user.account.firstName}
              {' '}
              {user.account.lastName}
            </Typography>
            <i className="icons-arrow-down d-none d-xl-block" aria-hidden="true" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={logout} href="#">
              <span className="mr-2"><FaPowerOff /></span>
              {terms.TopBar.disconnect}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>
    </ul>
  )
}
