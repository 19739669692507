import {
  Grid, Paper, Stack, Typography,
} from '@mui/material'
import { ReactElement } from 'react'
import InfoToolTip from '../Common/InfoToolTip'

type Props = {
    title: string;
    data: any;
    infoTitle: string;
    infoTooltip: string;
}

export default function PieChartInfo({
  title, data, infoTitle, infoTooltip,
}: Props): ReactElement {
  return (
    <Grid container item xs={5} sx={{ justifyContent: 'flex-end' }}>
      <Paper
        sx={{
          background: 'rgba(192, 207, 237, 0.17)',
          padding: '12px 12px',
          width: '300px',
          marginLeft: '3rem',
          marginBottom: '3rem',
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: '400',
                color: '#8F8F8F',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              {infoTitle}
              <InfoToolTip
                text={infoTooltip}
                position="position-relative"
                placement="bottom-end"
              />
            </Typography>
          </Grid>
          <Grid item container xs={12} sx={{ justifyContent: 'center' }}>
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: '900',
                  color: '#4A81F0',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                {data.train_count.train_count_on_time}
              &nbsp;
              </Typography>
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: '400',
                  color: '#4A4A4A',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                {' '}
                sur
                {' '}
                <b>
                  {data.train_count.train_count_total}
                </b>
                {' '}
                circulations
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} container sx={{ justifyContent: 'center' }}>
            <Paper
              sx={{
                background: '#4B80F0',
                padding: '3px',
                width: '90px',
                mt: '5px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '900',
                  color: 'white',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                {data.train_count.ponctuality_percentage}
                %
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
