import {
  FormControl, Grid, MenuItem, Select,
} from '@mui/material'
import { ReactElement } from 'react'
import AggregationCategories from '../types'

type Props = {
    comparaisonType: string;
    handleChangeComparaisonType: (event) => void;
    results: any;
    aggregationCategory: string;
    handleAggregationCategory: (event) => void;
}

export default function TracksUsageSelect({
  comparaisonType, handleChangeComparaisonType, results, aggregationCategory, handleAggregationCategory,
}: Props): ReactElement {
  return (
    <Grid item className="ml-auto" data-html2canvas-ignore>
      <FormControl sx={{ m: 1, minWidth: 260 }} className="ml-auto">
        <Select
          value={comparaisonType}
          label="Utilisation des voies"
          onChange={handleChangeComparaisonType}
          className="detail-variation-select"
        >
          {results ? (results[0].payload.map((a, i:number) => (
            <MenuItem key={Object?.keys(results[0]?.payload[i])[0]} value={Object?.keys(results[0]?.payload[i])[0]}>
              {Object?.keys(results[0]?.payload[i])[0]}
            </MenuItem>
          ))) : <></>}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 260 }} className="ml-auto">
        <Select
          value={aggregationCategory}
          label="Type d'aggregation"
          onChange={handleAggregationCategory}
          className="detail-variation-select"
        >
          <MenuItem value={AggregationCategories.TotalTrains}>{AggregationCategories.TotalTrains}</MenuItem>
          <MenuItem value={AggregationCategories.TotalOccupation}>{AggregationCategories.TotalOccupation}</MenuItem>
          <MenuItem value={AggregationCategories.MediumOccupation}>{AggregationCategories.MediumOccupation}</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  )
}
