import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import GovService from './services/govService'
import { GovsList } from './types'

interface MissingGovState {
    displayMissingGovNotice: boolean;
    displayIconNotice: boolean;
    missingGovs?: any;
    govs: GovsList[];
    hasNoGovs: boolean;
  }

const initialState: MissingGovState = {
  displayMissingGovNotice: false,
  displayIconNotice: false,
  govs: [],
  hasNoGovs: false,
}

export const missingGovSlice = createSlice({
  name: 'missingGov',
  initialState,

  reducers: {
    updateDisplayMissingGovNotice: (state, action: PayloadAction<boolean>) => {
      state.displayMissingGovNotice = action.payload
    },
    updateDisplayIconNotice: (state, action: PayloadAction<boolean>) => {
      state.displayIconNotice = action.payload
    },
    updateMissingGovs: (state, action: PayloadAction<any>) => {
      state.govs = action.payload
    },
    updateHasNoGovs: (state, action: PayloadAction<boolean>) => {
      state.hasNoGovs = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(GovService.getGovs.fulfilled, (state, action) => {
      state.govs.push(action.payload)
    })
    builder.addCase(GovService.updateGovs.fulfilled, (state, action) => {
      state.govs = state.govs.map(gov => (gov.comparaisonType === action.payload.comparaisonType
        ? action.payload : gov))
    })
  },
})

export const {
  updateDisplayMissingGovNotice, updateDisplayIconNotice, updateMissingGovs, updateHasNoGovs,
} = missingGovSlice.actions

export default missingGovSlice.reducer
