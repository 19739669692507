import { ReactElement } from 'react'
import { Chip, Typography } from '@mui/material'

import './filter.scss'

type Props = {
    title: string;
    data: string[];
    handleClick: (v: string) => void;
    selectedValues: string[];
}

export default function FiltersChips({
  title, data, handleClick, selectedValues,
}: Props): ReactElement {
  return (
    <>
      <Typography className="inputs-title">
        {title}
      </Typography>
      {data.map(item => (
        <Chip
          key={item}
          className={`filters-chips ${selectedValues?.filter(value => value === item)?.length !== 0
            ? 'selected-chips' : ''}`}
          label={item}
          variant="outlined"
          onClick={() => handleClick(item)}
        />
      ))}
    </>
  )
}
