/* eslint-disable react/jsx-no-bind */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable dot-notation */
/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import _ from 'lodash'
import Loading from 'components/Common/Backdrop/Backdrop'
import { setGovVariationInitialState } from 'reducers/metrics'
import metricsService from 'reducers/services/metricsService'
import nextId from 'react-id-generator'
import MissingGovAlert from 'components/MissingGov/MissingGovAlert'
import terms from 'common/terms'
import MissingData from '../MissingData'
import GovVariationFrame from './GovVariationFrame'
import '../station.scss'

interface TabPanelProps {
  index: number;
  value: number;
}

export default function GovVariation(props: TabPanelProps): ReactElement {
  const {
    value, index, ...other
  } = props
  const dispatch = useDispatch()
  const {
    metricsList, govVariation, loadingGovVariation, fromGroix, filters,
  } = useSelector((state: RootState) => state.metrics)

  useEffect(() => {
    dispatch(setGovVariationInitialState())
    const result = metricsList?.metrics?.filter(obj => obj?.board === 'Variation de GOV')
    if (value === index) {
      if (result) {
        result.forEach((element, indexx) => {
          dispatch(metricsService.getGovVariation({
            uri: element?.uri.substring(result[0].uri.indexOf('/usage_reseau')),
            gov_a: element?.payload?.gov_a.toString(),
            gov_b: element?.payload?.gov_b.toString(),
            type_gov_a: element?.payload?.type_gov_a,
            type_gov_b: element?.payload?.type_gov_b,
            title: element?.title,
            order: indexx,
            filters,
          }))
        })
      }
    }
  }, [metricsList, filters, value])

  let myData = [].concat(govVariation)
  myData = _.orderBy(myData, item => item?.meta?.arg?.order, ['asc'])

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tab--${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className="container-fluid" style={{ height: 'calc(100vh - 170px)' }}>
            <div className="row h-100 flex-nowrap overflow-auto">
              {myData?.map(gv => (
                <div
                  key={nextId()}
                  style={{ minWidth: '30vw', maxHeight: 'calc(100vh - 130px)', overflow: 'auto' }}
                  className={`col-4 border-right ${gv?.payload.status ? 'bg-missing-data' : 'bg-white'}`}
                >
                  {(gv?.payload['pie-chart'] && gv?.payload['pie-chart'].length !== 0) && (
                  <GovVariationFrame gv={gv} />
                  )}
                  {gv?.payload?.status && (
                  <MissingData message={gv?.payload?.message?.detail} title={gv?.meta?.arg?.title} />
                  )}
                  {filters && gv?.payload['pie-chart'] && gv?.payload['pie-chart'].length === 0 && (
                  <MissingData message={terms.Filters.noResultNotice} title={gv?.meta?.arg?.title} />
                  )}
                </div>
              ))}
              {metricsList.length !== 0 && metricsList?.missing_days?.length !== 0 && !fromGroix && <MissingGovAlert />}
            </div>
          </div>
        )}
      </div>
      <Loading open={loadingGovVariation} />
    </>
  )
}
