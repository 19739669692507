/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateMissingGovs } from 'reducers/missingGov'
import { updateMissingPagines } from 'reducers/missingPagine'
import terms from 'common/terms'
import TopBar from 'components/Common/TopBar/TopBar'
import history from 'customHistory'
import GroixDrop from 'components/GroixImport/GroixDrop'
import { customButton } from 'common/CustomTheme'
import { ThemeProvider } from '@mui/material/styles'
import Fade from '@mui/material/Fade'
import uploadIcon from 'assets/icons/uploadfiles.png'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import PathNames from 'common/PathNames'
import GroixUploaded from './GroixUploaded'

export default function GroixImport(): ReactElement {
  const dispatch = useDispatch()
  const { uploadedList } = useSelector((state: RootState) => state.groix)

  const handleClick = () => {
    history.push(PathNames.Metrics)
  }

  useEffect(() => {
    dispatch(updateMissingGovs([]))
    dispatch(updateMissingPagines([]))
  }, [])

  return (
    <main className="mastcontainer pl-0">
      <Fade timeout={400} in mountOnEnter unmountOnExit>
        <Container maxWidth={false} disableGutters sx={{ background: '#e8f4fc', height: 'calc(100vh - 70px)' }}>
          <TopBar appName={terms.common.appName} goHomeIcon />
          <Grid
            container
            spacing={2}
            style={{
              textAlign: 'center', alignItems: 'center', flexDirection: 'column', background: '#e8f4fc', marginTop: 0,
            }}
          >
            <Grid item xs={10} style={{ marginTop: '45px' }}>
              <img src={uploadIcon} alt="" width={85} />
            </Grid>
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontWeight: '900',
                  color: '#44547F',
                  fontSize: '28px',
                }}
              >
                {terms.ImportGroix.ImportGroixTitle}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography
                sx={{
                  color: '#44547F',
                  fontSize: '24px',
                }}
              >
                {terms.ImportGroix.ImportGroixSubtitle}
              </Typography>
            </Grid>
            <Grid item xs={10} lg={8} xl={6} style={{ marginTop: '40px', width: '100%' }}>
              <GroixDrop />
            </Grid>
            <Grid item xs={10} lg={8} xl={6} style={{ width: '100%', paddingTop: '10px' }}>
              <GroixUploaded />
            </Grid>
            <Grid item xs={10} lg={8} xl={6}>
              <ThemeProvider theme={customButton}>
                <LoadingButton
                  onClick={() => handleClick()}
                  variant="outlined"
                  disableElevation
                  disabled={uploadedList.some(item => item.statut !== 'Insertion terminée') || uploadedList.length < 2}
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    width: '400px',
                    paddingY: '15px',
                    borderRadius: '30px',
                    borderColor: '#61B8FA',
                    fontWeight: '900',
                    color: 'white',
                    marginBottom: '3rem',
                    background: '#61B8FA',
                  }}
                >
                  {terms.ImportGroix.button.continue}
                  {uploadedList.length < 2 || uploadedList.some(item => item.statut !== 'Insertion terminée') ? '' : ` avec ${uploadedList.length} fichier` }
                  {uploadedList.length > 1 ? 's' : ''}
                </LoadingButton>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Container>
      </Fade>
    </main>
  )
}
