import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { removeItem } from 'reducers/groix'

type Props = {
  groixIndex: number;
}

export default function GroixRemove({ groixIndex }: Props): ReactElement {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(removeItem(groixIndex))
  }

  return (
    <div style={{ marginLeft: 'auto' }}>
      <IconButton
        aria-label="delete"
        onClick={() => handleClick()}
      >
        <DeleteIcon sx={{ fontSize: '30px', color: 'red' }} />
      </IconButton>
    </div>
  )
}
