import { get, patch } from '@osrdata/app_core/dist/requests'
import { ThunkApi } from 'types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { MissingGovsParams, GovsList, Gov } from 'reducers/types'

const getGovs = createAsyncThunk<GovsList, MissingGovsParams, ThunkApi>(
  'gov/getGovs',
  async (params, thunkApi) => {
    try {
      const govs = await get('/gov/govs/', { ids: params.ids.toString() })
      return { govs, comparaisonType: params.comparaisontype }
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const updateGovs = createAsyncThunk<GovsList, MissingGovsParams, ThunkApi>(
  'gov/updateGovs',
  async (params, thunkApi) => {
    try {
      const govs = await patch('/gov/govs/', { ids: params.ids })
      return { govs, comparaisonType: params.comparaisontype }
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GovService = {
  getGovs,
  updateGovs,
}

export default GovService
