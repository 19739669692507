/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
import { ReactElement } from 'react'
import {
  addMonths, format, getMonth, subMonths,
} from 'date-fns'
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded'
import IconButton from '@mui/material/IconButton'
import './stylesheets/datepicker.scss'

type Props = {
  customHeaderCount: number;
  increaseMonth: Function;
  setLeftMonth: Function;
  monthDate: Date;
  nextMonthButtonDisabled: boolean;
  maxDate: Date;
}

export default function HeaderArrowRight({
  customHeaderCount, increaseMonth, setLeftMonth, monthDate,
  nextMonthButtonDisabled, maxDate,
}: Props): ReactElement {
  return (
    <IconButton
      onClick={() => {
        increaseMonth()
        setLeftMonth(customHeaderCount === 0
          ? getMonth(addMonths(monthDate, 1))
          : getMonth(monthDate))
      }}
      disabled={
                  customHeaderCount === 0
                    ? nextMonthButtonDisabled || format(new Date(monthDate), 'yyyy-MM') === format(new Date(subMonths(maxDate, 1)), 'yyyy-MM')
                    : nextMonthButtonDisabled || format(new Date(monthDate), 'yyyy-MM') === format(new Date(maxDate), 'yyyy-MM')
                }
      sx={{
        fontSize: 30,
        height: 40,
        width: 40,
        position: 'absolute',
        right: -15,
        top: 140,
        color: '#1976d2',
        '&.Mui-disabled': {
          color: '#d3d3d3',
        },
      }}
      style={customHeaderCount === 0 ? { visibility: 'hidden' } : null}
    >
      <ArrowCircleRightRoundedIcon
        fontSize="inherit"
      />
    </IconButton>
  )
}
