import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  OriginDestination, TimeRange, TrainNumberField, TrainNumberRange,
} from './types'

interface FiltersState {
    selectedBalanceType: string[];
    selectedCirculationType: string[];
    selectedTracks: string[];
    selectedEquipments: string[];
    trainNumberRange: TrainNumberRange[];
    trainsRange: string[];
    trainsNumberFields: TrainNumberField[];
    trainsNumber: string[];
    originDestinationFields: OriginDestination[];
    originDestination: string[];
    timeRangeFields: TimeRange[];
    timeRange: string[];
    displayRequiredNotice: boolean;
  }
const initialState: FiltersState = {
  selectedBalanceType: [],
  selectedCirculationType: [],
  selectedTracks: [],
  selectedEquipments: [],
  trainNumberRange: [],
  trainsNumberFields: [],
  trainsRange: [],
  trainsNumber: [],
  originDestinationFields: [],
  originDestination: [],
  timeRangeFields: [],
  timeRange: [],
  displayRequiredNotice: false,
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,

  reducers: {
    reset: () => initialState,
    updateSelectedBalanceType: (state, action: PayloadAction<string[]>) => {
      state.selectedBalanceType = action.payload
    },
    updateSelectedCirculationType: (state, action: PayloadAction<string[]>) => {
      state.selectedCirculationType = action.payload
    },
    updateSelectedTracks: (state, action: PayloadAction<string[]>) => {
      state.selectedTracks = action.payload
    },
    updateSelectedEquipments: (state, action: PayloadAction<string[]>) => {
      state.selectedEquipments = action.payload
    },
    updateTrainNumberRange: (state, action: PayloadAction<TrainNumberRange[]>) => {
      state.trainNumberRange = action.payload
    },
    updateTrainsRange: (state, action: PayloadAction<string[]>) => {
      state.trainsRange = action.payload
    },
    updateTrainsNumber: (state, action: PayloadAction<string[]>) => {
      state.trainsNumber = action.payload
    },
    updateTrainNumberFields: (state, action: PayloadAction<TrainNumberField[]>) => {
      state.trainsNumberFields = action.payload
    },
    updateOriginDestinationFields: (state, action: PayloadAction<OriginDestination[]>) => {
      state.originDestinationFields = action.payload
    },
    updateOriginDestion: (state, action: PayloadAction<string[]>) => {
      state.originDestination = action.payload
    },
    updateTimeRangeFields: (state, action: PayloadAction<TimeRange[]>) => {
      state.timeRangeFields = action.payload
    },
    updateTimeRange: (state, action: PayloadAction<string[]>) => {
      state.timeRange = action.payload
    },
    toggleDisplayRequiredNotice: (state, action: PayloadAction<boolean>) => {
      state.displayRequiredNotice = action.payload
    },
  },

})

export const {
  updateSelectedBalanceType, updateSelectedCirculationType, updateSelectedTracks,
  updateSelectedEquipments, updateTrainNumberRange, updateTrainsRange,
  updateTrainsNumber, updateTrainNumberFields, updateOriginDestinationFields, updateOriginDestion,
  updateTimeRangeFields, updateTimeRange, reset, toggleDisplayRequiredNotice,
} = filtersSlice.actions

export default filtersSlice.reducer
