import {
  InputBase, MenuItem, Select, SelectChangeEvent, styled,
} from '@mui/material'
import { ReactElement } from 'react'
import { GOVMetrics } from 'reducers/services/normesService'
import { v4 as uuidV4 } from 'uuid'

interface Props {
  value: string;
  options: string[];
  size?: 'small' | 'medium';
  handleChange: (event: SelectChangeEvent) => void;
}

const StyleInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #e6eaee',
    fontSize: 14,
    padding: '7px 26px 9px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    width: '210px',
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 10,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))

const SelectGOV = ({
  value, options, size, handleChange,
}: Props): ReactElement => (
  <Select
    size={size}
    variant="outlined"
    value={value}
    onChange={handleChange}
    input={<StyleInput />}
  >
    { options.map(i => (
      <MenuItem key={uuidV4()} value={i} disabled={i === GOVMetrics.REEL}>
        {i}
      </MenuItem>
    ))}
  </Select>
)

SelectGOV.defaultProps = {
  size: 'small',
}

export default SelectGOV
