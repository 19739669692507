import { ReactElement } from 'react'
import './style.scss'

interface Props {
  date: string;
  data: {
    id: string;
    result: number;
  }[];
  hoveredId: string;
}

const TooltipLine = ({ date, data, hoveredId }: Props): ReactElement => (
  <div className="custom-tooltip">
    <div className="label">
      {date}
    </div>
    <div className="reocupation-container">
      {
        data.map(item => (
          <div
            key={item.id}
            className={`tooltip-line ${item.id === hoveredId ? 'bold' : ''}`}
          >
            <span>
              {`${item.id}:`}
            </span>
            <span>
              {item.result}
            </span>
          </div>
        ))
      }
    </div>

  </div>
)

export default TooltipLine
