import { createTheme, styled } from '@mui/material/styles'
import Button, { ButtonProps } from '@mui/material/Button'

export const customButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderColor: '#4A81F0',
          backgroundColor: '#4A81F0',
          '&:hover': {
            backgroundColor: '#4A81F0',
          },
          '&:focus': {
            borderColor: '#4A81F0',
          },
          '&:disabled': {
            color: 'white',
            backgroundColor: '#61B8FA',
            opacity: '.2',
          },
        },
      },
    },
  },
})

export const customDarkButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderColor: 'white',
          backgroundColor: '#022e4f',
          '&:hover': {
            backgroundColor: '#a1a9bf',
          },
          '&:focus': {
            borderColor: '#022e4f',
          },
          '&:disabled': {
            color: '#022e4f',
            backgroundColor: 'rgba(255, 255, 255, .2)',
          },
        },
      },
    },
  },
})

export const customWhiteButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderColor: 'white',
          color: '#022e4f',
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: '#a1a9bf',
          },
          '&:focus': {
            borderColor: '#022e4f',
          },
          '&:disabled': {
            color: '#022e4f',
            backgroundColor: 'rgba(255, 255, 255, .2)',
          },
        },
      },
    },
  },
})

export const tabsMenu = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          minHeight: '40px',
          color: '#414141',
          border: '1px solid',
          borderColor: '#E6EAEE',
          borderRadius: '8px',
          marginInline: '5px',
          backgroundColor: 'white',
          fontSize: '12px',
          height: '40px',
          padding: '10px',
          '&:hover': {
            color: 'white',
            backgroundColor: '#4B80F0',
          },
          '&:focus': {
            color: 'white',
            backgroundColor: '#4B80F0',
            outline: 'none',
          },
          '&:selected': {
            color: 'red',
            backgroundColor: 'red',
          },
        },
      },
    },
  },
})

export const filterButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          indicator: {
            background: 'none', // ça ne marche pas
          },
          height: '40px',
          color: '#414141',
          border: '1px solid',
          fontSize: '13px',
          padding: '13px',
          borderColor: '#E6EAEE',
          borderRadius: '8px',
          marginInline: '10px',
          backgroundColor: 'white',
          '&:hover': {
            color: 'white',
            backgroundColor: '#4B80F0',
          },
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
  },
})

export const ColorButton = styled(Button)<ButtonProps>(() => ({
  color: '#000000',
  backgroundColor: '#E8F0F9',
  textTransform: 'none',
  padding: '6px 16px',
  fontWeight: '400',
  fontSize: '1rem',
  marginLeft: '8px',
  borderRadius: '8px',
  height: '58px',
  '&:hover': {
    backgroundColor: '#4B80F0',
    color: '#ffffff',
  },
}))

export const OutlineButton = styled(Button)<ButtonProps>(() => ({
  color: 'rgba(0, 0, 0, 0.87)',
  backgroundColor: '#FFF',
  textTransform: 'none',
  padding: '6px 16px',
  fontWeight: '400',
  fontSize: '1rem',
  margin: '8px',
  borderRadius: '8px',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  height: '56px',
  '&:hover': {
    border: '1px solid #000',
    backgroundColor: '#FFF',
  },
}))

export const customIgnoreButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          border: '1px solid rgba(252, 81, 48, 0.2)',
          color: '#FC5130',
          backgroundColor: 'white',
          borderRadius: '30px',
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
  },
})

export const customImportButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          border: '1px solid rgba(252, 81, 48, 0.2)',
          color: 'white',
          backgroundColor: '#FC5130',
          borderRadius: '30px',
          '&:hover': {
            color: 'white',
            backgroundColor: '#FC5130',
          },
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
  },
})

export const customBackWardButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          border: 'none',
          color: '#272727',
          backgroundColor: '#e8f4fc',
          fontSize: '16px',
          fontWeight: 900,
          marginTop: '32px',
        },
      },
    },
  },
})

export const customReImportButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          position: 'absolute',
          boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.08), -2px -2px 7px rgba(0, 0, 0, 0.06)',
          color: 'white',
          backgroundColor: '#4A81F0',
          borderRadius: '30px',
          bottom: '5%',
          padding: '6px 25px',
          '&:hover': {
            color: '#4A81F0',
            backgroundColor: 'white',
          },
          '&:focus': {
            outline: 'none',
          },
          '&:disabled': {
            background: '#F2F6FE',
            color: '#272727',
          },
        },
      },
    },
  },
})

export const customResetFiltersButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          border: 'none',
          textDecorationLine: 'underline',
          fontWeight: '400',
          fontSize: '16px',
          color: '#272727',
          '&:hover': {
            backgroundColor: 'white',
            textDecorationLine: 'underline',
          },
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
  },
})

export const customApplyFiltersButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: 'white',
          borderRadius: '30px',
          padding: '6px 6px 6px 8px',
          backgroundColor: '#4B80F0',
          width: '100%',
          '&:hover': {
            color: '#4A81F0',
            backgroundColor: 'white',
            border: '1px solid #4A81F0',
          },
          '&:focus': {
            color: 'white',
            backgroundColor: '#4B80F0',
            outline: 'none',
          },
        },
      },
    },
  },
})

export default customButton
