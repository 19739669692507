/* eslint-disable max-len */
import { ReactElement, useState } from 'react'
import nextId from 'react-id-generator'
import Table from '@mui/material/Table'
import TablePagination from '@mui/material/TablePagination'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import terms from 'common/terms'
import InfoHover from '../Common/InfoHover'

type Props = {
    detailVariation: any;
    tableRef: any;
    createSortHandlerA: any;
    createSortHandlerB: any;
}

export default function TableOfTable({
  detailVariation, tableRef, createSortHandlerA, createSortHandlerB,
}: Props): ReactElement {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const cellCount = (e, cIndex) => {
    if (cIndex <= 6) {
      return e.slice(0, 7).filter(array => array.value === null).length === 7
    }
    return e.slice(7, 14).filter(array => array.value === null).length === 7
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    if (tableRef.current) {
      tableRef.current.scrollTo(0, 0)
    }
    setPage(newPage)
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: '60vh' }} ref={tableRef}>
        <Table stickyHeader aria-label="sticky table" className="border">
          <TableHead>
            <TableRow>
              {detailVariation['table-of-table']?.headers?.map(header => (
                <TableCell key={nextId()} className="table-header" align="center" colSpan={7}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {detailVariation['table-of-table']?.subheaders?.map(subHeaders => (
                <TableCell key={nextId()} align="center" className="table-subheader table-cell-border">
                  <TableSortLabel
                    active
                    onClick={() => createSortHandlerA(subHeaders)}
                    IconComponent={() => <UnfoldMoreIcon />}
                  >
                    {subHeaders}
                  </TableSortLabel>
                </TableCell>
              ))}
              {detailVariation['table-of-table']?.subheaders?.map(subHeaders => (
                <TableCell key={nextId()} align="center" className="table-subheader">
                  <TableSortLabel
                    active
                    onClick={() => createSortHandlerB(subHeaders)}
                    IconComponent={() => <UnfoldMoreIcon />}
                  >
                    {subHeaders}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {detailVariation['table-of-table']?.values
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(row => (
                <TableRow hover key={nextId()}>
                  {row?.map((column, cIndex, array) => (
                    <InfoHover
                      key={nextId()}
                      title={cIndex === 2 || cIndex === 9 ? (column.libelle || terms.VariationDetail.notSpecifiedLabel) : ''}
                    >
                      <TableCell
                        align="center"
                        className={`table-cell-border ${(column.value === null && cellCount(array, cIndex))
                          ? 'no-value only-one' : ''} ${column.value === null && !cellCount(array, cIndex)
                          ? 'only-one-cell' : ''}`}
                        colSpan={(column.value === null && cellCount(array, cIndex))
                          ? 7 : 1}
                      >
                        {column.value !== null ? <span className={`${column.is_modified ? 'modified' : ''}`}>{column.value}</span>
                          : <span>{cIndex > 6 ? terms.VariationDetail.deletedCirculation : terms.VariationDetail.notPlanifiedCirculation}</span>}
                      </TableCell>
                    </InfoHover>

                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {detailVariation['table-of-table']?.values?.length !== 0 && (
      <div className="mt-3">
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={detailVariation['table-of-table']?.values?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ count }) => `${page + 1} / ${Math.ceil(count / rowsPerPage)}`}
          sx={{
            '.MuiToolbar-root': {
              display: 'flex',
              justifyContent: 'center',
            },
            '.MuiTablePagination-spacer': {
              flex: 'none',
            },
            '.MuiTablePagination-displayedRows': {
              marginLeft: 'auto',
              paddingLeft: '6vw',
            },
            '.MuiTablePagination-selectLabel': {
              order: '3',
              marginLeft: 'auto',
            },
            '.MuiInputBase-root': {
              order: '4',
            },
            '.MuiButtonBase-root': {
              border: '1px solid #E6EAEE',
              borderRadius: '8px',
              marginLeft: '8px',
            },
            '.MuiTablePagination-select': {
              border: '1px solid #E6EAEE',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              minHeight: '32px !important',
            },
          }}
        />
      </div>
      )}
    </>
  )
}
