import { ReactElement, SyntheticEvent } from 'react'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { TabValues } from 'types'
import './TabsMenu.scss'

type Props = {
  tabValues: TabValues[];
  value: number;
  handleChange: (event: SyntheticEvent, newValue: number) => void;
}

export default function TabsMenu({
  tabValues, value, handleChange,
}: Props): ReactElement {
  function a11yProps(index: number) {
    return {
      id: `tab-menu-${index}`,
      'aria-controls': `tab-menu-panel-${index}`,
    }
  }

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      sx={{
        flexWrap: 'wrap',
      }}
    >
      {tabValues.map((tab: TabValues) => (
        <Tab
          key={tab.index}
          {...a11yProps(tab.index)}
          label={tab.label}
          icon={tab.icon}
          iconPosition={tab.iconPosition}
          className="tabs-menu"
        />
      ))}
    </Tabs>
  )
}
