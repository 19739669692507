import { TableBody, TableCell, TableRow } from '@mui/material'
import { ReactElement } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ReusabilityTable } from 'reducers/services/normesService'

interface Props {
  dataTable: ReusabilityTable;
}

const CustomTableBody = ({
  dataTable,
}: Props): ReactElement => (
  <TableBody>
    {
        dataTable.data.map(row => (
          <TableRow key={uuidv4()}>
            <TableCell>
              <span className="cell-libelle">
                {row.key}
              </span>
            </TableCell>
            {
              row.value.map(item => (
                <TableCell key={uuidv4()}>
                  <span>
                    {item.value}
                  </span>
                </TableCell>
              ))
            }
          </TableRow>
        ))
      }
  </TableBody>
)

export default CustomTableBody
