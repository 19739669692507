/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  format, getMonth, getYear, subMonths,
} from 'date-fns'
import range from 'lodash/range'
import { updateSelectedDates, updateMonthToShow } from 'reducers/annualServices'
import DatePicker, { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import Stack from '@mui/material/Stack'
import HeaderArrowLeft from './HeaderArrowLeft'
import HeaderArrowRight from './HeaderArrowRight'
import HeaderSelectYear from './HeaderSelectYear'
import HeaderSelectMonth from './HeaderSelectMonth'
import './stylesheets/datepicker.scss'

registerLocale('fr', fr)

export default function PickerDates(): ReactElement {
  const dispatch = useDispatch()
  const {
    selectedDates, availableDates, excludedDates, monthToShow,
  } = useSelector((state: RootState) => state.annualServices)

  const minDate = availableDates[0] ? availableDates[0] : undefined
  const maxDate = availableDates[0] ? availableDates[availableDates.length - 1] : undefined
  const highlightedDates = selectedDates[0] ? selectedDates.map(date => (new Date(date))) : undefined
  const openToDate = subMonths(Date.now(), 1)
  const minYear = getYear(minDate)
  const maxYear = getYear(maxDate)
  const years = range(minYear, maxYear + 1, 1)

  const [startDate, setStartDate] = useState(null)
  const [lastClickedSide, setLastClickedSide] = useState('left')
  const [leftMonth, setLeftMonth] = useState(getMonth(monthToShow || openToDate))

  const months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ]

  const confirmDates = date => {
    let array = selectedDates
    array = [...array, format(new Date(date), 'yyyy-MM-dd')]
    dispatch(updateSelectedDates(array))
    setStartDate(null)
  }

  return (
    <DatePicker
      renderCustomHeader={({
        monthDate,
        changeYear,
        changeMonth,
        customHeaderCount,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <Stack
          direction="row"
          sx={{
            width: '270px',
            pl: '20px',
            pr: '20px',
            height: '50px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <HeaderArrowLeft
            customHeaderCount={customHeaderCount}
            decreaseMonth={decreaseMonth}
            setLeftMonth={setLeftMonth}
            monthDate={monthDate}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            minDate={minDate}
          />
          <HeaderSelectMonth
            customHeaderCount={customHeaderCount}
            setLeftMonth={setLeftMonth}
            monthDate={monthDate}
            minDate={minDate}
            maxDate={maxDate}
            changeMonth={changeMonth}
            changeYear={changeYear}
            lastClickedSide={lastClickedSide}
            months={months}
            maxYear={maxYear}
          />
          <HeaderSelectYear
            customHeaderCount={customHeaderCount}
            setLeftMonth={setLeftMonth}
            monthDate={monthDate}
            minDate={minDate}
            maxDate={maxDate}
            changeMonth={changeMonth}
            changeYear={changeYear}
            lastClickedSide={lastClickedSide}
            years={years}
          />
          <HeaderArrowRight
            customHeaderCount={customHeaderCount}
            increaseMonth={increaseMonth}
            setLeftMonth={setLeftMonth}
            monthDate={monthDate}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
            maxDate={maxDate}
          />
        </Stack>
      )}
      locale="fr"
      inline
      isClearable
      disabledKeyboardNavigation
      monthsShown={2}
      minDate={minDate}
      maxDate={maxDate}
      onMonthChange={m => {
        dispatch(lastClickedSide === 'left' ? updateMonthToShow(m) : updateMonthToShow(subMonths(m, 1)))
      }}
      openToDate={monthToShow || openToDate}
      highlightDates={highlightedDates}
      excludeDates={excludedDates}
      onChange={date => {
        confirmDates(date)
        setLastClickedSide(getMonth(date) === leftMonth ? 'left' : 'right')
      }}
      selected={startDate}
    />
  )
}
