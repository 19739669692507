import { ReactElement } from 'react'
import warningIcon from 'assets/icons/warning.png'
import terms from 'common/terms'

type Props = {
  message: string;
  title?: string;
}

const defaultProps = {
  title: '',
}

export default function MissingData({ message, title }: Props): ReactElement {
  return (
    <div className="d-flex p-5 align-items-center justify-content-center h-100 flex-column text-center">
      <h1 className="main-title font-weight-bold align-self-start">{title}</h1>
      <div className="d-flex align-items-center justify-content-center h-100 flex-column text-center mt-3">
        <img src={warningIcon} alt="" width={80} />
        <h1 className="font-weight-bold my-5">{message}</h1>
        {message?.includes('Tranche') && <h3>{terms.Filters.trancheNotice}</h3>}
      </div>
    </div>
  )
}

MissingData.defaultProps = defaultProps
