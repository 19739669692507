import { ReactElement } from 'react'
import filterIcon from 'assets/images/filter-icon.svg'

export default function FilterIcon(): ReactElement {
  return (
    <div>
      <img src={filterIcon} alt="filter" height="20px" />
    </div>
  )
}
