/* eslint-disable max-len */
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import GroixService from 'reducers/services/groixService'
import terms from 'common/terms'
import { setError } from 'reducers/groix'
import { useDropzone } from 'react-dropzone'
import Loading from 'components/Common/Backdrop/Backdrop'
import Grid from '@mui/material/Grid'
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined'
import { ResponseError } from 'types'
import Paper from '@mui/material/Paper'
import ImportError from './ImportError'
import GroixList from './GroixList'

export default function GroixDrop(): ReactElement {
  const dispatch = useDispatch()
  const [file, setFile] = useState([])
  const {
    loading, error,
  } = useSelector((state: RootState) => state.groix)

  const setDisabled = () => true

  const errorFound = error !== undefined

  const dropRejected: ResponseError = {
    status: 'dropRejected',
    message: terms.ImportGroix.dropRejectedMsg,
  }

  const dropError: ResponseError = {
    status: 'dropError',
    message: terms.ImportGroix.dropErrorMsg,
  }

  const errorMessage = () => {
    switch (true) {
      case (error === undefined):
        return ''
        break
      case (error.status === 'dropRejected'):
        return dropRejected.message
        break
      case (error.status === 'dropError'):
        return dropError.message
        break
      case (error.status === 400):
        return error.message.detail
        break
      case (error.status === 401):
        return error.message
        break
      default:
        return null
    }
  }

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/vnd.ms-excel.sheet.macroenabled.12': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls'],
      'application/vnd.ms-excel': ['.xls'],
    },
    onDropAccepted: acceptedFile => {
      setFile(acceptedFile)
      const formData = new FormData()
      formData.append('fichier', acceptedFile[0])
      const object = {
        formData,
      }
      dispatch(GroixService.uploadGroix(object))
    },
    onDropRejected: e => {
      dispatch(setError(dropRejected))
    },
    onError: () => {
      dispatch(setError(dropError))
    },
  })

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          p: '30px',
          border: '1px solid #61B8FA',
          borderRadius: '8px',
          opacity: setDisabled() ? '1' : '.5',
          width: '100%',
          boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.08), -2px -2px 7px rgba(0, 0, 0, 0.06)',
          display: 'flex',
          alignItems: 'center',
          height: '300px',
        }}
      >
        <Grid item xs={6} {...getRootProps()} sx={{ justifyContent: 'center', width: '100%', height: '100%' }}>
          <input {...getInputProps()} />
          <p className={setDisabled() ? 'drop-zone-light pointer' : 'drop-zone-light'} style={{ height: '100%' }}>
            <FileUploadIcon sx={{ fontSize: '70px', color: '#44547F' }} />
            {terms.ImportGroix.fileSelection}
          </p>
        </Grid>
        <Grid item xs={6} sx={{ width: '100%', height: '100%' }}>
          {(setDisabled() && <GroixList />)}
        </Grid>
        <Loading open={loading} />
        <ImportError message={errorMessage()} error={error} file={file} />
      </Paper>
    </>
  )
}
