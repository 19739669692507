export const PathNames = {
  Home: '/',
  Metrics: '/metriques-gare',
  Normes: '/normes-gare',
  OptionChoice: '/gov-choix-option',
  DateChoice: '/gov-choix-date',
  FileImport: '/gov-import-fichier',
  TrigramConfig: '/configuration-trigramme',
  EquipmentConfig: '/configuration-materiel',
  ImageConfig: '/configuration-image',
  ImagePreviz: '/configuration-image-previsualisation',
  TrackConfig: '/configuration-voie',
  MissingGov: '/gov-manquant',
  REOption: '/re-option',
  MissingGovNormes: '/normes-gare/gov-manquant',
}

export default PathNames
