import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { frFR } from '@mui/material/locale'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { store, persistor } from './Store'
import * as serviceWorker from './serviceWorker'
import App from './App'
import './index.css'
import 'assets/css/constants.scss'

import 'moment/locale/fr'

import '@sncf/bootstrap-sncf.metier/dist/bootstrap-sncf.min.css'

const theme = createTheme(
  {
    typography: {
      fontFamily: 'Avenir',
    },
  },
  frFR,
)

require('@sncf/bootstrap-sncf.metier')

Sentry.init({
  dsn: 'https://3fcd4e2cc9ba4badacfa0175de669af5@sentry.shared.dgexsol.fr/42',
  environment: process.env.REACT_APP_REALM,
  integrations: [new BrowserTracing()],
  sampleRate: 1.0,
  tracesSampleRate: 0.01,
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
