import { ReactElement } from 'react'
import Container from '@mui/material/Container'
import terms from 'common/terms'
import TopBar from 'components/Common/TopBar/TopBar'
import Grid from '@mui/material/Grid'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import CustomDatePicker from 'components/Common/DatePicker'

export default function GovComparation(): ReactElement {
  return (
    <>
      <TopBar appName={terms.common.appName} goHomeIcon />
      <main className="mastcontainer pl-0">
        <Fade timeout={400} in mountOnEnter unmountOnExit>
          <Container maxWidth={false} disableGutters sx={{ background: '#e8f4fc', height: 'calc(100vh - 70px)' }}>
            <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
              <Grid container sx={{ justifyContent: 'center' }} item xs={12}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    mt: '68px', fontWeight: 'bold', color: '#44547F', mb: '40px',
                  }}
                >
                  {terms.DatePicker.chooseDatesTitle}
                </Typography>
              </Grid>
              <Grid container sx={{ justifyContent: 'center', background: '#e8f4fc' }} item xs={12}>
                <CustomDatePicker />
              </Grid>
            </Grid>
          </Container>
        </Fade>
      </main>
    </>
  )
}
