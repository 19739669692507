import { ReactElement } from 'react'
import './style.scss'
import terms from 'common/terms'
import { formatDate } from '../../function'

interface Props {
  date: string;
  data: {
    id: string;
    result: number;
  }[];
  hoveredId: string;
}

const TooltipLine = ({ date, data, hoveredId }: Props): ReactElement => (
  <div className="custom-tooltip">
    <div className="label">
      {formatDate(date)}
    </div>
    <div className="conflict-container">
      {
        data.map(item => (
          <div
            key={item.id}
            className={`tooltip-line ${item.id === hoveredId ? 'bold' : ''}`}
          >
            <span>
              {item.result}
            </span>
            <span>
              {terms.NormesRE.ConflictsGov.ConflictsEvolution.conflict(item.result)}
            </span>
            <span>
              {item.id}
            </span>
          </div>
        ))
      }
    </div>

  </div>
)

export default TooltipLine
