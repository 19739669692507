import { Grid } from '@mui/material'
import { ReactElement } from 'react'
import terms from 'common/terms'
import InfoToolTip from '../Common/InfoToolTip'

export default function Legend(): ReactElement {
  return (
    <Grid item>
      <h1 className="main-title font-weight-bold">
        {terms.TracksUsage.tracksUsageTitle}
        <InfoToolTip
          text={terms.TracksUsage.tootlTip}
          position="position-relative"
          placement="bottom-end"
          data-html2canvas-ignore
        />
      </h1>
      <h4 className="sub-title">{terms.TracksUsage.tracksUsageSubtitle}</h4>
    </Grid>
  )
}
