/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { useScreenshot, createFileName } from 'use-react-screenshot'
import _ from 'lodash'
import { format } from 'date-fns'
import Box from '@mui/material/Box'
import GovVariationLegend from './GovVariationLegend'
import GovVariationPieChart from './GovVariationPieChart'
import GovVariationTitle from './GovVariationTitle'
import '../station.scss'

  type Props = {
    gv: any;
  }

export default function GovVariationFrame({ gv }: Props): ReactElement {
  const { fromGroix } = useSelector((state: RootState) => state.metrics)
  const { activeStation } = useSelector((state: RootState) => state.stations)
  const ref = useRef()
  const [image, takeScreenshot] = useScreenshot()
  const download = (image,
    {
      name = `${activeStation.abv_traction}${fromGroix ? ''
        : ` - ${format(new Date(Date.now()), 'dd-MM-yyyy')}`}`, extension = 'jpeg',
    } = {}) => {
    const a = document.createElement('a')
    a.href = image
    a.download = createFileName(extension, name)
    a.click()
  }

  function getImage() {
    takeScreenshot(ref.current).then(download)
  }

  return (
    <Box sx={{ alignItems: 'flex-start', padding: 5 }} ref={ref}>
      <GovVariationTitle gv={gv} getImage={getImage} />
      <GovVariationPieChart gv={gv} />
      <GovVariationLegend gv={gv} />
    </Box>
  )
}
