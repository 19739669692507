/* eslint-disable max-len */
import {
  get, patch, post, deleteRequest,
} from '@osrdata/app_core/dist/requests'
import { ThunkApi } from 'types'
import { createAsyncThunk } from '@reduxjs/toolkit'

type GetEquipmentsParams = any

const getEquipmentsList = createAsyncThunk<any, GetEquipmentsParams, ThunkApi>(
  'equipments/getEquipmentsList',
  async (params: GetEquipmentsParams, thunkApi) => {
    try {
      const equipmentsList = await get(`/gov/gares/${params.id}/codes_materiel/`)
      return equipmentsList
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const addEquipment = createAsyncThunk<any, any, ThunkApi>(
  'equipments/addEquipment',
  async (object: any, thunkApi) => {
    try {
      const response: any = await post(`/gov/gares/${object.stationId}/codes_materiel/`, object.newEquipment)
      return response
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data.detail,
        status: e.response.status,
      })
    }
  },
)

const patchEquipment = createAsyncThunk<any, any, ThunkApi>(
  'equipments/updateEquipment',
  async (object: any, thunkApi) => {
    try {
      const response: any = await patch(`/gov/gares/${object.stationId}/codes_materiel/${object.id}/`, object.patchEquipment)
      return response
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data.detail,
        status: e.response.status,
      })
    }
  },
)

const deleteEquipment = createAsyncThunk<any, any, ThunkApi>(
  'equipments/deleteEquipment',
  async (object: any, thunkApi) => {
    try {
      const response: any = await deleteRequest(`/gov/gares/${object.stationId}/codes_materiel/${object.id}`)
      return response
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const EquipmentsService = {
  getEquipmentsList,
  addEquipment,
  patchEquipment,
  deleteEquipment,
}

export default EquipmentsService
