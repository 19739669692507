import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from './types'

const initialState: AppState = {
  error: null,
  fullscreen: false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleFullscreen: state => {
      state.fullscreen = !state.fullscreen
    },
    getError: (state, action: PayloadAction<null>) => {
      state.error = action.payload
    },
  },
})

export const { toggleFullscreen, getError } = appSlice.actions

export default appSlice.reducer
