import { DatumValue, Point } from '@nivo/line'
import { ReactElement } from 'react'
import { v4 as uuidV4 } from 'uuid'

type Props = {
  slice: {
    id: DatumValue;
    height: number;
    width: number;
    x0: number;
    x: number;
    y0: number;
    y: number;
    points: Point[];
};
}

export default function LineChatTooltip({ slice }: Props): ReactElement {
  return (
    <div
      style={{
        background: 'black',
        padding: '9px 12px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        opacity: '0.90',
        color: '#FFF',
        textAlign: 'center',
      }}
    >
      {
        slice.points.map(point => (
          <div key={uuidV4()}>
            <div style={{
              fontSize: '1rem',
              fontWeight: '900',
            }}
            >
              {point.data.xFormatted}
            </div>
            <div>
              <span style={{
                fontSize: '1rem',
                fontWeight: '900',
              }}
              >
                {point.data.yFormatted}
                {' '}
                {' '}
              </span>
              <span style={{
                fontSize: '1rem',
                fontWeight: '400',
              }}
              >
                {point.serieId.toString().substr(point.serieId.toString().lastIndexOf('.') + 1)}

              </span>
            </div>
          </div>
        ))
      }

    </div>
  )
}
