import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSelectedTracks } from 'reducers/filters'
import { RootState } from 'Store'
import terms from 'common/terms'
import FiltersChips from './FiltersChips'
import BtSelectAll from './BtSelectAll'

export default function TracksFilter(): ReactElement {
  const dispatch = useDispatch()
  const { metricsList, filters } = useSelector((state: RootState) => state.metrics)
  const { selectedTracks } = useSelector((state: RootState) => state.filters)
  useEffect(() => {
    if (filters && selectedTracks.length === 0) {
      dispatch(updateSelectedTracks(filters.track))
    }
  }, [filters])

  const onTrackClick = (track: string) => {
    const tracksList: string[] = []
    if (selectedTracks.filter(type => type === track)?.length === 0) {
      tracksList.push(...selectedTracks, track)
    } else {
      tracksList.push(...selectedTracks.filter(type => type !== track))
    }
    dispatch(updateSelectedTracks(tracksList))
  }

  const selectAllTracks = () => {
    dispatch(updateSelectedTracks(metricsList?.track_list))
  }

  return (
    <>
      <FiltersChips
        title={terms.Filters.tracks}
        data={metricsList?.track_list}
        handleClick={onTrackClick}
        selectedValues={selectedTracks}
      />
      <BtSelectAll
        disabled={metricsList?.track_list.length === selectedTracks.length}
        btSelectAll={terms.Filters.btSelectAllTracks}
        handleClick={selectAllTracks}
      />
    </>
  )
}
