import { Typography } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Gov } from 'reducers/types'
import { RootState } from 'Store'
import ReactHtmlParser from 'react-html-parser'

type Props = {
  comparaisonType: string;
  govs: Gov[];
}

const ReImportNotice = ({ comparaisonType, govs }: Props): ReactElement => {
  const { missingDays } = useSelector((state: RootState) => state.normes)
  const { hasNoGovs } = useSelector((state: RootState) => state.missingGov)

  return (
    <Typography className="notice">
      {comparaisonType === 'Conception (COTECH GOV)' && (
        <>
          {ReactHtmlParser(terms.MissingGov.govConception)}
          {(govs?.length === 0 || hasNoGovs) && missingDays[comparaisonType].length !== 0 ? (
            <>
              {' '}
              {terms.MissingGov.conceptionGovAvailability}
            </>
          ) : '.'}
        </>
      )}
      {comparaisonType !== 'Conception (COTECH GOV)'
       && ((govs?.length === 0 || hasNoGovs) && missingDays[comparaisonType].length !== 0 ? (
         <>
           {terms.MissingGov.missingGovsPending}
           {' '}
           <strong>{terms.MissingGov.openGovTR}</strong>
           .
         </>
       ) : ReactHtmlParser(terms.MissingGov.missingGovsNorme))}
    </Typography>
  )
}

export default ReImportNotice
