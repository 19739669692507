import terms from 'common/terms'
import { ReactElement } from 'react'
import { v4 as uuidV4 } from 'uuid'

interface Props {
  info: {
    value: number;
    label: string;
  }[];
}

const BarChartHeader = ({ info }: Props): ReactElement => (
  <div className="info-header">
    {
      info.map(item => (
        <div key={uuidV4()} className={`item ${item.value === null ? 'null' : ''} `}>
          <div className="number">
            {item.value === null ? terms.NormesRE.Reusability.BarChart.header.noData : item.value}
          </div>
          <div className="libelle">
            {item.label}
          </div>
        </div>
      ))
    }
  </div>
)

export default BarChartHeader
