/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import terms from 'common/terms'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import StationsService from 'reducers/services/stationsService'
import { setStationOrdering } from 'reducers/stations'
import { RootState } from 'Store'

export default function OrderingMenu(): ReactElement {
  const dispatch = useDispatch()
  const { saveStatus, ordering, search } = useSelector((state: RootState) => state.stations)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    dispatch(StationsService.getStationsList({ ordering, search }))
  }, [ordering, saveStatus, search])

  const toggleChoiceAlphabetical = () => {
    dispatch(setStationOrdering('alphabetical'))
    setAnchorEl(null)
  }

  const toggleChoiceFavorite = () => {
    dispatch(setStationOrdering('favorite'))
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          fontSize: '12px', textAlign: 'right', textTransform: 'none', minWidth: 180, justifyContent: 'flex-start',
        }}
      >
        <span style={{ color: '#868686' }}>
          {terms.common.sortBy}
        </span>
        {' '}
        <span style={{ color: '#43454A', paddingLeft: '3px' }}>{(ordering === 'favorite') ? 'Favoris' : 'Ordre alphabétique'}</span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={toggleChoiceAlphabetical} style={{ fontSize: '12px', minWidth: '180px' }}>
          {terms.common.alphabeticalOrder}
        </MenuItem>
        <MenuItem onClick={toggleChoiceFavorite} style={{ fontSize: '12px' }}>
          {terms.common.favorites}
        </MenuItem>
      </Menu>
    </div>
  )
}
