/* eslint-disable max-len */
import { ReactElement } from 'react'
import TablePagination from '@mui/material/TablePagination'
import '../station.scss'

type Props = {
    initialPage: number;
    rowsPerPage: number;
    setInitialPage: (value: number) => void;
    setRowsPerPage: (value: number) => void;
}

export default function PaginationLinesPerPage({
  initialPage, setInitialPage, rowsPerPage, setRowsPerPage,
}: Props): ReactElement {
  const handleChangePage = () => {
    setInitialPage(0)
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setInitialPage(0)
  }

  return (
    <>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={-1}
        rowsPerPage={rowsPerPage}
        page={initialPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ count }) => `${initialPage + 1} / ${Math.ceil(count / rowsPerPage)}`}
        className="pagination-lines-per-page"
      />
    </>
  )
}
