import { ReactElement, useEffect } from 'react'
import { RootState } from 'Store'
import { useDispatch, useSelector } from 'react-redux'
import GroixService from 'reducers/services/groixService'
import { setError } from 'reducers/groix'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import GroixRemove from './GroixRemove'

export default function GroixUploaded(): ReactElement {
  const { uploadedList, uploadedItem, groix } = useSelector((state: RootState) => state.groix)
  const dispatch = useDispatch()

  useEffect(() => {
    if (uploadedItem) {
      dispatch(GroixService.getGroix(uploadedItem?.id))
    }
  }, [uploadedItem])

  useEffect(() => {
    if (groix && (groix?.statut === 'Insertion en cours' || groix?.statut === 'En attente de sauvegarde')) {
      setTimeout(() => {
        dispatch(GroixService.getGroix(uploadedItem?.id))
      }, 1000)
    } else if (groix?.statut === 'Insertion échouée') {
      dispatch(setError({
        status: 401,
        message: groix.detail_statut,
      }))
    }
  }, [groix])

  const getColor = statut => {
    if (statut === 'Insertion terminée') {
      return 'green'
    } return 'red'
  }
  return (
    <>
      {uploadedList.map((item: any, index: number) => (
        <Paper
          elevation={0}
          key={item.id}
          sx={{
            borderRadius: '8px', width: '100%',
          }}
        >
          <Stack sx={{
            flexDirection: 'row', alignItems: 'center', paddingX: '24px', marginTop: '10px',
          }}
          >
            <CheckCircleIcon sx={{ fontSize: '24px', color: getColor(item.statut) }} />
            <Typography
              sx={{
                fontWeight: '900',
                textAlign: 'center',
                paddingX: '24px',
              }}
            >
              {item.nom_fichier}
            </Typography>
            <GroixRemove groixIndex={index} />
          </Stack>
        </Paper>
      ))}
    </>
  )
}
