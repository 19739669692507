import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { Grid, InputLabel, TextField } from '@mui/material'
import { ReactElement } from 'react'

type Props = {
    label: string;
    value: any;
    index: number;
    onChange: (value: any) => void;
    inputStyle: string;
    warningMessage: string;
}

export default function CostumTimePicker({
  label, index, value, onChange, inputStyle, warningMessage,
}: Props): ReactElement {
  return (
    <Grid>
      {label !== '' && index === 0 && (
      <InputLabel htmlFor="my-input">
        {label}
        {' '}
        <strong style={{ color: 'red' }}>*</strong>
      </InputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          openTo="hours"
          ampm={false}
          value={value}
          onChange={onChange}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: '',
              }}
            />
          )}
          className={inputStyle}
        />
        <div style={{ color: 'red' }}>{warningMessage}</div>
      </LocalizationProvider>
    </Grid>
  )
}
