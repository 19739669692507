import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSelectedCirculationType } from 'reducers/filters'
import { RootState } from 'Store'
import terms from 'common/terms'
import FiltersChips from './FiltersChips'

export default function CirculationTypeFilter(): ReactElement {
  const dispatch = useDispatch()
  const { circulationType, filters } = useSelector((state: RootState) => state.metrics)
  const { selectedCirculationType } = useSelector((state: RootState) => state.filters)

  useEffect(() => {
    if (filters && selectedCirculationType.length === 0) {
      dispatch(updateSelectedCirculationType(filters.circulation_type))
    }
  }, [filters])

  const onCirculationTypeClick = (circulation: string) => {
    const circulationList: string[] = []
    if (selectedCirculationType.filter(type => type === circulation)?.length === 0) {
      circulationList.push(...selectedCirculationType, circulation)
    } else {
      circulationList.push(...selectedCirculationType.filter(type => type !== circulation))
    }
    dispatch(updateSelectedCirculationType(circulationList))
  }

  return (
    <FiltersChips
      title={terms.Filters.circulationType}
      data={circulationType}
      handleClick={onCirculationTypeClick}
      selectedValues={selectedCirculationType}
    />
  )
}
