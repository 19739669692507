/* eslint-disable max-len */
import { ReactElement } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { linearGradientDef } from '@nivo/core'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import LineChatTooltip from './LineChartTooltip'
import PonctualityHeader from './PonctualityHeader'
import {
  color6b, color3b, color5b,
} from '../colorSchemes'

type Props = {
  data: any;
  title: string;
}

export default function PonctualityLineChart({ data, title }: Props): ReactElement {
  const { monochrome } = useSelector((state: RootState) => state.stations)
  return (
    <Paper sx={{
      width: '100%', boxShadow: 'none', paddingLeft: '3rem', paddingRight: '3rem',
    }}
    >
      <PonctualityHeader title={title} fullWidth />
      <Box sx={{ p: 3, height: '600px' }}>
        <ResponsiveLine
          data={data}
          curve="catmullRom"
          enableArea
          areaOpacity={1}
          crosshairType="x"
          theme={{
            crosshair: {
              line: {
                strokeWidth: 3,
                stroke: 'white',
                strokeOpacity: 1,
                strokeDasharray: null,
              },
            },
            grid: {
              line: {
                strokeWidth: 1,
                stroke: '#EDEDED',
                opacity: 0.5,
              },
            },
            legends: {
              text: {
                fontSize: 14,
              },
            },
          }}
          lineWidth={3}
          colors={monochrome ? ['#4A81F0', '#C0CFED'] : [color6b, color5b]}
          defs={[
            linearGradientDef('gradientA', [
              { offset: 70, color: monochrome ? '#C0CFED' : color5b },
              { offset: 100, color: monochrome ? '#C0CFED' : color5b, opacity: 0 },
            ]),
            linearGradientDef('gradientB', [
              { offset: 0, color: monochrome ? '#81A5EF' : color6b },
              { offset: 100, color: monochrome ? '#4A81F0' : color6b, opacity: 0 },
            ]),
          ]}
          fill={[
            { match: { id: 'Nombre de circulations en retard au départ' }, id: 'gradientA' },
            { match: '*', id: 'gradientB' },
          ]}
          enablePoints
          areaBlendMode="normal"
          pointSize={10}
          pointColor="white"
          pointBorderColor={monochrome ? '#9BB9F2' : color3b}
          pointBorderWidth={3}
          pointLabelYOffset={-12}
          animate
          enableSlices="x"
          sliceTooltip={({ slice }) => <LineChatTooltip slice={slice} />}
          legends={[
            {
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateX: 70,
              translateY: -70,
              itemDirection: 'left-to-right',
              itemWidth: 370,
              itemHeight: 60,
              symbolSize: 12,
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              symbolShape: ({ fill }) => <rect x={-50} y={25} width="60px" height="10px" rx="5" fill={fill} />,
            },
          ]}
          margin={{
            top: 50, right: 110, bottom: 50, left: 60,
          }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false,
          }}
          axisBottom={{
            tickValues: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Heure',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Nombre de circulations',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
        />
      </Box>
    </Paper>
  )
}
