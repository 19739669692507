import { ReactElement } from 'react'
import { IconButton, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

type Props = {
    title: string;
    onClick: () => void;
}

export default function InputFilterTitle({ title, onClick }: Props): ReactElement {
  return (
    <div className="d-flex justify-content-between">
      <Typography className="inputs-title">
        {title}
      </Typography>
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClick}
        aria-label="add"
        sx={{ marginRight: 0 }}
      >
        <AddIcon />
      </IconButton>
    </div>
  )
}
