import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import metricsService from 'reducers/services/metricsService'
import { ResponseError } from 'types'
import { Filters, FiltersDelay } from './types'

interface MetricsState {
  metricsList: any;
  departPonctuality: any;
  loadingDepartPonctuality: boolean;
  errorDepartPonctuality: boolean;
  errorDepartPonctualityCode?: ResponseError;
  arrivalPonctuality: any;
  loadingArrivalPonctuality: boolean;
  errorArrivalPonctuality: boolean;
  errorArrivalPonctualityCode?: ResponseError;
  ponctualityLineChart: any;
  loadingPonctualityLineChart: boolean;
  errorPonctualityLineChart: boolean;
  errorPonctualityLineChartCode?: ResponseError;
  trackUsage: any;
  loadingTrackUsage: boolean;
  errorTrackUsage: boolean;
  errorTrackUsageCode?: ResponseError;
  hourlyVariation: any;
  loadingHourlyVariation: boolean;
  errorHourlyVariation: boolean;
  errorHourlyVariationCode?: ResponseError;
  detailVariation: any;
  loadingDetailVariation: boolean;
  errorDetailVariation: boolean;
  errorDetailVariationCode: any;
  govVariation: any;
  loadingGovVariation: boolean;
  errorGovVariation: boolean;
  fromGroix: boolean;
  trainsList: Array<any>;
  errorTrainsList: boolean;
  activeMetrics: number;
  categoriesVariabiliteDetail: Array<any>;
  categoriesVariabiliteHourly: Array<any>;
  filters?: Filters;
  balanceType: string[];
  circulationType: string[];
  variabilityDefinition: any;
  loadingTrainsList: boolean;
  sortingBy?: any;
  filterArrival: FiltersDelay;
  filterDeparture: FiltersDelay;
}

const initialState: MetricsState = {
  metricsList: [],
  departPonctuality: {},
  loadingDepartPonctuality: false,
  errorDepartPonctuality: false,
  arrivalPonctuality: {},
  loadingArrivalPonctuality: false,
  errorArrivalPonctuality: false,
  ponctualityLineChart: {},
  loadingPonctualityLineChart: false,
  errorPonctualityLineChart: false,
  trackUsage: [],
  loadingTrackUsage: false,
  errorTrackUsage: false,
  hourlyVariation: {},
  loadingHourlyVariation: false,
  errorHourlyVariation: false,
  detailVariation: {},
  loadingDetailVariation: false,
  errorDetailVariation: false,
  errorDetailVariationCode: undefined,
  govVariation: [],
  loadingGovVariation: true,
  errorGovVariation: true,
  fromGroix: false,
  trainsList: [],
  errorTrainsList: false,
  activeMetrics: 0,
  categoriesVariabiliteDetail: [],
  categoriesVariabiliteHourly: [],
  balanceType: [],
  circulationType: [],
  variabilityDefinition: undefined,
  loadingTrainsList: false,
  sortingBy: [],
  filterArrival: {
    operation: 'all',
    value1: null,
    value2: null,
  },
  filterDeparture: {
    operation: 'all',
    value1: null,
    value2: null,
  },
}

export const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    reset: () => initialState,
    setMetricsInitialState: state => {
      state.metricsList = []
      state.govVariation = []
    },
    setGovVariationInitialState: state => {
      state.govVariation = []
    },
    setTrainsList: state => {
      state.trainsList = []
    },
    updateMetricsList: (state, action: PayloadAction<Array<any>>) => {
      state.metricsList = action.payload
    },
    setFromGroix: (state, action: PayloadAction<any>) => {
      state.fromGroix = action.payload
    },
    setSortingBy: (state, action: PayloadAction<any>) => {
      state.sortingBy = action.payload
    },
    updateActiveMetrics: (state, action: PayloadAction<number>) => {
      state.activeMetrics = action.payload
    },
    updateFilters: (state, action: PayloadAction<Filters>) => {
      state.filters = action.payload
    },
    updateFilterArrival: (state, action: PayloadAction<any>) => {
      state.filterArrival = action.payload
    },
    updateFilterDeparture: (state, action: PayloadAction<any>) => {
      state.filterDeparture = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(metricsService.getMetricsJsa.fulfilled, (state, action) => {
      state.metricsList = action.payload
    })
    builder.addCase(metricsService.getMetricsGroix.fulfilled, (state, action) => {
      state.metricsList = action.payload
    })

    builder.addCase(metricsService.getDepartPonctuality.fulfilled, (state, action) => {
      state.departPonctuality = action.payload
      state.loadingDepartPonctuality = false
      state.errorDepartPonctuality = false
      state.errorDepartPonctualityCode = undefined
    })
    builder.addCase(metricsService.getDepartPonctuality.pending, state => {
      state.loadingDepartPonctuality = true
      state.errorDepartPonctualityCode = undefined
    })
    builder.addCase(metricsService.getDepartPonctuality.rejected, (state, action) => {
      state.loadingDepartPonctuality = false
      state.errorDepartPonctuality = true
      state.errorDepartPonctualityCode = action.payload
    })

    builder.addCase(metricsService.getArrivalPonctuality.fulfilled, (state, action) => {
      state.arrivalPonctuality = action.payload
      state.loadingArrivalPonctuality = false
      state.errorArrivalPonctuality = false
      state.errorArrivalPonctualityCode = undefined
    })
    builder.addCase(metricsService.getArrivalPonctuality.pending, state => {
      state.loadingArrivalPonctuality = true
      state.errorArrivalPonctualityCode = undefined
    })
    builder.addCase(metricsService.getArrivalPonctuality.rejected, (state, action) => {
      state.loadingArrivalPonctuality = false
      state.errorArrivalPonctuality = true
      state.errorArrivalPonctualityCode = action.payload
    })

    builder.addCase(metricsService.getPonctualityLineChart.fulfilled, (state, action) => {
      state.ponctualityLineChart = action.payload
      state.loadingPonctualityLineChart = false
      state.errorPonctualityLineChart = false
      state.errorPonctualityLineChartCode = undefined
    })
    builder.addCase(metricsService.getPonctualityLineChart.pending, state => {
      state.loadingPonctualityLineChart = true
      state.errorPonctualityLineChartCode = undefined
    })
    builder.addCase(metricsService.getPonctualityLineChart.rejected, (state, action) => {
      state.loadingPonctualityLineChart = false
      state.errorPonctualityLineChart = true
      state.errorPonctualityLineChartCode = action.payload
    })

    builder.addCase(metricsService.getTrackUsage.fulfilled, (state, action) => {
      state.trackUsage = action.payload
      state.loadingTrackUsage = false
      state.errorTrackUsage = false
      state.errorTrackUsageCode = undefined
    })
    builder.addCase(metricsService.getTrackUsage.pending, state => {
      state.loadingTrackUsage = true
      state.errorTrackUsageCode = undefined
    })
    builder.addCase(metricsService.getTrackUsage.rejected, (state, action) => {
      state.loadingTrackUsage = false
      state.errorTrackUsage = true
      state.errorTrackUsageCode = action.payload
    })

    builder.addCase(metricsService.getHourlyVariation.fulfilled, (state, action) => {
      state.hourlyVariation = action.payload
      state.loadingHourlyVariation = false
      state.errorHourlyVariation = false
      state.errorHourlyVariationCode = undefined
    })
    builder.addCase(metricsService.getHourlyVariation.pending, state => {
      state.loadingHourlyVariation = true
      state.errorHourlyVariationCode = undefined
    })
    builder.addCase(metricsService.getHourlyVariation.rejected, (state, action) => {
      state.loadingHourlyVariation = false
      state.errorHourlyVariation = true
      state.errorHourlyVariationCode = action.payload
    })

    builder.addCase(metricsService.getDetailVariation.fulfilled, (state, action) => {
      state.detailVariation = action.payload
      state.loadingDetailVariation = false
      state.errorDetailVariation = false
      state.errorDetailVariationCode = undefined
    })
    builder.addCase(metricsService.getDetailVariation.pending, state => {
      state.loadingDetailVariation = true
      state.errorDetailVariationCode = undefined
    })
    builder.addCase(metricsService.getDetailVariation.rejected, (state, action) => {
      state.loadingDetailVariation = false
      state.errorDetailVariation = true
      state.errorDetailVariationCode = action.payload
    })

    builder.addCase(metricsService.getGovVariation.fulfilled, (state, action) => {
      if (state.govVariation.filter(gov => gov.meta.arg.title === action.meta.arg.title).length === 0) {
        state.govVariation.push(action)
      }
      state.loadingGovVariation = false
      state.errorGovVariation = false
    })
    builder.addCase(metricsService.getGovVariation.pending, state => {
      state.loadingGovVariation = true
    })
    builder.addCase(metricsService.getGovVariation.rejected, (state, action) => {
      if (state.govVariation.filter(gov => gov.meta.arg.title === action.meta.arg.title).length === 0) {
        state.govVariation.push(action)
      }
      state.loadingGovVariation = false
      state.errorGovVariation = true
    })

    builder.addCase(metricsService.getTrainsList.fulfilled, (state, action) => {
      if (state.trainsList.filter(train => train.meta.arg.order === action.meta.arg.order).length === 0) {
        state.trainsList.push(action)
      }
      state.errorTrainsList = false
      state.loadingTrainsList = false
    })

    builder.addCase(metricsService.getTrainsList.pending, state => {
      state.loadingTrainsList = true
    })

    builder.addCase(metricsService.getTrainsList.rejected, (state, action) => {
      if (state.trainsList.filter(train => train.meta.arg.order === action.meta.arg.order).length === 0) {
        state.trainsList.push(action)
      }
      state.errorTrainsList = true
      state.loadingTrainsList = false
    })

    builder.addCase(metricsService.getCategoriesVariabiliteDetail.fulfilled, (state, action) => {
      state.categoriesVariabiliteDetail = action.payload
    })
    builder.addCase(metricsService.getCategoriesVariabiliteHourly.fulfilled, (state, action) => {
      state.categoriesVariabiliteHourly = action.payload
    })

    builder.addCase(metricsService.getVariabilityDefinition.fulfilled, (state, action) => {
      state.variabilityDefinition = action.payload
    })

    builder.addCase(metricsService.getMetricsParams.fulfilled, (state, action) => {
      state.balanceType = action.payload[0].parameters.filter(p => p.slug === 'balance_type')[0].values
      state.circulationType = action.payload[0].parameters.filter(p => p.slug === 'circulation_type')[0].values
    })
  },
})

export const {
  updateMetricsList, setFromGroix, setMetricsInitialState,
  setTrainsList, updateActiveMetrics, updateFilters, reset, setGovVariationInitialState, setSortingBy,
  updateFilterArrival, updateFilterDeparture,
} = metricsSlice.actions

export default metricsSlice.reducer
