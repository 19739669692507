/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
} from '@mui/material'
import { RootState } from 'Store'
import warningIcon from 'assets/icons/warning.png'
import terms from 'common/terms'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateHasNoGovs } from 'reducers/missingGov'
import { updateHasNoPagines } from 'reducers/missingPagine'
import GovService from 'reducers/services/govService'
import PagineService from 'reducers/services/pagineService'
import { GovsList, Pagine } from 'reducers/types'
import { v4 as uuidV4 } from 'uuid'
import ReImportNotice from './ReImportNotice'

export default function MissingDates(): ReactElement {
  const dispatch = useDispatch()
  const { metricsList } = useSelector((state: RootState) => state.metrics)
  const { govs, hasNoGovs } = useSelector((state: RootState) => state.missingGov)
  const { paginesList, hasNoPagines } = useSelector((state: RootState) => state.missingPagine)
  const [noDataStatus, setNoDataStatus] = useState<GovsList[]>(undefined)
  const [pendingStatus, setPendingStatus] = useState<GovsList[]>(undefined)
  const [noDataPaginesStatus, setNoDataPaginesStatus] = useState<Pagine[]>(undefined)
  const [pendingPaginesStatus, setPendingPaginesStatus] = useState(undefined)
  const columns = metricsList?.metrics?.filter(obj => obj?.board === 'PAGINE').length !== 0 ? 3 : 4

  const getMissingGare = (comparaisonType: string) => (metricsList && metricsList.length !== 0
    ? metricsList?.gare[comparaisonType] : [])

  const getGovsList = (key: string) => (govs?.filter((gov: GovsList) => gov.comparaisonType
  === key)[0])?.govs

  const getGovsStatus = (key: string, status: string) => (govs?.filter((gov: GovsList) => gov.comparaisonType
  === key)[0])?.govs.filter(gov => gov.statut === status)

  const getPaginesStatus = (status: string) => (paginesList?.filter((pagine: Pagine) => pagine.statut === status))

  useEffect(() => {
    const noDataGovs: GovsList[] = []
    const noDataPagines: Pagine[] = []
    Object.keys(metricsList?.missing_days).map(key => (key === 'PAGINE'
      ? noDataPagines.push(getPaginesStatus('no_data'))
      : noDataGovs.push({
        govs: getGovsStatus(key, 'no_data'),
        comparaisonType: key,
      })))
    setNoDataStatus(noDataGovs)
    setNoDataPaginesStatus(noDataPagines)
    const pendingGovs: GovsList[] = []
    const pendingPagines: Pagine[] = []
    Object.keys(metricsList?.missing_days).map(key => (key === 'PAGINE'
      ? pendingPagines.push(getPaginesStatus('pending'))
      : pendingGovs.push({
        govs: getGovsStatus(key, 'pending'),
        comparaisonType: key,
      })))
    setPendingStatus(pendingGovs)
    setPendingPaginesStatus(pendingPagines[0])
  }, [govs, paginesList])

  const handleClick = (key: string) => {
    if (key !== 'PAGINE') {
      const ids: string[] = []
      metricsList.missing_days[key].forEach(day => {
        if (Object.values(day)[0] !== '') {
          ids.push(Object.values(day)[0] as string)
        }
      })
      if (ids.length !== 0) {
        dispatch(GovService.updateGovs({ ids, comparaisontype: key }))
      } else {
        dispatch(updateHasNoGovs(true))
      }
    } else {
      const ids: string[] = []
      metricsList.missing_days[key].forEach(day => {
        if (Object.values(day)[0] !== '') {
          ids.push(Object.values(day)[0] as string)
        }
      })
      if (ids.length !== 0) {
        dispatch(PagineService.updatePagines(ids))
      } else {
        dispatch(updateHasNoPagines(true))
      }
    }
  }

  return (
    <Grid
      container
      spacing={5}
      justifyContent="center"
      alignItems="start"
      sx={{ width: '100%', mx: '6%' }}
    >
      {
        metricsList && metricsList.length !== 0 && Object.keys(metricsList?.missing_days).map((key: string) => (
          <Grid className="type" key={uuidV4()} item xs={6} sm={3} justifyContent="center">
            <div className="type-container">
              <header className="missing-day-headers">
                <div className="title">
                  <img src={warningIcon} alt="" width={30} className="mr-2" />
                  <span>
                    {key}
                  </span>
                </div>
                {
                  getMissingGare(key).length === 0 && (
                    <ReImportNotice
                      comparaisonType={key}
                      govs={noDataStatus?.filter(gov => gov.comparaisonType === key)[0]?.govs}
                    />
                  )
                }
              </header>
              <div className="overflow-container">
                {
                  getMissingGare(key).length === 0 ? (
                    <div className="day-container">
                      {
                        metricsList.missing_days[key].map((day: string) => (
                          <div key={Object.keys(day)[0]} className="day">
                            {new Date(Object.keys(day)[0]).toLocaleString('fr-FR', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}
                          </div>
                        ))
                      }
                    </div>
                  ) : (
                    metricsList.gare[key].length !== 0 && (
                    <div className="notice">
                      {metricsList.gare[key]}
                    </div>
                    )
                  )
                }
              </div>

            </div>
            <div className={`import-button ${key === 'Réel (J)' ? 'off' : ''} `}>
              <button
                type="button"
                disabled={(
                  pendingStatus?.filter(gov => gov.comparaisonType === key)[0]?.govs?.length
                   === getGovsList(key)?.length && getGovsList(key))
                  || metricsList.missing_days[key].length === 0
                  || hasNoGovs
                  || (key === 'PAGINE' && pendingPaginesStatus?.length === paginesList?.length)
                  || (key === 'PAGINE' && hasNoPagines)}
                onClick={() => handleClick(key)}
              >
                {(
                  key !== 'PAGINE'
                    && ((noDataStatus?.filter(gov => gov.comparaisonType === key)[0]?.govs
                    && noDataStatus?.filter(gov => gov.comparaisonType === key)[0]?.govs?.length !== 0)
                    || metricsList.missing_days[key].length === 0
                    || (!hasNoGovs && !getGovsList(key))))
                    || (
                      key === 'PAGINE'
                      && (!(pendingPaginesStatus?.length === paginesList?.length)
                      || (hasNoPagines && !paginesList)))
                  ? terms.MissingGov.reImport : terms.MissingGov.reImportPending}
              </button>
            </div>
          </Grid>
        ))
      }
    </Grid>
  )
}
