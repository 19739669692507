import { ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'
import { updateOriginDestinationFields, reset } from 'reducers/filters'
import { updateFilters } from 'reducers/metrics'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { filterButton } from 'common/CustomTheme'
import ThemeProvider from '@mui/material/styles/ThemeProvider'

const TooltipInfo = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    maxWidth: '100%',
    fontSize: theme.typography.pxToRem(12),
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: '7.53488px',
  },
}))

export default function FilterH00Btn(): ReactElement {
  const { activeStation } = useSelector((state: RootState) => state.stations)
  const { fromGroix } = useSelector((state: RootState) => state.metrics)
  const dispatch = useDispatch()
  const applyFilters = () => {
    dispatch(reset())
    dispatch(updateOriginDestinationFields([
      {
        index: 0,
        origin: activeStation?.abv_traction,
        destination: '',
      },
    ]))
    dispatch(updateFilters({
      balance_type: ['COM-COM', 'TECH-COM'],
      circulation_type: [],
      multi_time_range: [],
      track: [],
      train_number: [],
      train_number_range: [],
      equipment: [],
      ori_dest: [`${activeStation?.abv_traction}-`],
    }))
  }

  return (
    (!fromGroix && (
    <ThemeProvider theme={filterButton}>
      <TooltipInfo
        title={(
          <div className="d-flex flex-column p-1" style={{ fontSize: 16 }}>
            <span>{terms.Ponctuality.filterH00BtnTooltipText1}</span>
            <span style={{ color: '#99B7F6', fontWeight: '900' }}>
              {terms.Ponctuality.filterH00BtnTooltipText2}
              {' '}
              {activeStation?.abv_traction}
            </span>
          </div>
        )}
      >
        <Button
          onClick={applyFilters}
          variant="contained"
          disableElevation
          className="mt-2 position-absolute"
          sx={{ minWidth: '240px', right: 0 }}
          data-html2canvas-ignore
        >
          {terms.Ponctuality.filterH00Btn}
        </Button>
      </TooltipInfo>
    </ThemeProvider>
    ))

  )
}
