import { ReactElement, useEffect } from 'react'
import Container from '@mui/material/Container'
import TopBar from 'components/Common/TopBar/TopBar'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateYearsList, updateDatesList, updateAvailableDates, updateAvailableDates2,
  updateExcludedDates, updateSelectedDates, updateConfirmedDates,
} from 'reducers/annualServices'
import { RootState } from 'Store'
import { setSaveStatus } from 'reducers/stations'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import GovBanner from './GovBanner'
import GovCards from './GovCards'

export default function GovOptions(): ReactElement {
  const dispatch = useDispatch()
  const { activeStation } = useSelector((state: RootState) => state.stations)

  useEffect(() => {
    if (activeStation.est_config !== true) {
      history.push(PathNames.TrigramConfig)
    } dispatch(setSaveStatus(false))
  }, [])

  useEffect(() => {
    dispatch(updateYearsList([]))
    dispatch(updateDatesList([]))
    dispatch(updateAvailableDates([]))
    dispatch(updateAvailableDates2([]))
    dispatch(updateExcludedDates([]))
    dispatch(updateSelectedDates([]))
    dispatch(updateConfirmedDates([]))
  }, [])

  return (
    <main className="mastcontainer pl-0">
      <Container maxWidth={false} disableGutters sx={{ height: '100%' }}>
        <TopBar appName={terms.common.appName} goHomeIcon />
        <GovBanner />
        <GovCards />
      </Container>
    </main>
  )
}
