import { ReactElement } from 'react'
import './style.scss'

type Props = {
    label: string;
    value: number;
}

const Tooltip = ({ label, value }: Props): ReactElement => (
  <div className="custom-tooltip">
    <div className="label">
      {label.split(' - ')[0]}
    </div>

    <div className="number">
      {value}
    </div>
  </div>
)

export default Tooltip
