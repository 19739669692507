/* eslint-disable react/prop-types */

import moment from 'moment'

/* eslint-disable react/destructuring-assignment */
const separateDataType = (tableauType, tableauData) => {
  const result = []
  tableauType.forEach(type => {
    const typeData = tableauData.filter(item => type.values.includes(item.x))
    if (typeData.length > 0) {
      result.push({
        x: type.key,
        type: type.key,
      })
      typeData.forEach(data => result.push({
        ...data,
        type: type.key,
      }))
    }
  })

  return result
}

const BarTotalsLayer = props => {
  const labelOffset = 10
  const labelFontSize = 12
  if (props?.bars?.length === 0) return null
  const totals = {}
  const bandwidth = props.bars[0].width
  props.bars.forEach(bar => {
    const { indexValue } = bar.data
    if (!(indexValue in totals)) {
      totals[indexValue] = 0
    }
    if (!bar.data.hidden) {
      totals[indexValue] += bar.data.value
    }
  })
  const labels = Object.keys(totals).map(indexValue => {
    const x = props.xScale(indexValue) + bandwidth / 2
    const y = props.yScale(totals[indexValue]) - labelOffset
    return (
      <text
        key={`total.${indexValue}`}
        x={x}
        y={y}
        textAnchor="middle"
        fontWeight="bold"
        fontSize={labelFontSize}
      >
        {totals[indexValue] > 0 ? totals[indexValue] : ''}
      </text>
    )
  })
  return <>{labels}</>
}

const calcTotalBarChart = data => {
  let total = 0
  Object.keys(data).forEach(key => {
    if (key !== 'x' && typeof data[key] === 'number') {
      total += data[key]
    }
  })
  return total
}

const formatDate = (date: string) => moment(date, 'DD/MM/YYYY').format('LL')

export {
  separateDataType, BarTotalsLayer, formatDate, calcTotalBarChart,
}
