import { Card, CardContent } from '@mui/material'
import { ReactElement } from 'react'
import warningIcon from 'assets/icons/warning.png'
import terms from 'common/terms'

type Props = {
  message: string;
}

export default function WarningCard({ message }: Props): ReactElement {
  return (
    <div className="d-flex align-items-center justify-content-center h-100 flex-column text-center">
      <Card variant="outlined" sx={{ maxWidth: 500, marginTop: '3.875rem' }}>
        <CardContent>
          <div className="d-flex align-items-center justify-content-center h-100 flex-column text-center mt-3">
            <img src={warningIcon} alt="" width={80} />
            <h1 className="font-weight-bold my-5">{message}</h1>
            {message?.includes('Tranche') && <h3>{terms.Filters.trancheNotice}</h3>}
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
