/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import terms from 'common/terms'
import { RootState } from 'Store'
import { setSaveStatus } from 'reducers/stations'
import Fade from '@mui/material/Fade'
import history from 'customHistory'
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import CloseIcon from '@mui/icons-material/Close'
import customButton from 'common/CustomTheme'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import LoadingButton from '@mui/lab/LoadingButton'
import PathNames from 'common/PathNames'
import StationsService from 'reducers/services/stationsService'
import Snackbar from '../Common/SnackBar/Snackbar'
import './configuration.scss'
import EquipmentTable from './EquipmentTable'

export default function EquipmentConfig(): ReactElement {
  const dispatch = useDispatch()
  const { loading, error } = useSelector((state: RootState) => state.equipments)
  const { activeStation, saveStatus } = useSelector((state: RootState) => state.stations)

  const errorFound = error !== undefined

  const errorMessage = error?.message

  const validateConfig = () => {
    const object = {
      stationId: activeStation.id,
      params: {
        est_config: true,
      },
    }
    dispatch(StationsService.validateConfig(object))
  }

  useEffect(() => {
    if (saveStatus) {
      history.push(PathNames.OptionChoice)
    } dispatch(setSaveStatus(false))
  }, [activeStation, saveStatus])

  return (
    <main>
      <Fade timeout={400} in mountOnEnter unmountOnExit>
        <Container maxWidth={false} disableGutters sx={{ height: '100%' }}>
          <Box sx={{ height: '68px' }} style={{ background: '#022e4f' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: '100%', width: '90%', margin: 'auto' }}
            >
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label="back"
                  onClick={() => history.push(PathNames.TrackConfig)}
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
                <Typography sx={{ fontSize: '12px', color: 'white' }}>
                  {terms.StationsDashboard.EquipmentConfig.returnTopBt}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label="close"
                  onClick={() => history.push(PathNames.Home)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ height: '30%' }} style={{ background: '#022e4f' }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100%', width: '70%', margin: 'auto' }}
            >
              <Grid item>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: '400',
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  {activeStation.nom_gare}
                  {' '}
                  (
                  {activeStation.abv_traction}
                  )
                </Typography>
              </Grid>
              <Grid item mt={1} mb={5}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: '400', color: 'white' }}
                >
                  {terms.StationsDashboard.EquipmentConfig.EquipmentConfigSubtitle}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              height: 'calc(70% - 68px)',
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100%', margin: 'auto' }}
            >
              <EquipmentTable />
              <Grid
                item
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <ThemeProvider theme={customButton}>
                  <LoadingButton
                    onClick={() => validateConfig()}
                    endIcon={<ArrowRightAltIcon />}
                    variant="contained"
                    loadingPosition="end"
                    disableElevation
                    loading={loading}
                    sx={{
                      width: '400px',
                      paddingY: '15px',
                      borderRadius: '30px',
                      color: 'white',
                      mb: 5,
                    }}
                  >
                    {terms.StationsDashboard.EquipmentConfig.validateBt}
                  </LoadingButton>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Fade>
      <Snackbar message={errorMessage} error={errorFound} />
    </main>
  )
}
