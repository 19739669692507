/* eslint-disable max-len */
import { ReactElement } from 'react'
import terms from 'common/terms'
import bannerBG from 'assets/images/home-bg.jpg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import StationsSearchBar from './SearchBar'

export default function StationsBanner(): ReactElement {
  const styles = {
    bannerContainer: {
      backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${bannerBG})`,
      backgroundSize: 'cover',
      height: '40%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '0px 0px 100px 0px',
    },
  }

  return (
    <Fade in timeout={500}>
      <Box style={styles.bannerContainer}>
        <Grid
          container
          justifyContent="center"
          sx={{
            height: '300px', width: '100%', pl: 3, pr: 3,
          }}
        >
          <Grid item xs={12} sm={10} md={8} sx={{ mt: 4 }}>
            <Typography sx={{ fontWeight: '900', fontSize: '56px', color: '#000000' }}>
              {terms.StationsDashboard.StationsHome.bannerTitle}
            </Typography>
            <Typography variant="subtitle1">
              {terms.StationsDashboard.StationsHome.bannerSubtitle}
              <ArrowRightAltIcon sx={{ ml: 1 }} />
            </Typography>

          </Grid>
          <Grid item xs={12} sm={10} md={8}>
            <StationsSearchBar />
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}
