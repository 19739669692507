import {
  TableCell, TableHead, TableRow, TableSortLabel,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { ReactElement } from 'react'

interface Props {
  data: string[];
  sortTable?: string;
  setSortTable?: (value: string) => void;
}

const TableHeader = ({ data, sortTable, setSortTable }: Props): ReactElement => {
  const shouldSorting = (column: string) => !['Total', 'Détail par type de crochet', ''].includes(column)

  const direction = (column: string) => {
    if (sortTable === column) return 'asc'
    if (sortTable === `-${column}`) return 'desc'
    return 'desc'
  }
  return (
    <TableHead>
      <TableRow>
        {
          data.map(column => (
            <TableCell key={uuidv4()} className="table-head-cell">
              {
                shouldSorting(column) ? (
                  <TableSortLabel
                    active={sortTable === column || sortTable === `-${column}`}
                    direction={direction(column)}
                    onClick={() => setSortTable(sortTable === column ? `-${column}` : column)}
                  >
                    {column}
                  </TableSortLabel>
                ) : column
              }
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  )
}

TableHeader.defaultProps = {
  sortTable: '',
  setSortTable: () => {},
}

export default TableHeader
