import { ReactElement } from 'react'
import { setInitialState } from 'reducers/groix'
import { reset } from 'reducers/metrics'
import { reset as resetFilters } from 'reducers/filters'
import history from 'customHistory'
import { updateDisplayIconNotice, updateHasNoGovs } from 'reducers/missingGov'
import { updateMonthToShow } from 'reducers/annualServices'
import PathNames from 'common/PathNames'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import Stack from '@mui/material/Stack'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

export default function BtCurrentStation(): ReactElement {
  const dispatch = useDispatch()
  const { activeStation } = useSelector((state: RootState) => state.stations)

  const handleClickGoHome = () => {
    dispatch(setInitialState())
    dispatch(reset())
    dispatch(resetFilters())
    dispatch(updateMonthToShow(undefined))
    dispatch(updateDisplayIconNotice(false))
    dispatch(updateHasNoGovs(false))
    history.push(PathNames.Home)
  }

  return (
    <Stack direction="row" sx={{ alignItems: 'center' }}>
      <IconButton
        sx={{ color: 'white' }}
        aria-label="go home"
        onClick={handleClickGoHome}
      >
        <HomeOutlinedIcon />
      </IconButton>
      <Typography sx={{ fontFamily: 'Avenir', fontSize: '16px', color: 'white' }}>
        {activeStation.nom_gare}
        {' '}
        (
        {activeStation.abv_traction}
        )
      </Typography>
    </Stack>
  )
}
