/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import terms from 'common/terms'
import { ResponseError } from 'types'
import { RootState } from 'Store'
import { setUpdateStatus, setError } from 'reducers/tracks'
import { setSaveStatus } from 'reducers/stations'
import Fade from '@mui/material/Fade'
import history from 'customHistory'
import TracksService from 'reducers/services/tracksService'
import StationsService from 'reducers/services/stationsService'
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import CloseIcon from '@mui/icons-material/Close'
import Paper from '@mui/material/Paper'
import customButton from 'common/CustomTheme'
import LoadingButton from '@mui/lab/LoadingButton'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import AddIcon from '@mui/icons-material/Add'
import InputBase from '@mui/material/InputBase'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import CheckIcon from '@mui/icons-material/Check'
import PathNames from 'common/PathNames'
import Snackbar from '../Common/SnackBar/Snackbar'
import './configuration.scss'

export default function TrackConfiguration(): ReactElement {
  const dispatch = useDispatch()
  const {
    tracksList, loading, error, updateStatus,
  } = useSelector((state: RootState) => state.tracks)

  const { activeStation, saveStatus } = useSelector((state: RootState) => state.stations)

  const [show, setShow] = useState(false)
  const [newInput, setNewInput] = useState('')
  const [trackId, setTrackId] = useState(undefined)
  const [trackValue, setTrackValue] = useState(undefined)

  const errorFound = error !== undefined
  const errorMessage = () => error?.message?.voies_a_quai[0]

  const onChangeAdd = (event: any) => {
    setNewInput(event.target.value)
  }

  const addTrack = () => {
    let array = tracksList
    array = [...array, newInput]
    const object = {
      stationId: activeStation.id,
      newTracksList: {
        voies_a_quai: array,
      },
    }
    dispatch(TracksService.patchTrack(object))
    setNewInput('')
    setShow(false)
  }

  const handleFocus = () => {
    setTrackId(undefined)
    setTrackValue(undefined)
  }

  const handleChange = (event: any) => {
    setTrackId(event.target.id)
    setTrackValue(event.target.value)
  }

  const handleModify = (id, value) => {
    let array = tracksList.filter(track => track !== id)
    array = [...array, value]
    const object = {
      stationId: activeStation.id,
      newTracksList: {
        voies_a_quai: array,
      },
    }
    dispatch(TracksService.patchTrack(object))
  }

  const validateConfig = () => {
    history.push(PathNames.EquipmentConfig)
  }

  const removeTrack = (deteteTrack: string) => {
    const array = tracksList.filter(track => track !== deteteTrack)
    const object = {
      stationId: activeStation.id,
      newTracksList: {
        voies_a_quai: array,
      },
    }
    dispatch(TracksService.patchTrack(object))
  }

  useEffect(() => {
    dispatch(TracksService.getTracksList({ id: activeStation.id }))
    dispatch(setUpdateStatus(false))
  }, [updateStatus])

  return (
    <main>
      <Fade timeout={400} in mountOnEnter unmountOnExit>
        <Container maxWidth={false} disableGutters sx={{ height: '100%' }}>
          <Box sx={{ height: '68px' }} style={{ background: '#022e4f' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: '100%', width: '90%', margin: 'auto' }}
            >
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label="back"
                  onClick={() => history.push(PathNames.ImageConfig)}
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
                <Typography sx={{ fontSize: '12px', color: 'white' }}>
                  {terms.StationsDashboard.TrackConfig.returnTopBt}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label="close"
                  onClick={() => history.push(PathNames.Home)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ height: '30%' }} style={{ background: '#022e4f' }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100%', width: '70%', margin: 'auto' }}
            >
              <Grid item>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: '400',
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  {activeStation.nom_gare}
                  {' '}
                  (
                  {activeStation.abv_traction}
                  )
                </Typography>
              </Grid>
              <Grid item mt={1} mb={5}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: '400', color: 'white' }}
                >
                  {terms.StationsDashboard.TrackConfig.TrackConfigSubtitle}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              height: 'calc(70% - 68px)',
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100%', margin: 'auto' }}
            >
              <Grid
                item
                sx={{
                  width: '40%',
                  background: 'white',
                  padding: '20px',
                  pb: '50px',
                  maxHeight: '60vh',
                  overflow: 'auto',
                  marginTop: 5,
                  marginBottom: 5,
                  boxShadow: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '30px',
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      width: '90%',
                      height: '44px',
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '20px',
                    }}
                  >
                    <Typography>
                      {terms.StationsDashboard.TrackConfig.tracks}
                    </Typography>
                  </Paper>
                  <IconButton
                    sx={{ color: 'black' }}
                    aria-label="close"
                    onClick={() => setShow(!show)}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
                {show && (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '10px',
                    backgroudColor: 'yellow',
                  }}
                >
                  <Paper
                    variant="outlined"
                    sx={{
                      width: '90%',
                      height: '44px',
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '20px',
                      borderColor: 'rgba(65, 168, 249, 1)',
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      inputProps={{ 'aria-label': terms.StationsDashboard.TrackConfig.inputPlaceholder }}
                      placeholder={terms.StationsDashboard.TrackConfig.inputPlaceholder}
                      value={newInput}
                      onChange={onChangeAdd}
                      autoFocus
                    />
                  </Paper>
                  <IconButton
                    disabled={!newInput}
                    aria-label="close"
                    onClick={() => addTrack()}
                  >
                    <CheckIcon color={!newInput ? 'disabled' : 'info'} />
                  </IconButton>
                </Box>
                )}
                {tracksList
                    && tracksList.map((track: string) => (
                      <Box
                        key={track}
                        sx={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '10px',
                        }}
                      >
                        <Paper
                          variant="outlined"
                          sx={{
                            width: '90%',
                            height: '44px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '20px',
                          }}
                        >
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            inputProps={{ 'aria-label': terms.common.modify }}
                            disabled={loading}
                            onFocus={() => handleFocus()}
                            onChange={e => handleChange(e)}
                            id={track}
                            value={trackId === track ? trackValue : track}
                          />
                        </Paper>
                        {trackId === track ? (
                          <IconButton
                            sx={{ color: 'black' }}
                            aria-label={terms.common.modify}
                            onClick={e => handleModify(trackId, trackValue)}
                          >
                            <CheckIcon color="info" />
                          </IconButton>
                        ) : (
                          <IconButton
                            sx={{ color: 'black' }}
                            aria-label={terms.common.remove}
                            onClick={() => removeTrack(track)}
                          >
                            <HorizontalRuleIcon />
                          </IconButton>
                        )}

                      </Box>
                    ))}
              </Grid>
              <Grid
                item
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <ThemeProvider theme={customButton}>
                  <LoadingButton
                    onClick={() => validateConfig()}
                    endIcon={<ArrowRightAltIcon />}
                    variant="contained"
                    loadingPosition="end"
                    disableElevation
                    disabled={trackValue === ''}
                    loading={loading}
                    sx={{
                      width: '400px',
                      paddingY: '15px',
                      borderRadius: '30px',
                      color: 'white',
                      mb: 5,
                    }}
                  >
                    {terms.StationsDashboard.TrackConfig.validateBt}
                  </LoadingButton>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Fade>
      <Snackbar message={errorMessage()} error={errorFound} />
    </main>
  )
}
