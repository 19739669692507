import { ReactElement } from 'react'
import '../station.scss'

type Props = {
    title: string;
    fullWidth?: boolean;
}

const defaultProps = {
  fullWidth: false,
}

export default function PonctualityHeader({ title, fullWidth }: Props): ReactElement {
  return (
    <div className={`my-3 mx-5 ${fullWidth ? ' w-100' : ' w-50'} `}>
      <h1 className="main-title font-weight-bold">{title}</h1>
    </div>
  )
}

PonctualityHeader.defaultProps = defaultProps
