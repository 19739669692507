import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import terms from 'common/terms'
import GroixService from 'reducers/services/groixService'
import { RootState } from 'Store'
import { addItem } from 'reducers/groix'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Groix } from 'reducers/types'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

export default function GroixList(): ReactElement {
  const dispatch = useDispatch()
  const { groixList, uploadedList } = useSelector((state: RootState) => state.groix)

  const selectGroixItem = groixItem => {
    dispatch(addItem(groixItem))
  }

  useEffect(() => {
    dispatch(GroixService.getGroixList())
  }, [uploadedList])

  const disabledAdded = e => uploadedList.some(elem => elem.id === e)

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '1.5rem',
      height: '100%',
      textAlign: 'left',
    }}
    >
      <Typography
        variant="body2"
        sx={{
          fontWeight: '400',
          color: '#B6B6B6',
          pb: '10px',
        }}
      >
        {terms.ImportGroix.previouslyImportedFiles}
      </Typography>
      <Box sx={{ overflowY: 'scroll' }}>
        <List>
          {groixList.map((groixItem: Groix) => (
            <ListItem disablePadding key={groixItem.id}>
              <ListItemButton
                disabled={disabledAdded(groixItem.id)}
                sx={{ padding: 0, margin: 0 }}
                onClick={() => selectGroixItem(groixItem)}
              >
                <ListItemText sx={{ padding: 0, margin: 0 }} primary={groixItem.nom_fichier} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  )
}
