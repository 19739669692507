/* eslint-disable dot-notation */
/* eslint-disable max-len */
import {
  ReactElement, useState, useRef, useEffect,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { format } from 'date-fns'
import metricsService from 'reducers/services/metricsService'
import _ from 'lodash'
import Paper from '@mui/material/Paper'
import { ColorButton } from 'common/CustomTheme'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import WarningCard from 'components/Common/WarningCard/WarningCard'
import Loading from 'components/Common/Backdrop/Backdrop'
import MissingGovAlert from 'components/MissingGov/MissingGovAlert'
import terms from 'common/terms'
import DelayArrivalFilter from './DelayArrivalFilter'
import DelayDepartureFilter from './DelayDepartureFilter'
import TableOfTable from './TableOfTable'
import InfoToolTip from '../Common/InfoToolTip'
import InfoHover from '../Common/InfoHover'
import fetchCsv from './utils'
import '../station.scss'

interface TabPanelProps {
  index: number;
  value: number;
}

export default function DetailVariation(props: TabPanelProps): ReactElement {
  const {
    value, index, ...other
  } = props
  const dispatch = useDispatch()
  const tableRef = useRef<HTMLTableElement>(null)
  const [results, setResults] = useState(undefined)
  const [sortingBy, setSortingBy] = useState('heure_arrivee_a')
  const {
    metricsList, detailVariation, categoriesVariabiliteDetail, loadingDetailVariation, errorDetailVariation, errorDetailVariationCode, fromGroix,
    variabilityDefinition, filters, filterArrival, filterDeparture,
  } = useSelector((state: RootState) => state.metrics)
  const { activeStation } = useSelector((state: RootState) => state.stations)

  const [comparaisonType, setComparaisonType] = useState('Théorique (J-1) / Réel (J)')
  const [variabilityCategory, setVariabilityCategory] = useState('all')

  useEffect(() => {
    const result = metricsList?.metrics?.filter(obj => obj?.board_title === 'Détail variation')
    if (result && fromGroix) {
      setComparaisonType(Object.keys(result[0].payload[0])[0])
    }
  }, [metricsList])

  useEffect(() => {
    const result = metricsList?.metrics?.filter(obj => obj?.board_title === 'Détail variation')
    setResults(result)
    if (value === index) {
      if (result) {
        const object = result[0]?.payload.find(x => x[comparaisonType])
        if (object) {
          const govA = object[comparaisonType]?.gov_a
          const govB = object[comparaisonType]?.gov_b
          dispatch(metricsService.getDetailVariation({
            uri: result[0]?.uri.substring(result[0].uri.indexOf('/usage_reseau')),
            gov_a: govA?.toString(),
            gov_b: govB?.toString(),
            type_gov_a: object[comparaisonType]?.type_gov_a,
            type_gov_b: object[comparaisonType]?.type_gov_b,
            sorting: sortingBy,
            category_filter: variabilityCategory,
            filters,
            filterArrival,
            filterDeparture,
          }))
        }
      }
    }
  }, [metricsList, comparaisonType, sortingBy, variabilityCategory, filters, value, filterArrival, filterDeparture])

  useEffect(() => {
    dispatch(metricsService.getCategoriesVariabiliteDetail())
    dispatch(metricsService.getVariabilityDefinition())
  }, [metricsList])

  const handleChangeComparaisonType = event => {
    setComparaisonType(event.target.value)
  }

  const handleChangeVariabilityCategory = event => {
    setVariabilityCategory(event.target.value)
  }

  const savefile = responseText => {
    const blob = new Blob([responseText])
    const url = window.URL.createObjectURL(blob)
    const outputFilename = `${activeStation.abv_traction} - ${comparaisonType} - ${format(new Date(Date.now()), 'dd-MM-yyyy')}.xlsx`
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', outputFilename)
    document.body.appendChild(link)
    link.click()
  }

  const handleExport = () => {
    const result = metricsList?.metrics?.filter(obj => obj?.board_title === 'Détail variation')
    if (result && !fromGroix) {
      const object = result[0]?.payload.find(x => x[comparaisonType])
      const govA = object[comparaisonType]?.gov_a
      const govB = object[comparaisonType]?.gov_b
      const typeGovA = object[comparaisonType]?.type_gov_a
      const typeGovB = object[comparaisonType]?.type_gov_b
      const delayArrival = filterArrival
      const delayDeparture = filterDeparture
      fetchCsv(govA?.toString(), govB?.toString(), typeGovA, typeGovB, variabilityCategory, sortingBy, filters, delayArrival, delayDeparture, savefile)
    } else if (result) {
      const object = Object.values(result[0]?.payload[0])[0]
      const govA = object['gov_a']
      const govB = object['gov_b']
      const typeGovA = object['type_gov_a']
      const typeGovB = object['type_gov_b']
      const delayArrival = filterArrival
      const delayDeparture = filterDeparture
      fetchCsv(govA?.toString(), govB?.toString(), typeGovA, typeGovB, variabilityCategory, sortingBy, filters, delayArrival, delayDeparture, savefile)
    }
  }

  const createSortHandlerA = event => {
    switch (event) {
      case 'Arrivée':
        setSortingBy(sortingBy === 'arrivee_a' ? '-arrivee_a' : 'arrivee_a')
        break
      case 'Départ':
        setSortingBy(sortingBy === 'depart_a' ? '-depart_a' : 'depart_a')
        break
      case 'Matériel':
        setSortingBy(sortingBy === 'materiel_a' ? '-materiel_a' : 'materiel_a')
        break
      case 'Nombre d\'éléments':
        setSortingBy(sortingBy === 'nb_elements_a' ? '-nb_elements_a' : 'nb_elements_a')
        break
      case 'Voie':
        setSortingBy(sortingBy === 'voie_a' ? '-voie_a' : 'voie_a')
        break
      case 'Heure d\'arrivée':
        setSortingBy(sortingBy === 'heure_arrivee_a' ? '-heure_arrivee_a' : 'heure_arrivee_a')
        break
      case 'Heure de départ':
        setSortingBy(sortingBy === 'heure_depart_a' ? '-heure_depart_a' : 'heure_depart_a')
        break
      default:
        setSortingBy(sortingBy === 'heure_arrivee_a' ? '-heure_arrivee_a' : 'heure_arrivee_a')
        break
    }
  }

  const createSortHandlerB = event => {
    switch (event) {
      case 'Arrivée':
        setSortingBy(sortingBy === 'arrivee_b' ? '-arrivee_b' : 'arrivee_b')
        break
      case 'Départ':
        setSortingBy(sortingBy === 'depart_b' ? '-depart_b' : 'depart_b')
        break
      case 'Matériel':
        setSortingBy(sortingBy === 'materiel_b' ? '-materiel_b' : 'materiel_b')
        break
      case 'Nombre d\'éléments':
        setSortingBy(sortingBy === 'nb_elements_b' ? '-nb_elements_b' : 'nb_elements_b')
        break
      case 'Voie':
        setSortingBy(sortingBy === 'voie_b' ? '-voie_b' : 'voie_b')
        break
      case 'Heure d\'arrivée':
        setSortingBy(sortingBy === 'heure_arrivee_b' ? '-heure_arrivee_b' : 'heure_arrivee_b')
        break
      case 'Heure de départ':
        setSortingBy(sortingBy === 'heure_depart_b' ? '-heure_depart_b' : 'heure_depart_b')
        break
      default:
        setSortingBy(sortingBy === 'heure_arrivee_b' ? '-heure_arrivee_b' : 'heure_arrivee_b')
        break
    }
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-dv-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper sx={{
          width: '100%', boxShadow: 'none', paddingLeft: '3rem', paddingRight: '3rem',
        }}
        >
          <Loading open={loadingDetailVariation} />
          <div className="d-flex w-100 flex-wrap my-3 align-items-center">
            <div className="h-100 justify-content-between align-items-between">
              <h1 className="main-title variation font-weight-bold">
                {terms.VariationDetail.VariationDetailTitle}
                <InfoToolTip text={variabilityDefinition.detail} position="position-relative" placement="bottom-end" />
              </h1>
              <h3 className="sub-title info">
                {`(${terms.VariationDetail.variationLimite})`}
              </h3>
              {errorDetailVariationCode?.status !== 400 && (
              <>
                <h3 className="sub-title">
                  {detailVariation?.train_count?.gov_a}
                </h3>
                <h3 className="sub-title">
                  {detailVariation?.train_count?.gov_b}
                </h3>
              </>
              )}

            </div>
            <DelayArrivalFilter />
            <DelayDepartureFilter />
            <FormControl sx={{ m: 1, minWidth: 260 }}>
              <Select
                value={comparaisonType}
                label={terms.VariationDetail.menuItemGovToCompare}
                onChange={handleChangeComparaisonType}
                className="detail-variation-select"
              >
                <MenuItem disabled value={terms.VariationDetail.menuItemGovToCompare}><em>{terms.VariationDetail.menuItemGovToCompare}</em></MenuItem>
                {results ? (results[0]?.payload?.map((a, i:number) => (
                  <MenuItem key={Object?.keys(results[0]?.payload[i])[0]} value={Object.keys(results[0]?.payload[i])[0]}>{Object.keys(results[0]?.payload[i])[0]}</MenuItem>
                ))) : <></>}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 260 }}>
              <Select
                value={variabilityCategory}
                label={terms.VariationDetail.menuItemVariabilityCategory}
                className="detail-variation-select"
                onChange={handleChangeVariabilityCategory}
                disabled={errorDetailVariationCode?.status === 400}
              >
                <MenuItem disabled value={terms.VariationDetail.menuItemVariabilityCategory}><em>{terms.VariationDetail.menuItemVariabilityCategory}</em></MenuItem>
                <MenuItem value="all"><em>{terms.VariationDetail.menuItemAllCategories}</em></MenuItem>
                {categoriesVariabiliteDetail.map(cv => (
                  <MenuItem key={cv.value} value={cv.value}>
                    <InfoHover title={cv.description}>
                      <div>{cv.value}</div>
                    </InfoHover>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ColorButton disabled={errorDetailVariationCode?.status === 400 || detailVariation['table-of-table']?.headers?.length === 0} onClick={() => handleExport()} startIcon={<FileDownloadOutlinedIcon />}>{terms.common.xlsxExport}</ColorButton>
          </div>
          {(errorDetailVariationCode?.status === 400) && <WarningCard message={errorDetailVariationCode?.message?.detail} />}
          {(filters !== 'all') && detailVariation['table-of-table']?.headers?.length === 0 && !errorDetailVariation && <WarningCard message={terms.Filters.noResultNotice} />}
          {metricsList?.missing_days?.length !== 0 && !fromGroix && <MissingGovAlert />}

          {(!errorDetailVariation && !loadingDetailVariation && !_.isEmpty(detailVariation?.['table-of-table']))
          && detailVariation['table-of-table']?.headers?.length !== 0 && (
            <TableOfTable detailVariation={detailVariation} tableRef={tableRef} createSortHandlerA={createSortHandlerA} createSortHandlerB={createSortHandlerB} />
          )}
        </Paper>
      )}
    </div>
  )
}
