import { linearGradientDef } from '@nivo/core'
import terms from 'common/terms'
import {
  color1a, color2a, color3a, color4a, color5a, color6a, color7a, color8a, color9a, color10a,
  color1b, color2b, color3b, color4b, color5b, color6b, color7b, color8b, color9b, color10b,
} from './colorSchemes'

export const fill = [
  {
    match: {
      id: terms.MatchColors.matchColor1,
    },
    id: 'gradient1',
  },
  {
    match: {
      id: terms.MatchColors.matchColor2,
    },
    id: 'gradient2',
  },
  {
    match: {
      id: terms.MatchColors.matchColor3,
    },
    id: 'gradient3',
  },
  {
    match: {
      id: terms.MatchColors.matchColor4,
    },
    id: 'gradient4',
  },
  {
    match: {
      id: terms.MatchColors.matchColor5,
    },
    id: 'gradient5',
  },
  {
    match: {
      id: terms.MatchColors.matchColor6,
    },
    id: 'gradient6',
  },
  {
    match: {
      id: terms.MatchColors.matchColor7,
    },
    id: 'gradient7',
  },
  {
    match: {
      id: terms.MatchColors.matchColor8,
    },
    id: 'gradient8',
  },
  {
    match: {
      id: terms.MatchColors.matchColor9,
    },
    id: 'gradient9',
  },
  {
    match: {
      id: terms.MatchColors.matchColor10,
    },
    id: 'gradient10',
  },
]

export const def = (monochrome: boolean) => [
  linearGradientDef('gradient10', [
    { offset: 0, color: monochrome ? color10a : color10b },
    { offset: 100, color: monochrome ? color10a : color10b, opacity: 1 },
  ]),
  linearGradientDef('gradient9', [
    { offset: 0, color: monochrome ? color9a : color9b },
    { offset: 100, color: monochrome ? color9a : color9b, opacity: 1 },
  ]),
  linearGradientDef('gradient8', [
    { offset: 0, color: monochrome ? color8a : color8b },
    { offset: 100, color: monochrome ? color8a : color8b, opacity: 1 },
  ]),
  linearGradientDef('gradient7', [
    { offset: 0, color: monochrome ? color7a : color7b },
    { offset: 100, color: monochrome ? color7a : color7b, opacity: 1 },
  ]),
  linearGradientDef('gradient6', [
    { offset: 0, color: monochrome ? color6a : color6b },
    { offset: 100, color: monochrome ? color6a : color6b, opacity: 1 },
  ]),
  linearGradientDef('gradient5', [
    { offset: 0, color: monochrome ? color5a : color5b },
    { offset: 100, color: monochrome ? color5a : color5b, opacity: 1 },
  ]),
  linearGradientDef('gradient4', [
    { offset: 0, color: monochrome ? color4a : color4b },
    { offset: 100, color: monochrome ? color4a : color4b, opacity: 1 },
  ]),
  linearGradientDef('gradient3', [
    { offset: 0, color: monochrome ? color3a : color3b },
    { offset: 100, color: monochrome ? color3a : color3b, opacity: 1 },
  ]),
  linearGradientDef('gradient2', [
    { offset: 0, color: monochrome ? color2a : color2b },
    { offset: 100, color: monochrome ? color2a : color2b, opacity: 1 },
  ]),
  linearGradientDef('gradient1', [
    { offset: 0, color: monochrome ? color1a : color1b },
    { offset: 100, color: monochrome ? color1a : color1b, opacity: 1 },
  ]),
]

export const color = (e: string, monochrome: boolean) => {
  switch (e) {
    case terms.MatchColors.matchColor1:
      return { color: monochrome ? color1a : color1b }
      break
    case terms.MatchColors.matchColor2:
      return { color: monochrome ? color2a : color2b }
      break
    case terms.MatchColors.matchColor3:
      return { color: monochrome ? color3a : color3b }
      break
    case terms.MatchColors.matchColor4:
      return { color: monochrome ? color4a : color4b }
      break
    case terms.MatchColors.matchColor5:
      return { color: monochrome ? color5a : color5b }
      break
    case terms.MatchColors.matchColor6:
      return { color: monochrome ? color6a : color6b }
      break
    case terms.MatchColors.matchColor7:
      return { color: monochrome ? color7a : color7b }
      break
    case terms.MatchColors.matchColor8:
      return { color: monochrome ? color8a : color8b }
      break
    case terms.MatchColors.matchColor9:
      return { color: monochrome ? color9a : color9b }
      break
    case terms.MatchColors.matchColor10:
      return { color: monochrome ? color10a : color10b }
      break
    default:
      break
  }
  return { color: monochrome ? color9a : color9b }
}
