import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

type Params = {
    label: any;
    value: number;
    aggregationCategory: string;
}

export default function Tooltip({ label, value, aggregationCategory }: Params): ReactElement {
  const { fromGroix } = useSelector((state: RootState) => state.metrics)

  return (
    <div
      style={{
        background: 'black',
        padding: '9px 12px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        opacity: '0.90',
        color: '#FFF',
        textAlign: 'center',
      }}
    >
      {!fromGroix && (
      <div style={{
        fontSize: '1rem',
        fontWeight: '900',
      }}
      >
        {label.split(' - ')[0]}
      </div>
      )}

      <div style={{
        fontSize: '1rem',
        fontWeight: '400',
      }}
      >
        {value}
        {' '}
        {aggregationCategory !== 'Nombre total de circulations' ? 'minutes' : 'circulations'}
      </div>
    </div>
  )
}
