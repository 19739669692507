/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import terms from 'common/terms'
import { RootState } from 'Store'
import { setUpdateStatus } from 'reducers/equipments'
import { setSaveStatus } from 'reducers/stations'
import history from 'customHistory'
import EquipmentsService from 'reducers/services/equipmentsService'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add'
import InputBase from '@mui/material/InputBase'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import Stack from '@mui/material/Stack'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Equipment } from 'reducers/types'
import CheckIcon from '@mui/icons-material/Check'
import PathNames from 'common/PathNames'
import './configuration.scss'

export default function EquipmentTable(): ReactElement {
  const dispatch = useDispatch()
  const {
    equipmentsList, loading, updateStatus,
  } = useSelector((state: RootState) => state.equipments)

  const { activeStation, saveStatus } = useSelector((state: RootState) => state.stations)

  const [show, setShow] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [newCode, setNewCode] = useState('')
  const [newLabel, setNewLabel] = useState('')

  const [equipmentId, setEquipmentId] = useState('')
  const [equipmentCode, setEquipmentCode] = useState('')
  const [equipmentLabel, setEquipmentLabel] = useState('')

  const onChangeAddCode = e => {
    const regex = /^[0-9\b]+$/
    if (e.target.value === '' || regex.test(e.target.value)) {
      setNewCode(e.target.value)
    }
  }
  const onChangeAddLabel = (event: any) => {
    setNewLabel(event.target.value)
  }
  const addEquipment = () => {
    const object = {
      stationId: activeStation.id,
      newEquipment: {
        code: +newCode,
        libelle: newLabel,
      },
    }
    dispatch(EquipmentsService.addEquipment(object))
    setNewCode(undefined)
    setNewLabel(undefined)
    setShow(false)
  }

  const handleChangeCode = (event: any) => {
    setEquipmentId(event.target.id)
    setEquipmentCode(event.target.value)
  }

  const handleChangeLabel = (event: any) => {
    setEquipmentId(event.target.id)
    setEquipmentLabel(event.target.value)
  }

  const handleModify = id => {
    const object = {
      stationId: activeStation.id,
      id,
      patchEquipment: {
        code: equipmentCode,
        libelle: equipmentLabel !== '' ? equipmentLabel : null,
      },
    }
    setEditMode(false)
    setEquipmentId('')
    setEquipmentCode('')
    setEquipmentLabel('')
    dispatch(EquipmentsService.patchEquipment(object))
  }

  const removeEquipment = (deleteEquipmentId: string) => {
    const object = {
      stationId: activeStation.id,
      id: deleteEquipmentId,
    }
    dispatch(EquipmentsService.deleteEquipment(object))
  }

  useEffect(() => {
    if (saveStatus) {
      history.push(PathNames.OptionChoice)
    } dispatch(setSaveStatus(false))
  }, [activeStation, saveStatus])

  useEffect(() => {
    dispatch(EquipmentsService.getEquipmentsList({ id: activeStation.id }))
    dispatch(setUpdateStatus(false))
  }, [updateStatus])

  return (
    <Grid
      item
      sx={{
        width: '40%',
        background: 'white',
        padding: '20px',
        pb: '50px',
        maxHeight: '60vh',
        overflow: 'auto',
        marginTop: 5,
        marginBottom: 5,
        boxShadow: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <Paper
          elevation={0}
          sx={{
            width: '100%',
            height: '44px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '15px',
          }}
        >
          <Typography>
            {terms.StationsDashboard.EquipmentConfig.equipmentCode}
          </Typography>
        </Paper>
        <Paper
          elevation={0}
          sx={{
            width: '100%',
            height: '44px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography>
            {terms.StationsDashboard.EquipmentConfig.equipmentLabel}
          </Typography>
        </Paper>
        <IconButton
          disabled={loading}
          sx={{
            color: '#3C3C3B',
            '&:focus': {
              backgroundColor: 'white',
              outline: 'none',
            },
          }}
          aria-label="close"
          onClick={() => {
            setShow(!show)
            setEquipmentId('')
            setEquipmentCode('')
            setEquipmentLabel('')
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      {show && (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '10px',
          backgroudColor: 'yellow',
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            width: '100%',
            height: '44px',
            display: 'flex',
            alignItems: 'center',
            borderColor: 'rgba(65, 168, 249, 1)',
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            inputProps={{ 'aria-label': terms.StationsDashboard.EquipmentConfig.inputCodePlaceholder }}
            placeholder={terms.StationsDashboard.EquipmentConfig.inputCodePlaceholder}
            value={newCode}
            onChange={onChangeAddCode}
            autoFocus
          />
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            width: '100%',
            height: '44px',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '20px',
            borderColor: 'rgba(65, 168, 249, 1)',
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            inputProps={{ 'aria-label': terms.StationsDashboard.EquipmentConfig.inputLabelPlaceholder }}
            placeholder={terms.StationsDashboard.EquipmentConfig.inputLabelPlaceholder}
            value={newLabel}
            onChange={onChangeAddLabel}
          />
        </Paper>
        <Stack direction="row">
          <IconButton
            sx={{
              color: '#3C3C3B',
              '&:focus': {
                backgroundColor: 'white',
                outline: 'none',
              },
            }}
            disabled={newCode === (undefined || '') || loading}
            aria-label="close"
            onClick={() => addEquipment()}
          >
            <CheckIcon color={newCode === (undefined || '') ? 'disabled' : 'info'} />
          </IconButton>
          <IconButton
            disabled={loading}
            sx={{
              color: '#3C3C3B',
              '&:focus': {
                backgroundColor: 'white',
                outline: 'none',
              },
            }}
            aria-label={terms.common.modify}
            onClick={() => {
              setShow(false)
              setNewCode(undefined)
              setNewLabel(undefined)
            }}
          >
            <HighlightOffOutlinedIcon color="error" />
          </IconButton>
        </Stack>
      </Box>
      )}
      {equipmentsList.length !== 0
                    && equipmentsList.map((equipment: Equipment) => (
                      <Box
                        key={equipment.id}
                        sx={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '10px',
                        }}
                      >
                        <Paper
                          variant="outlined"
                          sx={{
                            width: '100%',
                            height: '44px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '20px',
                            borderColor: equipmentId === equipment.id ? 'rgb(25, 118, 210)' : 'rgb(0, 0, 0, 0.87',
                          }}
                        >
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            inputProps={{ 'aria-label': terms.common.modify }}
                            size="small"
                            disabled={equipmentId !== equipment.id || loading}
                            autoFocus={equipmentId === equipment.id}
                            color={equipmentId === equipment.id ? 'info' : 'primary'}
                            id={equipment.id}
                            onChange={e => handleChangeCode(e)}
                            value={equipmentId === equipment.id ? equipmentCode : equipment.code}
                          />
                        </Paper>
                        <Paper
                          variant="outlined"
                          sx={{
                            width: '100%',
                            height: '44px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '20px',
                            marginLeft: '20px',
                            borderColor: equipmentId === equipment.id ? 'rgb(25, 118, 210)' : 'rgb(0, 0, 0, 0.87',
                          }}
                        >
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            inputProps={{ 'aria-label': terms.common.modify }}
                            disabled={equipmentId !== equipment.id || loading}
                            id={equipment.id}
                            onChange={e => handleChangeLabel(e)}
                            value={equipmentId === equipment.id ? equipmentLabel : (equipment.libelle || '')}
                          />
                        </Paper>
                        {editMode && equipmentId === equipment.id
                          ? (
                            <Stack direction="row">
                              <IconButton
                                disabled={loading}
                                sx={{
                                  color: '#3C3C3B',
                                  '&:focus': {
                                    backgroundColor: 'white',
                                    outline: 'none',
                                  },
                                }}
                                aria-label={terms.common.modify}
                                onClick={e => handleModify(equipmentId)}
                              >
                                <CheckCircleOutlineOutlinedIcon color="success" />
                              </IconButton>
                              <IconButton
                                disabled={loading}
                                sx={{
                                  color: '#3C3C3B',
                                  '&:focus': {
                                    backgroundColor: 'white',
                                    outline: 'none',
                                  },
                                }}
                                onClick={() => {
                                  setEditMode(false)
                                  setEquipmentId('')
                                  setEquipmentCode('')
                                  setEquipmentLabel('')
                                }}
                              >
                                <HighlightOffOutlinedIcon color="error" />
                              </IconButton>
                            </Stack>
                          ) : (
                            <Stack direction="row">
                              <IconButton
                                disabled={loading || (equipmentId && equipmentId !== equipment.id)}
                                aria-label={terms.common.modify}
                                sx={{
                                  color: '#3C3C3B',
                                  '&:focus': {
                                    backgroundColor: 'white',
                                    outline: 'none',
                                  },
                                }}
                                onClick={() => {
                                  setEditMode(true)
                                  setEquipmentId(equipment.id)
                                  setEquipmentCode(equipment.code.toString())
                                  setEquipmentLabel(equipment.libelle)
                                }}
                              >
                                <EditOutlinedIcon />
                              </IconButton>
                              <IconButton
                                disabled={loading || (equipmentId && equipmentId !== equipment.id)}
                                sx={{
                                  color: '#3C3C3B',
                                  '&:focus': {
                                    backgroundColor: 'white',
                                    outline: 'none',
                                  },
                                }}
                                aria-label={terms.common.remove}
                                onClick={() => removeEquipment(equipment.id)}
                              >
                                <DeleteForeverOutlinedIcon />
                              </IconButton>
                            </Stack>
                          )}
                      </Box>
                    ))}
    </Grid>
  )
}
