import { ReactElement } from 'react'
import _ from 'lodash'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import IconButton from '@mui/material/IconButton'
import '../station.scss'

  type Props = {
    gv: any;
    getImage: () => void;
  }

export default function GovVariationFrame({ gv, getImage }: Props): ReactElement {
  return (
    <div className="d-flex flex-column" style={{ height: '130px' }}>
      <IconButton
        sx={{
          color: '#616465',
          alignSelf: 'flex-end',
          position: 'absolute',
          top: '12px',
          right: '12px',
          borderRadius: '4px',
          background: '#F3F7FB',
        }}
        data-html2canvas-ignore
        aria-label="save"
        onClick={() => getImage()}
      >
        <SaveAltIcon />
      </IconButton>
      <h1 className="main-title font-weight-bold">{gv?.payload?.title}</h1>
      <h3 className="sub-title">
        {gv?.payload?.train_count?.gov_a}
      </h3>
      <h3 className="sub-title">
        {gv?.payload?.train_count?.gov_b}
      </h3>
    </div>
  )
}
