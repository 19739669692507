import { ReactElement } from 'react'
import { Chip } from '@mui/material'

import './filter.scss'

type Props = {
    btSelectAll: string;
    handleClick: () => void;
    disabled: boolean;
}

export default function BtSelectAll({
  btSelectAll, handleClick, disabled,
}: Props): ReactElement {
  return (
    <>
      <Chip
        disabled={disabled}
        className="filters-chips"
        label={btSelectAll}
        variant="outlined"
        onClick={() => handleClick()}
      />
    </>
  )
}
