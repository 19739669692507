import { RootState } from 'Store'
import terms from 'common/terms'
import Loading from 'components/Common/Backdrop/Backdrop'
import { format } from 'date-fns'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import normesService, { GOVMetrics, MetricsNormes } from 'reducers/services/normesService'
import { createFileName, useScreenshot } from 'use-react-screenshot'
import ConflictLineChart from './Component/ConflictLineChart'
import SelectStatus from './Component/SelectStatus'
import BarChartHeader from '../Common/BarChartHeader'
import Header from '../Common/Header'
import ConflictBarChart from './Component/ConflictBarChart'
import SelectContainer from './Component/SelectContainer'
import './style.scss'

interface Props {
  index: number;
  value: number;
  type: GOVMetrics;
}

const Conflict = ({ index, value, type }: Props): ReactElement => {
  const dispatch = useDispatch()
  const {
    normesList,
    isLoading,
    conflictsType,
    conflictBar,
    normesLineList,
    conflictEvolutionMetrics,
  } = useSelector((state: RootState) => state.normes)
  const { filters } = useSelector((state: RootState) => state.metrics)
  const [normesType, setNormesType] = useState<string[]>([])
  const [classeEvolution, setClasseEvolution] = useState<string[]>([])
  const [selectedStatus, setSelectedStatus] = useState<string>('all')
  const [statusOptions, setStatusOptions] = useState<string[]>([])
  const [isChangingStatus, setIsChangingStatus] = useState<boolean>(false)
  const { activeStation } = useSelector((state: RootState) => state.stations)
  const refBar = useRef<HTMLDivElement>(null)
  const refLine = useRef<HTMLDivElement>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [image, takeScreenshot] = useScreenshot()

  const normesSelected = normesList.filter((norme: MetricsNormes) => norme.board === terms.NormesRE.ConflictsGov.tab)

  useEffect(() => {
    dispatch(normesService.getConflictsType())
    dispatch(normesService.getMetricsConflictEvolutionList())
  }, [])

  useEffect(() => {
    const statusList = conflictEvolutionMetrics?.parameters.find(item => item.slug === 'conflict_status')?.values || []
    setStatusOptions(statusList)
  }, [conflictEvolutionMetrics])

  useEffect(() => {
    setNormesType(conflictsType.map(item => item.key))
  }, [conflictsType, normesList])

  const defineDefaultClasse = () => {
    const findClasse = normesLineList.filter(item => item.id === 'Espacement' || item.id === 'Réutilisation')
    if (!findClasse.length) {
      if (normesLineList.length > 1) {
        return setClasseEvolution([normesLineList[0].id, normesLineList[1].id])
      }
      return normesLineList.length ? setClasseEvolution([normesLineList[0].id]) : setClasseEvolution([])
    }

    return setClasseEvolution(findClasse.map(item => item.id) || [])
  }

  useEffect(() => {
    if (isChangingStatus) {
      setIsChangingStatus(false)
    } else {
      defineDefaultClasse()
    }
  }, [normesLineList])

  useEffect(() => {
    if (value === index) {
      if (normesSelected) {
        normesSelected.map(item => (
          dispatch(normesService.getMetricsNormes({
            uri: item?.uri.substring(
              item.uri.indexOf('/usage_reseau'),
            ).includes('conflict_distribution')
              ? item.uri.substring(item.uri.indexOf('/usage_reseau'))
              : '/usage_reseau/metrics/conflict_evolution/line-chart/',
            gov_a: item?.payload[type].gov_a.toString(),
            type_gov_a: type,
            metricType: terms.NormesRE.ConflictsGov.tab,
            filters,
            status: item?.uri.substring(
              item.uri.indexOf('/usage_reseau'),
            ).includes('conflict_distribution') ? '' : selectedStatus,
            board: item.board,
            dates: item?.payload[type]?.dates?.toString(),
          }))
        ))
      }
    }
  }, [normesList, type, filters, selectedStatus])

  const download = (
    currentImage, {
      name = `${activeStation.abv_traction}-${format(new Date(Date.now()), 'dd-MM-yyyy')}`, extension = 'jpeg',
    } = {},
  ) => {
    const a = document.createElement('a')
    a.href = currentImage
    a.download = createFileName(extension, name)
    a.click()
  }

  const getImage = ref => takeScreenshot(ref.current).then(download)

  const handleSelectStatus = (inputvalue: string) => {
    setIsChangingStatus(true)
    setSelectedStatus(inputvalue)
  }

  return (
    <div className="conflict">
      <Loading open={isLoading} />
      <div>
        <div className="conflict-graph" ref={refBar}>
          <Header
            title={terms.NormesRE.ConflictsGov.ConflictsMetrics.title}
          />
          <SelectContainer
            selectTypeLabel={terms.NormesRE.ConflictsGov.selectConflictType.type}
            options={conflictsType}
            selected={normesType}
            handleSelectType={setNormesType}
            refImage={refBar}
            handleClick={getImage}
          />
          <div className="bar-header">
            <BarChartHeader info={conflictBar.meta.info} />
          </div>
          <ConflictBarChart normesTypeSelected={normesType} />
        </div>

        <div className="conflict-graph line" ref={refLine}>
          <Header
            title={terms.NormesRE.ConflictsGov.ConflictsEvolution.title}
          />
          <SelectContainer
            selectTypeLabel={terms.NormesRE.ConflictsGov.selectConflictType.subType}
            options={normesLineList.map(item => ({
              key: item.id,
              value: item.id,
            }))}
            selected={classeEvolution}
            handleSelectType={setClasseEvolution}
            isSubType
            refImage={refLine}
            handleClick={getImage}
          >
            <div className="select" data-html2canvas-ignore>
              <SelectStatus
                options={statusOptions}
                value={selectedStatus}
                size="medium"
                handleChange={e => handleSelectStatus(e.target.value)}
              />
            </div>
          </SelectContainer>
          <ConflictLineChart
            normesClasseSelected={classeEvolution}
            isChangingStatus={isChangingStatus}
          />
        </div>
      </div>
    </div>
  )
}
export default Conflict
