/* eslint-disable max-len */
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { setError } from 'reducers/stations'
import Fade from '@mui/material/Fade'
import history from 'customHistory'
import terms from 'common/terms'
import { useDropzone } from 'react-dropzone'
import { customDarkButton } from 'common/CustomTheme'
import './configuration.scss'
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined'
import { ResponseError } from 'types'
import PathNames from 'common/PathNames'
import Snackbar from '../Common/SnackBar/Snackbar'

export default function ImageConfiguration(): ReactElement {
  const dispatch = useDispatch()
  const { error } = useSelector((state: RootState) => state.stations)

  const errorFound = error !== undefined

  const errorMessage = () => {
    switch (true) {
      case (error === undefined):
        return ''
        break
      case (error.status === 'dropRejected'):
        return "Veuillez essayer d'envoyer une image en jpg ou png jusqu'à 3 mb."
        break
      case (error.status === 'dropError'):
        return 'Une Erreur inconnue est survenue'
        break
      default:
        return 'Une Erreur inconnue est survenue'
    }
  }

  const dropRejected: ResponseError = {
    status: 'dropRejected',
    message: '',
  }
  const dropError: ResponseError = {
    status: 'dropError',
    message: '',
  }

  const skipStep = () => {
    history.push(PathNames.TrackConfig)
  }
  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    maxSize: 3145728,
    accept: {
      'image/': ['.jpeg', '.png', '.jpg'],
    },
    onDropAccepted: acceptedFile => {
      history.push({
        pathname: '/configuration-image-previsualisation',
        state: acceptedFile,
      })
    },
    onDropRejected: () => {
      dispatch(setError(dropRejected))
    },
    onError: () => {
      dispatch(setError(dropError))
    },
  })

  return (
    <main>
      <Fade timeout={400} in mountOnEnter unmountOnExit>
        <Container maxWidth={false} disableGutters sx={{ height: '100vh', backgroundColor: '#022e4f' }}>
          <Box sx={{ height: '68px' }} style={{ background: '#022e4f' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: '100%', width: '90%', margin: 'auto' }}
            >
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label="back"
                  onClick={() => history.push(PathNames.TrigramConfig)}
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
                <Typography sx={{ fontSize: '12px', color: 'white' }}>
                  {terms.StationsDashboard.ImageConfig.returnTopBt}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label="close"
                  onClick={() => history.push(PathNames.Home)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ height: '70%' }} style={{ background: '#022e4f' }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100%', width: '60%', margin: 'auto' }}
            >
              <Grid container item sx={{ justifyContent: 'center', width: '100%' }}>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: '900',
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  {terms.StationsDashboard.ImageConfig.imageConfigTitle}
                </Typography>
              </Grid>
              <Grid container item xs={12} sx={{ justifyContent: 'center', width: '100%' }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: '400', color: '#B6B6B6' }}
                >
                  {terms.common.infoChangeItLater}
                </Typography>
              </Grid>
              <Grid item xs={10} sm={8} md={6} lg={4} {...getRootProps()}>
                <input {...getInputProps()} />
                <p className="drop-zone">
                  <FileUploadIcon sx={{ fontSize: '90px', cursor: 'pointer' }} />
                  {terms.StationsDashboard.ImageConfig.selectFile}
                </p>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{ height: 'calc(30% - 68px)' }}
            style={{ background: '#022e4f' }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              sx={{ height: '100%', margin: 'auto', width: '70%' }}
            >
              <Grid item>
                <ThemeProvider theme={customDarkButton}>
                  <LoadingButton
                    onClick={() => skipStep()}
                    endIcon={<ArrowRightAltIcon />}
                    sx={{
                      width: '400px',
                      paddingY: '15px',
                      borderRadius: '30px',
                      color: 'white',
                    }}
                    variant="outlined"
                    disableElevation
                  >
                    {terms.StationsDashboard.ImageConfig.skipBt}
                  </LoadingButton>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Fade>
      <Snackbar message={errorMessage()} error={errorFound} />
    </main>
  )
}
