import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { RootState } from 'Store'
import { ColorButton } from 'common/CustomTheme'
import terms from 'common/terms'
import { format } from 'date-fns'
import {
  ReactElement, useEffect, useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createFileName, useScreenshot } from 'use-react-screenshot'
import normesService, { MetricsNormes } from 'reducers/services/normesService'
import Loading from 'components/Common/Backdrop/Backdrop'
import Header from '../Common/Header'
import BarChartHeader from '../Common/BarChartHeader'
import ReusabilityBarChart from './Component/ReusabilityBarChart'
import ReusabilityTable from './Component/ReusabilityTable'
import './style.scss'

interface Props {
  index: number;
  value: number;
  type: string;
}

const Reusability = ({ index, value, type }: Props): ReactElement => {
  const dispatch = useDispatch()
  const { activeStation } = useSelector((state: RootState) => state.stations)
  const {
    normesList,
    reusabilityBar,
    isLoading,
    isLoadingReutilityBar,
    isLoadingReutilityTable,
    isLoadingConflictLine,
    isLoadingConflictBar,
  } = useSelector((state: RootState) => state.normes)
  const { filters } = useSelector((state: RootState) => state.metrics)
  const [sortTable, setSortTable] = useState<string>('')
  const refBar = useRef<HTMLDivElement>(null)
  const normesSelected = normesList.filter((norme: MetricsNormes) => norme.board === terms.NormesRE.Reusability.tab)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [image, takeScreenshot] = useScreenshot()
  const download = (
    currentImage, {
      name = `${activeStation.abv_traction}-${format(new Date(Date.now()), 'dd-MM-yyyy')}`, extension = 'jpeg',
    } = {},
  ) => {
    const a = document.createElement('a')
    a.href = currentImage
    a.download = createFileName(extension, name)
    a.click()
  }

  const getImage = ref => takeScreenshot(ref.current).then(download)

  useEffect(() => {
    if (value === index) {
      if (normesSelected) {
        normesSelected.map(item => (
          dispatch(normesService.getMetricsNormes({
            uri: item?.uri.substring(
              item.uri.indexOf('/usage_reseau'),
            ),
            gov_a: item?.payload[type].gov_a.toString(),
            type_gov_a: type,
            metricType: terms.NormesRE.ConflictsGov.tab,
            filters,
            status: '',
            board: item.board,
            ...(sortTable && { sort: sortTable }),
          }))
        ))
      }
    }
  }, [normesList, type, filters, sortTable])

  return (
    <div className="reusability">
      <Loading open={
        isLoading
        || isLoadingConflictBar
        || isLoadingConflictLine
        || isLoadingReutilityBar
        || isLoadingReutilityTable
        }
      />
      <div ref={refBar}>
        <Header
          title={terms.NormesRE.Reusability.Header.title}
          subTitle={terms.NormesRE.Reusability.Header.subTitle}
        />
        <div className="actions">
          <ColorButton
            onClick={() => getImage(refBar)}
            startIcon={<FileDownloadOutlinedIcon />}
            data-html2canvas-ignore
          >
            {terms.common.jpegExport}
          </ColorButton>
        </div>
        <div>
          <BarChartHeader info={reusabilityBar.meta.info} />
          <ReusabilityBarChart data={reusabilityBar.data} />
        </div>
        <div data-html2canvas-ignore>
          <ReusabilityTable
            normesSelected={normesSelected}
            type={type}
            sortTable={sortTable}
            setSortTable={setSortTable}
          />
        </div>
      </div>
    </div>
  )
}

export default Reusability
