import { ReactElement } from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

type Props = {
  open: boolean;
}

export default function Loading({ open }: Props): ReactElement {
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: 100000 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
