/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable max-len */
import { ReactElement } from 'react'
import {
  addMonths, getMonth, getYear, subMonths,
} from 'date-fns'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

type Props = {
  customHeaderCount: number;
  changeMonth: Function;
  setLeftMonth: Function;
  changeYear: Function;
  lastClickedSide: string;
  monthDate: Date;
  minDate: Date;
  maxDate: Date;
  years: Array<number>;
}

export default function HeaderSelectYear({
  customHeaderCount, setLeftMonth, monthDate, changeMonth,
  minDate, maxDate, lastClickedSide, changeYear, years,
}: Props): ReactElement {
  return (
    <Select
      className="font-weight-bold"
      variant="standard"
      autoWidth
      size="small"
      disableUnderline
      sx={{ fontFamily: 'Avenir', fontSize: '13px', borderColor: 'white' }}
      value={getYear(monthDate)}
      onChange={e => {
        switch (true) {
          case (customHeaderCount === 0 && lastClickedSide === 'left'
          && +e.target.value === getYear(maxDate) && getMonth(monthDate) >= getMonth(maxDate)):
            changeYear(getYear(subMonths(maxDate, 1)))
            changeMonth(getMonth(subMonths(maxDate, 1)))
            setLeftMonth(getMonth(subMonths(maxDate, 1)))
            break

          case (customHeaderCount === 0 && lastClickedSide === 'right'
            && +e.target.value === getYear(maxDate) && getMonth(monthDate) >= getMonth(maxDate)):
            changeYear(getYear(maxDate))
            changeMonth(getMonth(maxDate))
            setLeftMonth(getMonth(subMonths(maxDate, 1)))
            break
          case (customHeaderCount === 1 && lastClickedSide === 'left'
            && +e.target.value === getYear(maxDate) && getMonth(monthDate) >= getMonth(maxDate)):
            changeYear(getYear(subMonths(maxDate, 1)))
            changeMonth(getMonth(subMonths(maxDate, 1)))
            setLeftMonth(getMonth(subMonths(maxDate, 1)))
            break
          case (customHeaderCount === 1 && lastClickedSide === 'right'
          && +e.target.value === getYear(maxDate) && getMonth(monthDate) >= getMonth(maxDate)):
            changeYear(getYear(maxDate))
            changeMonth(getMonth(maxDate))
            setLeftMonth(getMonth(subMonths(maxDate, 1)))
            break
          case (customHeaderCount === 1 && lastClickedSide === 'left'
            && +e.target.value === getYear(minDate) && getMonth(monthDate) >= getMonth(minDate)):
            changeYear(getYear(minDate))
            changeMonth(getMonth(minDate))
            setLeftMonth(getMonth(minDate))
            break
          case (customHeaderCount === 1 && lastClickedSide === 'right'
              && +e.target.value === getYear(minDate) && getMonth(monthDate) >= getMonth(minDate)):
            changeYear(getYear(addMonths(minDate, 1)))
            changeMonth(getMonth(addMonths(minDate, 1)))
            setLeftMonth(getMonth(minDate))
            break
          case (customHeaderCount === 0):
            changeYear(+e.target.value)
            changeMonth(lastClickedSide === 'left' ? getMonth(monthDate) : getMonth(addMonths(monthDate, 1)))
            setLeftMonth(lastClickedSide === 'left' ? getMonth(monthDate) : getMonth(subMonths(monthDate, 1)))
            break
          case (customHeaderCount === 1):
            changeYear(+e.target.value)
            changeMonth(lastClickedSide === 'right' ? getMonth(monthDate) : getMonth(addMonths(monthDate, 1)))
            setLeftMonth(lastClickedSide === 'right' ? getMonth(subMonths(monthDate, 1)) : getMonth(monthDate))
            break
          default:
            changeYear(+e.target.value)
            changeYear(+e.target.value)
            changeMonth(getMonth(monthDate))
            setLeftMonth(getMonth(monthDate))
            break
        }
      }}
    >
      {years.map(year => (
        <MenuItem
          key={year}
          value={year}
          sx={{ fontFamily: 'Avenir', fontSize: '13px' }}
        >
          {year}
        </MenuItem>
      ))}
    </Select>
  )
}
