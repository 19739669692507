/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'

export enum GOVMetrics {
  CONCEPTION = 'Conception (COTECH GOV)',
  THEORIQUE = 'Théorique (J-1)',
  REEL = 'Réel (J)',
}

export type MetricsType = {
  slug: string;
  parameters: {
    name: string;
    slug: string;
    type: string;
    required: boolean;
    values: string[];
    // eslint-disable-next-line camelcase
    missing_error: string;
  }[];
  // eslint-disable-next-line camelcase
  used_data: string[];
};

export type NormesJsa = {
  gare : {
    'Conception (COTECH GOV)': string;
    'Réel (J)': string;
    'Théorique (J-1)': string;
  };
  metrics: {
    board: string;
    board_title: string;
    title: string;
    uri: string;
    payload: MetricsNormes[];
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  missing_days: any;
};

export type BarNormesMetrics = {
  x: string;
  'En cours': number;
  'Faux conflit': number;
  Maintenu: number;
  'Non traité': number;
}

export type MetricsNormes = {
  board: string;
  board_title: string;
  title: string;
  uri: string;
  payload: {
    'Conception (COTECH GOV)': {
      gov_a: string;
    };
    'Réel (J)': {
      gov_a: string;
    };
    'Théorique (J-1)': {
      gov_a: string;
    };
  };
};

export type ConflictsType = {
  value: string;
  key: string;
}

type ParamsGetNormesJSA = {
  dates: string;
  gare_id: string;
}

type ParamsGetMetricsNormes = {
  uri: string;
  gov_a: string;
  type_gov_a: string;
  metricType: string;
  filters: any;
  status: string;
  board: string;
  sort?: string;
  dates?: string;

}

export type NormesLineChart = {
  id: string;
  data: {
    x: string;
    y: number;
  }[];
}

export type ReusabilityBar = {
  meta: {
    bar_mode: string;
    info: {
      label: string;
      value: number | null;
    }[];
  };
  data: ReusabilityBarData[];
}

export type ReusabilityBarData = {
  x: string;
  'Non respect du temps minimal RE': number;
  'Non respect du temps robuste RE': number;
  'Norme minimale non informé': number;
  'Norme robuste non informé': number;
  'Temps minimal RE respecté': number;
  'Temps robuste RE respecté': number;
}

export type ConflictBar = {
  meta: {
    bar_mode: string;
    info: {
      label: string;
      value: string;
    }[];
  };
  data : ReusabilityBarData[];
}

export type ReusabilityDataTable = {
  key: string;
  value: {
    label: string;
    value: number;
    additional_value: string;
  }[];
}

export type ReusabilityTable = {
  meta: {
    index_label: string;
  };
  data: ReusabilityDataTable[];
}

export type ParamsGetDetailReusability = {
  key: string;
  label: string;
  gov_a: string;
  sorting?: string;
}

export type ParamsGetReusabilityHistoric = {
  gov_a: string;
  station_id: string;
  arrival: string;
  departure: string;
  sorting?: string;
}

export type ParamsDownloadExcel = {
  type: string;
  gov_a: string;
  key?: string;
  label?: string;
  station_id?: string;
  arrival?: string;
  departure?: string;
}

const getNormesJsa = createAsyncThunk(
  'normes/getNormesJsa',
  async (params: ParamsGetNormesJSA, thunkApi) => {
    try {
      const response = await get('/gov/normes/jsa', params)
      return response
    } catch (error) {
      return thunkApi.rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      })
    }
  },
)

const getMetricsNormes = createAsyncThunk(
  'normes/getMetricsNormes',
  async (params: ParamsGetMetricsNormes, thunkApi) => {
    try {
      const response = await get(params.uri, {
        ...(params.gov_a && { gov_a: params.gov_a }),
        balance_type: params.filters?.balance_type.toString() && params.filters?.balance_type.toString() !== '' ? params.filters.balance_type.toString() : null,
        circulation_type: params.filters?.circulation_type.toString() && params.filters?.circulation_type.toString() !== '' ? params.filters.circulation_type.toString() : [],
        multi_time_range: params.filters?.multi_time_range.toString() && params.filters?.multi_time_range.toString() !== '' ? params.filters.multi_time_range.toString() : null,
        track: params.filters?.track.toString() && params.filters?.track.toString() !== '' ? params.filters?.track.toString() : null,
        train_number_range: params.filters?.train_number_range.toString() && params.filters?.train_number_range.toString() !== '' ? params.filters.train_number_range.toString() : null,
        train_number: params.filters?.train_number.toString() && params.filters?.train_number.toString() !== '' ? params.filters.train_number.toString() : null,
        ori_dest: params.filters?.ori_dest.toString() && params.filters?.ori_dest.toString() !== '' ? params.filters.ori_dest.toString() : null,
        material_code: params.filters?.equipment.toString() && params.filters?.equipment.toString() !== '' ? params.filters.equipment.toString() : null,
        ...(params.status !== 'all' && { conflict_status: params.status }),
        ...(params.sort && { sorting: params.sort }),
        ...(params.dates && { dates: params.dates }),

      })
      return response
    } catch (error) {
      return thunkApi.rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      })
    }
  },
)

const getConflictsType = createAsyncThunk(
  'normes/getConflictTypes',
  async (_, thunkApi) => {
    try {
      const response = await get('/usage_reseau/gov/conflicts_matching/')
      return response
    } catch (error) {
      return thunkApi.rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      })
    }
  },
)

const getMetricsConflictEvolutionList = createAsyncThunk(
  'normes/getMetricsList',
  async (_, thunkApi) => {
    try {
      const response = await get('/usage_reseau/metrics/')
      return response.find(item => item.slug === 'conflict_evolution')
    } catch (error) {
      return thunkApi.rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      })
    }
  },
)

const getDetailReusability = createAsyncThunk(
  'normes/getDetailReusability',
  async (params: ParamsGetDetailReusability, thunkApi) => {
    try {
      const response = await get('/usage_reseau/metrics/reutilisation_balances_details/indexed-table-collapsable/', params)
      return response
    } catch (error) {
      return thunkApi.rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      })
    }
  },
)

const getDetailReusabilityHistory = createAsyncThunk(
  'normes/getDetailReusabilityHistory',
  async (params: ParamsGetReusabilityHistoric, thunkApi) => {
    try {
      const response = await get('/usage_reseau/metrics/reutilisation_balance_historic/indexed-table/', params)
      return response
    } catch (error) {
      return thunkApi.rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      })
    }
  },
)

const downloadExcel = createAsyncThunk(
  'normes/downloadExcel',
  async (params: ParamsDownloadExcel, thunkApi) => {
    try {
      const response = await get(`/usage_reseau/metrics/${params.type}/table-xlsx/`, {
        gov_a: params.gov_a,
        ...(params.key && { key: params.key }),
        ...(params.label && { label: params.label }),
        ...(params.station_id && { station_id: params.station_id }),
        ...(params.arrival && { arrival: params.arrival }),
        ...(params.departure && { departure: params.departure }),
      }, undefined, { responseType: 'blob' })
      return response
    } catch (error) {
      return thunkApi.rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      })
    }
  },
)

const generateTickBarValues = maxValue => {
  if (maxValue === -Infinity) return []
  let increment = 1
  if (maxValue > 10) increment = 2
  if (maxValue >= 50) increment = 5
  if (maxValue >= 100) increment = 10
  if (maxValue >= 200) increment = 20
  if (maxValue >= 500) increment = 50
  if (maxValue >= 1000) increment = 100
  const values = []
  for (let i = 0; i <= maxValue; i += increment) {
    values.push(i)
  }
  return values
}

const normesService = {
  getNormesJsa,
  getMetricsNormes,
  getConflictsType,
  getMetricsConflictEvolutionList,
  getDetailReusability,
  downloadExcel,
  getDetailReusabilityHistory,
  generateTickBarValues,
}

export default normesService
