/* eslint-disable react/no-array-index-key */
import { ReactElement, useEffect } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import SimpleInput from 'components/Common/Input/SimpleInput'
import { Grid, IconButton } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import { updateOriginDestinationFields, updateOriginDestion } from 'reducers/filters'
import { OriginDestination } from 'reducers/types'
import InputFilterTitle from './InputFilterTitle'
import { getOriginDestination } from './utils'

export default function OriginDestinationFilter(): ReactElement {
  const dispatch = useDispatch()
  const { originDestinationFields } = useSelector((state: RootState) => state.filters)
  const { filters } = useSelector((state: RootState) => state.metrics)

  useEffect(() => {
    if (filters && originDestinationFields.length === 0) {
      dispatch(updateOriginDestinationFields(getOriginDestination(filters.ori_dest)))
    }
  }, [filters, originDestinationFields])

  useEffect(() => {
    if (originDestinationFields.length !== 0) {
      const originDestination: string[] = []
      originDestinationFields.forEach(field => {
        if (field.origin !== '' || field.destination !== '') {
          originDestination.push(field.origin.concat('-').concat(field.destination))
        }
      })
      dispatch(updateOriginDestion(originDestination))
    }
  }, [originDestinationFields])

  const addoriginDestinationFields = () => {
    dispatch(updateOriginDestinationFields([...originDestinationFields, {
      index: originDestinationFields.length,
      origin: '',
      destination: '',
    }]))
  }

  const handleOriginChange = (value: string, index: number) => {
    const newOrigin = originDestinationFields.map((field: OriginDestination) => (field.index !== index
      ? field : { index, origin: value, destination: field.destination }))
    dispatch(updateOriginDestinationFields(newOrigin))
  }

  const handleDestinationChange = (value: string, index: number) => {
    const newDestination = originDestinationFields.map((field: OriginDestination) => (field.index !== index
      ? field : { index, origin: field.origin, destination: value }))
    dispatch(updateOriginDestinationFields(newDestination))
  }

  const deleteOriginDestinationFields = (index: number) => {
    dispatch(updateOriginDestinationFields(originDestinationFields.filter(field => field.index !== index)
      .map(field => (field.index < index ? field
        : { index: field.index - 1, origin: field.origin, destination: field.destination }))))
  }

  return (
    <>
      <InputFilterTitle title={terms.Filters.originDestination} onClick={addoriginDestinationFields} />
      {originDestinationFields.map((field: OriginDestination, index: number) => (
        <Grid container key={index} className="mb-3">
          <Grid item xs={5.5}>
            <SimpleInput
              label={terms.Filters.origin}
              value={field.origin}
              index={index}
              onChange={e => handleOriginChange(e.target.value, index)}
              inputStyle="filters-input"
            />
          </Grid>
          <Grid item xs={5.5}>
            <SimpleInput
              label={terms.Filters.destination}
              value={field.destination}
              index={index}
              onChange={e => handleDestinationChange(e.target.value, index)}
              inputStyle="filters-input"
            />
          </Grid>
          {originDestinationFields.length > 1 && (
          <Grid item xs={1} className="delete-field-btn">
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => deleteOriginDestinationFields(index)}
              aria-label="delete"
              sx={{ marginRight: 0 }}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
          )}
        </Grid>
      ))}
    </>
  )
}
