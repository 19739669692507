/* eslint-disable max-len */
/* eslint-disable camelcase */
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { animated } from '@react-spring/web'
import _ from 'lodash'
import { ResponsivePie } from '@nivo/pie'
import { def, fill } from '../utils'
import '../station.scss'

interface PieChartData {
  id: number;
  label: string;
  value: number;
  hover_info: string;
}

  type Props = {
    gv: any;
  }

export default function GovVariationPieChart({ gv }: Props): ReactElement {
  const { monochrome } = useSelector((state: RootState) => state.stations)

  return (
    <div
      style={{ height: '50vh', width: '100%' }}
      className="my-3"
    >
      <ResponsivePie
        data={gv?.payload['pie-chart']}
        margin={{
          top: 10, right: 70, bottom: 10, left: 80,
        }}
        innerRadius={0}
        activeOuterRadiusOffset={20}
        borderWidth={0}
        padAngle={1}
        borderColor="#ffffff"
        arcLinkLabelsSkipAngle={15}
        arcLinkLabelsOffset={-10}
        arcLinkLabelsStraightLength={5}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLabelsSkipAngle={15}
        arcLabel={d => `${d.value} %`}
        arcLabelsComponent={({ datum, label, style }) => (
          <animated.g transform={style.transform} style={{ pointerEvents: 'none' }}>
            <text
              textAnchor="middle"
              dominantBaseline="central"
              fill="white"
              style={{
                fontSize: 14,
                fontWeight: 800,
                textShadow: '1px 0 black, 0 1px black, -1px 0 black, 0 -1px black,-1px -1px black, -1px 1px black, 1px -1px black, 1px 1px black',
              }}
            >
              {label}
            </text>
          </animated.g>
        )}
        defs={def(monochrome)}
        fill={fill}
        tooltip={e => (
          <div
            style={{
              background: 'black',
              padding: '9px 12px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              opacity: '0.90',
              color: '#FFF',
              textAlign: 'center',
            }}
          >
            <div style={{
              fontSize: '1rem',
              fontWeight: '900',
            }}
            >
              {e.datum.id}
            </div>
            <div style={{
              fontSize: '1rem',
              fontWeight: '400',
            }}
            >
              {e.datum.value}
              {' '}
              %
              <div className="secondary-hover">
                {((e.datum.data) as PieChartData)?.hover_info}
              </div>
            </div>
          </div>
        )}
      />
    </div>
  )
}
