/* eslint-disable max-len */
import { ReactElement } from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'
import ReactHtmlParser from 'react-html-parser'

type Props = {
text?: string;
position?: string;
placement?: any;
}

const InfoTooltip = styled(({ className, placement, ...propss }: TooltipProps) => (
  <Tooltip {...propss} classes={{ popper: className }} placement={placement} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF',
    fontFamily: 'Avenir',
    fontWeight: 400,
    color: '#3C3C3B',
    maxWidth: 800,
    fontSize: '16px',
    border: '1px solid #4B80F0',
    boxShadow: '3px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
  },
}))
export default function InfoToolTip({ text, position, placement }: Props): ReactElement {
  return (
    <InfoTooltip
      placement={placement}
      title={(
        <>
          {text === '' ? (
            <div className="d-flex flex-column p-3">
              <span>
                Attention, un équilibre peut avoir subi
                {' '}
                <strong>plusieurs changements d’état.</strong>
              </span>
              <span>Par exemple, un équilibre peut avoir subi à la fois un dévoiement ET un changement de composition.</span>
              <span>
                Pour retrouver le pourcentage d’un état sur la légende, il faut sommer les pourcentages des états détaillés contenant cet état
                sur le graphique ci-haut. Pour le cas des dévoiements par exemple :

              </span>

              <span>
                <strong>
                  % Dévoiement = % Dévoiement (“simple”) + % Changement d’équilibre ET Dévoiement + % Dévoiement
                  ET Changement de composition + % Changement d’équilibre ET Dévoiement ET Changement de composition
                </strong>

              </span>

            </div>
          ) : (ReactHtmlParser(text))}
        </>
)}
    >
      <IconButton
        style={{ right: 0, bottom: 0 }}
        className={position}
        data-html2canvas-ignore
      >
        <InfoIcon style={{ color: '#4B80F0' }} />
      </IconButton>
    </InfoTooltip>
  )
}

InfoToolTip.defaultProps = {
  position: 'position-relative',
  placement: 'bottom',
  text: '',
}
