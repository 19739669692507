import { Table, TableContainer } from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import MissingData from 'components/Station/MissingData'
import moment from 'moment'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import normesService, { MetricsNormes } from 'reducers/services/normesService'
import ReoccupationTableHeader from './ReoccupationTableHeader'
import TableHeader from './Table/TableHeader'
import CustomTableBody from './Table/CustomTableBody'

interface Props {
  normesSelected: MetricsNormes[];
  type: string;
  sortTable: string;
  setSortTable: (value: string) => void;
}

const ReoccupationTable = ({
  normesSelected, type, sortTable, setSortTable,
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const {
    reoccupationTable,
    isLoading,
    errorReoccupationTable,
    isLoadingReoccupationTable,
  } = useSelector((state: RootState) => state.normes)
  const dataTable = reoccupationTable.data
  const columns = () => {
    if (dataTable.length === 0) return []
    const result = dataTable[0].value.map(item => item.label)
    result.unshift(reoccupationTable.meta.index_label)
    return result
  }

  const downloadExcel = async (metricType: string) => {
    const response = await dispatch(normesService.downloadExcel({
      type: metricType,
      gov_a: normesSelected[0]?.payload[type].gov_a.toString(),
    })) as unknown as { type: string; payload: ArrayBuffer }

    if (response.type.endsWith('fulfilled')) {
      const blob = new Blob(
        [response.payload],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      )
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${metricType}-${moment().format('L')}.xlsx`

      link.click()
    }
  }

  return (
    <>
      <div className="reoccupation-table">
        <ReoccupationTableHeader onDownload={() => downloadExcel('reoccupation_detail')} />
        <TableContainer sx={{ maxWidth: 1050, margin: 'auto', backgroundColor: '#fff' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHeader
              data={columns()}
              sortTable={sortTable}
              setSortTable={setSortTable}
            />
            <CustomTableBody
              dataTable={reoccupationTable}
            />
          </Table>
        </TableContainer>
      </div>
      {
        (dataTable.length === 0) && !isLoading && !isLoadingReoccupationTable && (
          <div
            className="missing-data no-data-table"
          >
            <MissingData
              message={errorReoccupationTable ? errorReoccupationTable.message.detail : terms.NormesRE.noData}
            />
          </div>
        )
      }
    </>
  )
}

export default ReoccupationTable
