import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import EquipmentService from 'reducers/services/equipmentsService'
import { ResponseError } from 'types'
import { EquipmentCodes, Equipment } from './types'

const initialState: EquipmentCodes = {
  equipmentsList: [],
  loading: false,
  error: undefined,
  updateStatus: false,
}

export const equipmentSlice = createSlice({
  name: 'equipments',
  initialState,

  reducers: {
    updateEquipmentList: (state, action: PayloadAction<Array<Equipment>>) => {
      state.equipmentsList = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.error = action.payload
    },
    setUpdateStatus: (state, action: PayloadAction<boolean>) => {
      state.updateStatus = action.payload
    },
  },

  extraReducers: builder => {
    builder.addCase(EquipmentService.getEquipmentsList.pending, state => {
      state.loading = true
    })
    builder.addCase(EquipmentService.getEquipmentsList.fulfilled, (state, action) => {
      state.equipmentsList = action.payload
      state.loading = false
    })
    builder.addCase(EquipmentService.getEquipmentsList.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })
    builder.addCase(EquipmentService.addEquipment.pending, state => {
      state.loading = true
    })
    builder.addCase(EquipmentService.addEquipment.fulfilled, state => {
      state.loading = false
      state.updateStatus = true
    })
    builder.addCase(EquipmentService.addEquipment.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(EquipmentService.patchEquipment.pending, state => {
      state.loading = true
    })
    builder.addCase(EquipmentService.patchEquipment.fulfilled, state => {
      state.loading = false
      state.updateStatus = true
    })
    builder.addCase(EquipmentService.patchEquipment.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(EquipmentService.deleteEquipment.pending, state => {
      state.loading = true
    })
    builder.addCase(EquipmentService.deleteEquipment.fulfilled, state => {
      state.loading = false
      state.updateStatus = true
    })
    builder.addCase(EquipmentService.deleteEquipment.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {
  updateEquipmentList, setLoading, setUpdateStatus, setError,
} = equipmentSlice.actions

export default equipmentSlice.reducer
