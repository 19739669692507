import terms from 'common/terms'
import { ReactElement } from 'react'

import './style.scss'

interface Props {
  title: string;
  subTitle?: string;
}

const Header = ({ title, subTitle }: Props): ReactElement => (
  <header className="graph-title">
    <h1>{title}</h1>
    <h2>{subTitle}</h2>
  </header>
)

Header.defaultProps = {
  subTitle: terms.NormesRE.ConflictsGov.ConflictsMetrics.subTitle,
}

export default Header
