/* eslint-disable no-case-declarations */
import { ReactElement, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { setSortingBy } from 'reducers/metrics'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import nextId from 'react-id-generator'
import _ from 'lodash'
import TableSortLabel from '@mui/material/TableSortLabel'
import TablePagination from '@mui/material/TablePagination'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import '../station.scss'

type Props = {
  table: any;
  rowsPerPage: number;
  initialPage: number;
}

export default function TrainsTable({ table, rowsPerPage, initialPage }: Props): ReactElement {
  const dispatch = useDispatch()
  const tableRef = useRef<HTMLTableElement>(null)
  const { sortingBy } = useSelector((state: RootState) => state.metrics)
  const [page, setPage] = useState(initialPage)

  const handleChangePage = (event: unknown, newPage: number) => {
    if (tableRef.current) {
      tableRef.current.scrollTo(0, 0)
    }
    setPage(newPage)
  }

  const sortHandler = event => {
    const sorting: any = sortingBy.filter(v => v.name !== table.table.name)
    switch (event) {
      case 'Arrivée':
        sorting.push({
          name: table.table.name,
          value: (sortingBy.filter(v => v.name === table.table.name).length !== 0
           && (sortingBy.filter(v => v.name === table.table.name)[0])?.value === 'arrivee')
            || sortingBy.filter(v => v.name === table.table.name).length === 0
            ? '-arrivee' : 'arrivee',
        })
        dispatch(setSortingBy(sorting))
        break
      default:
        sorting.push({
          name: table.table.name,
          value: (sortingBy.filter(v => v.name === table.table.name).length !== 0
           && (sortingBy.filter(v => v.name === table.table.name)[0])?.value === 'depart')
           || sortingBy.filter(v => v.name === table.table.name).length === 0 ? '-depart' : 'depart',
        })
        dispatch(setSortingBy(sorting))
        break
    }
  }

  return (
    <>
      <Box sx={{ padding: '24px 4px' }}>
        <div className="mb-3 pl-2">
          {table.train_count.gov_a}
        </div>
        <TableContainer
          key={table.name}
          sx={{ maxHeight: '50vh', boxShadow: 0 }}
          component={Paper}
          ref={tableRef}
          className="border border-bottom-0"
        >
          <Table
            sx={{ minWidth: '30%', boxShadow: 0 }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead sx={{ boxShadow: 0 }}>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align="center"
                  key={nextId()}
                  className="table-header border-0"
                >
                  {table.table.name}
                </TableCell>
              </TableRow>
              <TableRow>
                {table.table.headers.map(header => (
                  <TableCell
                    key={nextId()}
                    className="table-subheader table-cell-border"
                  >
                    <TableSortLabel
                      active
                      onClick={() => sortHandler(header)}
                      IconComponent={() => <UnfoldMoreIcon />}
                    >
                      {header}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {table.table.values.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(row => (
                <TableRow hover key={nextId()}>
                  {row?.map(cell => (
                    <TableCell key={nextId()} align="left">{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>

          </Table>
        </TableContainer>
        <div className="mt-4">
          <TablePagination
            rowsPerPageOptions={[8]}
            component="div"
            count={table.table.values.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            labelDisplayedRows={({ count }) => `${page + 1} / ${Math.ceil(count / rowsPerPage)}`}
            sx={{
              '.MuiToolbar-root': {
                display: 'flex',
                justifyContent: 'center',
                paddingLeft: '0',
              },
              '.MuiTablePagination-spacer': {
                flex: 'none',
              },
              '.MuiTablePagination-selectLabel': {
                order: '3',
              },
              '.MuiInputBase-root': {
                order: '4',
              },
              '.MuiButtonBase-root': {
                border: '1px solid #E6EAEE',
                borderRadius: '8px',
                marginRight: '8px',
              },
              '.MuiTablePagination-select': {
                border: '1px solid #E6EAEE',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                minHeight: '32px !important',
              },
            }}
          />
        </div>
      </Box>
    </>
  )
}
