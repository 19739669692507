/* eslint-disable react/no-array-index-key */
import { ReactElement, useEffect } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Grid, IconButton } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import { toggleDisplayRequiredNotice, updateTimeRange, updateTimeRangeFields } from 'reducers/filters'
import { TimeRange } from 'reducers/types'
import CostumTimePicker from 'components/Common/TimePicker/CostumTimePicker'
import InputFilterTitle from './InputFilterTitle'
import { getTimeFormat, getTimeRange } from './utils'

export default function TimeFilter(): ReactElement {
  const dispatch = useDispatch()
  const { timeRangeFields, displayRequiredNotice } = useSelector((state: RootState) => state.filters)
  const { filters } = useSelector((state: RootState) => state.metrics)

  useEffect(() => {
    if (filters && timeRangeFields.length === 0) {
      dispatch(updateTimeRangeFields(getTimeRange(filters.multi_time_range)))
    }
  }, [filters, timeRangeFields])

  useEffect(() => {
    if (timeRangeFields.length !== 0 && timeRangeFields.filter(range => range.startTime === null).length >= 1
     && timeRangeFields.filter(range => range.endTime === null).length >= 1 && displayRequiredNotice) {
      dispatch(toggleDisplayRequiredNotice(false))
    }
  }, [timeRangeFields])

  useEffect(() => {
    if (timeRangeFields.length !== 0) {
      const ranges: string[] = []
      timeRangeFields.forEach((range: TimeRange) => {
        if (range.startTime !== null && range.endTime !== null) {
          const timeFormat = getTimeFormat(range)
          ranges.push(timeFormat.startHours.concat(':').concat(timeFormat.startMinutes).concat(':00').concat('-')
            .concat(timeFormat.endHours)
            .concat(':')
            .concat(timeFormat.endMinutes)
            .concat(':59'))
        }
      })
      dispatch(updateTimeRange(ranges))
    }
  }, [timeRangeFields])

  const addTimeRangeFields = () => {
    dispatch(updateTimeRangeFields([...timeRangeFields,
      { index: timeRangeFields.length, startTime: null, endTime: null }]))
  }

  const handleStartTime = (value: Date, index: number) => {
    const newTimeRange = timeRangeFields.map((range: TimeRange) => (range.index !== index
      ? range : { index, startTime: value, endTime: range.endTime }))
    dispatch(updateTimeRangeFields(newTimeRange))
  }

  const handleEndTime = (value: Date, index: number) => {
    const newTimeRange = timeRangeFields.map((range: TimeRange) => (range.index !== index
      ? range : { index, startTime: range.startTime, endTime: value }))
    dispatch(updateTimeRangeFields(newTimeRange))
  }

  const deletetimeRangeFields = (index: number) => {
    dispatch(updateTimeRangeFields(timeRangeFields.filter(range => range.index !== index).map(range => (range.index
       < index ? range : { index: range.index - 1, startTime: range.startTime, endTime: range.endTime }))))
  }

  return (
    <>
      <InputFilterTitle title={terms.Filters.timeRange} onClick={addTimeRangeFields} />
      {timeRangeFields.map((range: TimeRange, index: number) => (
        <Grid container key={index} className="mb-3">
          <Grid item xs={5.5}>
            <CostumTimePicker
              label={terms.Filters.startTime}
              value={range.startTime}
              index={index}
              onChange={v => handleStartTime(v, index)}
              inputStyle="filters-time-input"
              warningMessage={displayRequiredNotice && range.startTime === null && range.endTime !== null
                ? terms.Filters.requiresStartTime : ''}
            />
          </Grid>
          <Grid item xs={5.5}>
            <CostumTimePicker
              label={terms.Filters.endTime}
              value={range.endTime}
              index={index}
              onChange={v => handleEndTime(v, index)}
              inputStyle="filters-time-input"
              warningMessage={displayRequiredNotice && range.endTime === null && range.startTime !== null
                ? terms.Filters.requiresEndTime : ''}
            />
          </Grid>
          {timeRangeFields.length > 1 && (
          <Grid item xs={1} className="delete-field-btn">
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => deletetimeRangeFields(index)}
              aria-label="delete"
              sx={{ marginRight: 0 }}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
          )}
        </Grid>
      ))}
    </>
  )
}
