/* eslint-disable max-len */
import { ReactElement, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { OutlineButton } from 'common/CustomTheme'
import { updateFilterArrival } from 'reducers/metrics'
import { FiltersDelay } from 'reducers/types'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Fade from '@mui/material/Fade'
import terms from 'common/terms'
import Button from '@mui/material/Button'
import Badge from '@mui/material/Badge'

const badgeStyle = {
  '& .MuiBadge-badge': {
    color: 'white',
    backgroundColor: '#FC5130',
    right: '15px',
    top: '8px',
  },
}

export default function DelayArrivalFilter(): ReactElement {
  const dispatch = useDispatch()
  const {
    filterArrival, errorDetailVariationCode,
  } = useSelector((state: RootState) => state.metrics)
  const [anchorElArrival, setAnchorElArrival] = useState<null | HTMLElement>(null)
  const [localFilterArrival, setLocalFilterArrival] = useState('all1')
  const [localFilterArrivalTime, setLocalFilterArrivalTime] = useState('')
  const [localFilterArrivalTimeV2, setLocalFilterArrivalTimeV2] = useState('')
  const openFilterArrival = Boolean(anchorElArrival)

  useEffect(() => {
    if (filterArrival.operation === 'all') {
      setAnchorElArrival(null)
      setLocalFilterArrival('all1')
      setLocalFilterArrivalTime('')
      setLocalFilterArrivalTimeV2('')
    } else {
      setAnchorElArrival(null)
      setLocalFilterArrival(filterArrival.operation)
      setLocalFilterArrivalTime(filterArrival.value1)
      setLocalFilterArrivalTimeV2(filterArrival.value2)
    }
  }, [])

  const handleClickArrival = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElArrival(event.currentTarget)
  }
  const handleCloseArrival = () => {
    if ((filterArrival.operation !== localFilterArrival) && filterArrival.operation !== 'all') {
      setAnchorElArrival(null)
      setLocalFilterArrival(filterArrival.operation)
      setLocalFilterArrivalTime(filterArrival.value1)
      setLocalFilterArrivalTimeV2(filterArrival.value2)
    } else if (localFilterArrival === 'all') {
      setAnchorElArrival(null)
      setLocalFilterArrival('all1')
      setLocalFilterArrivalTime('')
      setLocalFilterArrivalTimeV2('')
    } else {
      setAnchorElArrival(null)
      setLocalFilterArrival('all1')
      setLocalFilterArrivalTime('')
      setLocalFilterArrivalTimeV2('')
      const filter = {
        operation: 'all',
        value1: '',
        value2: '',
      }
      dispatch(updateFilterArrival(filter))
    }
  }

  const handleCloseModal = () => {
    if (filterArrival.operation === 'all') {
      setAnchorElArrival(null)
      setLocalFilterArrival('all1')
      setLocalFilterArrivalTime('')
      setLocalFilterArrivalTimeV2('')
    } else {
      setAnchorElArrival(null)
      setLocalFilterArrival(filterArrival.operation)
      setLocalFilterArrivalTime(filterArrival.value1)
      setLocalFilterArrivalTimeV2(filterArrival.value2)
    }
  }

  const handleChangeFilterArrival = event => {
    if (event.target.value !== filterArrival.operation) {
      setLocalFilterArrival(event.target.value)
      setLocalFilterArrivalTime('')
      setLocalFilterArrivalTimeV2('')
    } else {
      setLocalFilterArrival(event.target.value)
      setLocalFilterArrivalTime(filterArrival.value1)
      setLocalFilterArrivalTimeV2(filterArrival.value2)
    }
  }

  const applyFilterArrival = () => {
    setAnchorElArrival(null)
    const filter = {
      operation: localFilterArrival,
      value1: localFilterArrivalTime,
      value2: localFilterArrivalTimeV2,
    }
    dispatch(updateFilterArrival(filter))
  }

  const onChangeArrivaltime = e => {
    const regex = /^[0-9\b]+$/
    if (e.target.value === '' || regex.test(e.target.value)) {
      setLocalFilterArrivalTime(e.target.value)
    }
  }

  const onChangeArrivaltimeV2 = e => {
    const regex = /^[0-9\b]+$/
    if (e.target.value === '' || regex.test(e.target.value)) {
      setLocalFilterArrivalTimeV2(e.target.value)
    }
  }

  const arrivalBadge = (e: FiltersDelay) => {
    switch (e.operation) {
      case 'arrival_delay_gte':
        return `≥ ${e.value1}`
        break
      case 'arrival_delay_lte':
        return `≤ ${e.value1}`
        break
      case 'arrival_delay_equal':
        return `= ${e.value1}`
        break
      case 'between_delay':
        return `[${e.value1} , ${e.value2}]`
        break
      default:
        return ''
    }
  }

  return (
    <>
      <Badge badgeContent={arrivalBadge(filterArrival)} color="primary" className="ml-auto" sx={badgeStyle} invisible={filterArrival.operation === 'all'}>
        <OutlineButton disabled={errorDetailVariationCode?.status === 400} onClick={handleClickArrival}>{terms.VariationDetail.lateOnArrivalFilterBtn}</OutlineButton>
      </Badge>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorElArrival}
        open={openFilterArrival}
        onClose={handleCloseModal}
        TransitionComponent={Fade}
      >
        <MenuItem
          disableRipple
          focusVisibleClassName="focus-visible"
          sx={{
            '&:hover': {
              backgroundColor: '#FFF !important',
            },
          }}
        >
          <Select
            value={localFilterArrival}
            label={terms.VariationDetail.menuItemGovToCompare}
            onChange={handleChangeFilterArrival}
            className="detail-variation-select w-100"
          >
            <MenuItem disabled value="all1"><em>{terms.VariationDetail.delayFilters.operationType}</em></MenuItem>
            <MenuItem value="arrival_delay_gte">{terms.VariationDetail.delayFilters.greaterThan}</MenuItem>
            <MenuItem value="arrival_delay_lte">{terms.VariationDetail.delayFilters.lowerThan}</MenuItem>
            <MenuItem value="arrival_delay_equal">{terms.VariationDetail.delayFilters.equalTo}</MenuItem>
            <MenuItem value="between_delay">{terms.VariationDetail.delayFilters.between}</MenuItem>
          </Select>
        </MenuItem>
        <MenuItem
          disableRipple
          focusVisibleClassName="focus-visible"
          sx={{
            '&:hover': {
              backgroundColor: '#FFF !important',
            },
          }}
        >
          <Paper
            variant="outlined"
            sx={{
              width: '100%',
              height: '56px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <InputBase
              sx={{ ml: 1 }}
              value={localFilterArrival !== 'all' ? localFilterArrivalTime : ''}
              inputProps={{ 'aria-label': terms.VariationDetail.delayFilters.placeHolder }}
              placeholder={terms.VariationDetail.delayFilters.placeHolder}
              onChange={onChangeArrivaltime}
              disabled={localFilterArrival === 'all'}
            />
          </Paper>
        </MenuItem>
        {localFilterArrival === 'between_delay' && (
        <>
          <MenuItem
            disableRipple
            className="d-flex justify-content-between"
            sx={{
              '&:hover': {
                backgroundColor: '#FFF !important',
              },
            }}
          >
            <span className="font-weight-bold">et</span>
          </MenuItem>
          <MenuItem
            disableRipple
            focusVisibleClassName="focus-visible"
            sx={{
              '&:hover': {
                backgroundColor: '#FFF !important',
              },
            }}
          >
            <Paper
              variant="outlined"
              sx={{
                width: '100%',
                height: '56px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <InputBase
                sx={{ ml: 1 }}
                value={localFilterArrival === 'between_delay' ? localFilterArrivalTimeV2 : ''}
                inputProps={{ 'aria-label': terms.VariationDetail.delayFilters.placeHolder }}
                placeholder={terms.VariationDetail.delayFilters.placeHolder}
                onChange={onChangeArrivaltimeV2}
              />
            </Paper>
          </MenuItem>
        </>
        )}

        <MenuItem
          disableRipple
          className="d-flex justify-content-between"
          sx={{
            '&:hover': {
              backgroundColor: '#FFF !important',
            },
          }}
        >
          <Button onClick={handleCloseArrival} variant="outlined" color={filterArrival.operation === localFilterArrival ? 'error' : 'primary'}>{filterArrival.operation === localFilterArrival ? terms.common.delete : terms.common.cancel}</Button>
          <Button disableElevation onClick={applyFilterArrival} disabled={localFilterArrivalTime === '' && localFilterArrival !== 'all'} variant="contained">{terms.common.ok}</Button>
        </MenuItem>
      </Menu>
    </>
  )
}
